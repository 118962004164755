import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Asset, InterfaceService, ItineraryDetails, Key, PlaceLoc, Wish} from '../../services/interface.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import * as _ from 'lodash';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {AdminService} from '../../services/admin.service';
import * as geohash from 'ngeohash';
import {RoehttpService} from '../../services/roehttp.service';
import {MapboxService} from '../../services/mapbox.service';
import {MapIcons, PlaceAttributes, Transmodes} from '../../services/constants';
import {DomSanitizer} from '@angular/platform-browser';
import {transition, trigger} from '@angular/animations';
import {left, right} from '../../capptivation/ca-formgen/ca-formgen.component';

declare var google: any;


const autoComleteFields = [
  'address_components',
  'formatted_address',
  'name',
  'place_id',
  'geometry',
  'utc_offset_minutes',
  'photos',
  'website'
];

@Component({
  selector: 'app-blog-content',
  templateUrl: './blog-content.component.html',
  styleUrls: ['./blog-content.component.scss']
})
export class BlogContentComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentChecked {
  @Input()
  wish: Wish;

  @Input()
  guide: Wish;

  @Input()
  header: string;

  @Input()
  assets: Asset[];

  @Output()
  closeguide = new EventEmitter<boolean>();

  @Output()
  closesection = new EventEmitter<boolean>();

  @Output()
  section = new EventEmitter<Wish>();

  showFiller = false;
  rotate = false;
  fac = 720 / 1595;
  date;
  curlang = 'en';
  transmodes = Transmodes;
  mapicons = MapIcons;
  placeattributes = PlaceAttributes;

  pickassets: Asset[];
  hit;
  addphoto = false;
  tmptag: string;

  flipit = false;

  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  introheight;
  width = 417;
  height = 736;
  mapheight = 400;
  mapwidth = 417;
  scrollheight = 400;

  googletype = 'establishment';
  location: string;
  autoCompleteOptions = {
    fields: autoComleteFields,
    types: ['establishment'],
  };
  autocomplete;

  options = {
    key: 'wFE7nB4D3F4C3B10B6eyznxmE2cd1rsplpfG-7c1eeF6nablA3C11B8A4D2B4F3G2F3B2==',
    charCounterCount: true,
    fontFamilyDefaultSelection: 'Roboto',
    fixedToolbar: true,
    language: 'en',
    height: 340,
    toolbarButtons: ['bold', 'italic', 'underline', 'fontFamily', 'fontSize', 'color', 'align', 'formatOL', 'formatUL',
      'outdent', 'indent', 'spellChecker', 'html']
  };

  titlecolor = '#0000FF';

  config = {
    slidesPerView: 1,
    direction: 'horizontal',
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    allowTouchMove: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    a11y: true,
    preventClicks: true,
    preventClicksPropagation: false,
  };

  parentrect;
  parentrectbig;
  selectedukey: string;
  scrollpos;
  ftime = true;

  moving = false;
  mousepos: number;

  gallery = false;
  ypos = 0;
  xpos = 0;

  $sub1;
  $sub2;
  $sub3;
  $sub4;

  curwish: Wish;
  curcam;
  curasset: Asset;
  curguide: Wish;

  modes: string[];
  days: number;
  scrolling = true;

  toppos: number;
  oldmode: string;
  oldloc: PlaceLoc;

  @ViewChild('addresstext', {static: false}) addresstext: any;
  @ViewChild('intro', {static: false}) intro: ElementRef;
  @ViewChild('map', {static: false}) map: ElementRef;
  @ViewChild('container', {static: false}) container: ElementRef;
  @ViewChild('introbig', {static: false}) introbig: ElementRef;
  @ViewChild('activitybox', {static: false}) activitybox: ElementRef;
  @ViewChild('containerbig', {static: false}) containerbig: ElementRef;

  constructor(private is: InterfaceService,
              public mapbox: MapboxService,
              private http: RoehttpService,
              private dom: DomSanitizer,
              private admin: AdminService,
              private cd: ChangeDetectorRef) {
  }



  ngOnInit() {
    console.log('WISH', this.wish)
    if (this.wish && this.wish.l && this.wish.l.length) {
      this.selectedukey = this.wish.l[0].ukey;
    }

    if (this.wish.e && this.wish.e.mode) {
      this.oldmode = this.wish.e.mode;
    }

    if (this.wish.location) {
      this.oldloc = _.cloneDeep(this.wish.location);
    }

    if (!this.wish.e) {
      this.wish.e = new ItineraryDetails();
    }

    if (this.wish.ty === 'BlogGuide') {
      this.mapbox.buildMapRoute(this.wish);
      if (this.wish.m && this.wish.m.style) {
        this.mapbox.getMapStyle(this.wish.m.style);
      }

      // this.mapbox.getRoute(this.guide, this.wish, false);
    }

    this.addphoto = false;
    this.pickassets = _.cloneDeep(this.assets);
    if (this.pickassets && this.pickassets.length && this.wish.items && this.wish.items.length) {
      this.wish.items.forEach(item => {
        const i = this.pickassets.findIndex(a => a.ukey === item.ukey);
        if (i > -1) {
          this.pickassets.splice(i, 1);
        }
      });
    }

    this.$sub1 = this.mapbox.pickasset$.subscribe(asset => {
      if (asset) {
        this.nullOut();
        this.curasset = asset;
      }
    });

    this.$sub2 = this.mapbox.pickguide$.subscribe(guide => {
      if (guide) {
        this.nullOut();
        this.curguide = guide;
      }
    });

    this.$sub3 = this.mapbox.pickvideo$.subscribe(webcam => {
      if (webcam) {
        this.nullOut();
        this.curcam = this.dom.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${webcam}`);
      }
    });

    this.$sub4 = this.mapbox.pickphotos$.subscribe(wish => {
      if (wish) {
        this.nullOut();
        this.gallery = true;
        this.curwish = wish;
      }
    });

    if (this.wish.ty === 'BlogGuide') {
      this.getModes();

      // [this.wish.l[0].location.long, this.wish.l[0].location.lat]
      this.mapbox.buildMap(this.wish.l[0]);
    }

    if (!this.wish.types) {
      this.wish.types = [];
    }

    console.log('end Ini')
  }

  onStyleChange() {
    this.mapbox.getMapStyle(this.wish.m.style);
  }


  nullOut() {
    if (this.container) {
      this.toppos = this.container.nativeElement.scrollTop;
    }

    this.curwish = null;
    this.curcam = null;
    this.curguide = null;
    this.curasset = null;
  }

  compareWithFn(item1, item2) {
    return item1 && item2 ? item1.name === item2.name : item1 === item2;
  }


  /*
  ItineraryDetails {
  day: number; // what day of the trip will this fall on
  length: string; // distance to next activity - meters
  duration: string; // time to next activity
  elevationgain: number; // meters
  goingto: PlaceLoc;
  comingfrom: PlaceLoc;
  mode: string; // mode of transporation to next activity: walking, train, etc, various

   */


  onChangeMode() {
    if (!this.oldmode || this.oldmode !== this.wish.e.mode) {
      this.mapbox.getRoute(this.guide, this.wish, true);
      this.oldmode = this.wish.e.mode;
    }
  }


  getModes() {
    if (this.wish.ty === 'BlogGuide' && this.wish.l && this.wish.l.length) {
      const temp = this.wish.l.map(l => l.mode) as string[];
      if (temp && temp.length) {
        this.modes = [...new Set(temp)];
      }
      this.days = this.wish.l[this.wish.l.length - 1].e.day;
    }
  }

  onGallery(event) {
    this.gallery = true;
    this.curwish = event;
  }

  onNewSelectedUkey(s) {
    this.selectedukey = s.ukey;
    this.mapbox.onFlyTo(s);
    this.cd.detectChanges();

  }


  onTest(event) {
    event.stopPropagation();
  }

  onMainScroll(event) {
    if ( this.curwish || this.curcam || this.curguide || this.curasset) {
        this.container.nativeElement.scrollTop = this.toppos;
    } else {

      event.stopPropagation();
      if (this.scrolling) {
        if (this.container.nativeElement.scrollTop === 0) {
          this.ftime = true;
        }
        if (this.map && this.container) {
          const containerrect = this.container.nativeElement.getBoundingClientRect();
          const maprect = this.map.nativeElement.getBoundingClientRect();
          if (this.ftime && (maprect.top - containerrect.top) <= 300) {
            let x = this.container.nativeElement.scrollTop;
            this.scrolling = false;
            this.ftime = false;
            const interval = setInterval(() => {
              x = x + 3;
              this.container.nativeElement.scrollTop = x;
              if (this.container.nativeElement.scrollTop >= this.introheight) {
                this.scrolling = true;
                clearInterval(interval);
                this.cd.detectChanges();
              }
            }, 10);
          }
        }
      }
    }
  }

  openGuide() {
  }

  onScroll(event) {
    this.scrollpos = event;
  }

  ngAfterContentChecked() {
    if (this.activitybox) {
      this.parentrect = this.activitybox.nativeElement.getBoundingClientRect();
      this.scrollheight = this.height - this.mapheight;
    }
  }


  onResize(event) {
    /*

        const loadedImage: any = event.currentTarget;
        console.log('width:', loadedImage.width, loadedImage.height);
        if (this.containerbig) {
          const width = this.containerbig.nativeElement.offsetWidth;
          console.log('offset width', width)
        }
    */
    // todo: this is probably a miserable cludge
    window.dispatchEvent(new Event('resize'));
  }

  ngAfterViewInit() {
    this.createAutos();


    if (this.wish.location) {
      this.location = this.wish.location.name;
      let name;
      if (this.location && this.location.length) {
        name = this.location;
      } else {
        name = this.wish.location.name;
      }

      this.hit = {
        objectID: this.wish.ukey,
        hash: this.wish.location.hash,
        loc: this.wish.location.loc,
        ukey: this.wish.ukey,
        name,
        long: this.wish.location.long,
        lat: this.wish.location.lat
      };
    } else {
      this.hit = null;
    }

    if (this.wish.date) {
      this.date = new Date(this.wish.date);
    } else {
      this.date = new Date();
    }

    if (this.intro) {
      this.introheight = Math.max(this.intro.nativeElement.offsetHeight, this.height);
    }

    console.log('10')
    this.cd.detectChanges();
  }

  onMouseMove(event) {
    if (this.moving) {
      event.stopPropagation();
      const x = this.mousepos - event.clientY;
      this.mousepos = event.clientY;
      if ((this.mapheight >= 100) && x > 0) {
        this.mapheight = this.mapheight - x;
        this.scrollheight = this.height - this.mapheight;
      } else if ((this.mapheight <= 400) && x < 0) {
        this.mapheight = this.mapheight - x;
        this.scrollheight = this.height - this.mapheight;
      }
    }
  }


  onMouseDown(event) {
    event.stopPropagation();
    this.moving = true;
    this.mousepos = event.clientY;
  }

  onMouseUp(event) {
    if (this.moving) {
      event.stopPropagation();
      this.moving = false;
      this.mapbox.map.resize();
    }
  }

  createAutos() {
    if (!this.autocomplete) {
      const el = this.addresstext.nativeElement;
      this.hit = null;
      this.autocomplete = new google.maps.places.Autocomplete(el, this.autoCompleteOptions);
      console.log('11')
      this.autocomplete.addListener('place_changed', () => {
        this.onGetHit();
      });
    }
  }


  onDateChange(event) {
    this.wish.date = new Date(this.date).getTime();
  }

  dropItinerary(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.wish.l, event.previousIndex, event.currentIndex);
    let cnt = 0;
    this.wish.l.forEach(activity => {
      if (cnt === 0) {
        activity.e.day = 1;
      } else if (cnt !== (this.wish.l.length - 1) && activity.e.day > this.wish.l[cnt + 1].e.day) {
        activity.e.day = this.wish.l[cnt + 1].e.day;
      }

      cnt += 1;
    });
    const tmp = _.cloneDeep(this.wish.l);
    this.wish.l = null;
    this.cd.detectChanges();
    this.wish.l = tmp;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.wish.items, event.previousIndex, event.currentIndex);
  }

  onDeletePhoto(p, i) {
    this.wish.items.splice(i, 1);
    this.pickassets.push(p);
  }

  onChange(event) {
    if (this.googletype === 'address') {
      this.autocomplete.setTypes([]);
    } else {
      this.autocomplete.setTypes([this.googletype]);
    }
  }

  onLocChange() {
    if (this.hit) {
      console.log('here', this.oldloc)
      if (this.hit.lat && this.wish.location) {
        this.wish.location.lat = this.hit.lat;
      }
      if (this.hit.long && this.wish.location) {
        this.wish.location.long = this.hit.long;
      }
      console.log('wish location', this.wish.location)

      if (!this.oldloc || this.oldloc.long !== this.wish.location.long || this.oldloc.lat !== this.wish.location.lat) {
        console.log('need to recald')
        this.mapbox.getRoute(this.guide, this.wish, true);
        this.oldloc = _.cloneDeep(this.wish.location);
      }
    }
  }

  onMapClick(event) {
    if (event && event.coords && event.coords.lng) {
      this.hit.long = event.coords.lng;
      this.hit.lat = event.coords.lat;
      this.onLocChange();
    }
  }

  onAddSection() {
    this.section.emit(null);
  }

  onTypeChange() {
    if (this.wish.l && this.wish.l.length) {
      this.wish.l.forEach(w => {
        w.type = this.wish.type;
      });
    }
  }

  onEditSection(s, i) {
    this.section.emit(s);
  }

  onDeleteSection(s, i) {
    this.getModes();
    this.wish.l.splice(i, 1);
  }

  onCompleteHit(lox) {
    this.is.getObject(`Places/${lox.ukey}/location`)
      .take(1)
      .subscribe(location => {
        this.wish.location = location;
        let name;
        if (this.location && this.location.length) {
          name = this.location;
        } else {
          name = this.wish.location.name;
        }
        this.hit = {
          objectID: lox.ukey,
          hash: location.hash,
          ukey: lox.ukey,
          loc: location.loc,
          name,
          long: location.long,
          lat: location.lat
        };
        this.onLocChange();
      });
  }

  onGetHit() {
    this.hit = null;
    if (this.addresstext.nativeElement.value && this.addresstext.nativeElement.value.length) {
      const varr = this.addresstext.nativeElement.value.split(',');
      this.location = varr[0];
      if (this.location) {
        this.is.getQuery(`PlacesFirst`, 'name', this.location)
          .take(1)
          .subscribe(lox => {
            if (lox && lox.length) {
              this.onCompleteHit(lox[0]);
            } else {
              this.is.getQuery(`PlacesFirst`, 'address', this.addresstext.nativeElement.value)
                .take(1)
                .subscribe(addarr => {
                  if (addarr && addarr.length) {
                    this.onCompleteHit(addarr[0]);
                  } else {
                    console.log('not in the data base');
                  }
                });
            }
          });
      }
    }
  }

  onPickAsset(a, i) {
    if (!this.wish.items) {
      this.wish.items = [];
    }
    this.wish.items.push(a);
    this.pickassets.splice(i, 1);
  }


  onSaveSection() {
    if (this.hit) {
      if (this.hit.lat && this.wish.location) {
        this.wish.location.lat = this.hit.lat;
      }
      if (this.hit.long && this.wish.location) {
        this.wish.location.long = this.hit.long;
      }
      if (this.hit.loc && this.wish.location) {
        this.wish.location.loc = this.hit.loc;
      }
      this.location = '';
      this.hit = null;
      this.getModes();
      this.closesection.emit(false);
    } else {
      const place = this.autocomplete.getPlace();
      if (place) {
        // 1. check to see if Place/placeid/settings already exits
        this.is.getObject(`Places/${place.place_id}/location/loc`)
          .take(1)
          .subscribe(loc => {
            // if a location doesn't exist, create it
            if (!loc) {
              // 1. create a PlaceLoc
              const newloc = new PlaceLoc();
              const country = this.admin.getCountry(place.address_components, true);
              const state = this.admin.getState(place.address_components, true);
              const city = this.admin.getCity(place.address_components, true);
              if (country === 'US') {
                if (city) {
                  newloc.loc = `${city}, ${state}`;
                  newloc.location = `${city}, ${state}`;
                } else {
                  newloc.loc = `${state}, ${country}`;
                  newloc.location = `${state}, ${country}`;
                }
              } else {
                newloc.loc = `${city}, ${country}`;
                newloc.location = `${city}, ${country}`;
              }
              newloc.long = place.geometry.location.lng();
              newloc.lat = place.geometry.location.lat();
              if (place.geometry.bounds) {
                newloc.bounds = place.geometry.bounds;
              }
              if (place.geometry.viewport) {
                newloc.viewport = place.geometry.viewport;
              }
              newloc.hash = geohash.encode(newloc.lat, newloc.long);
              newloc.address = place.formatted_address; // this is an address
              newloc.utc_offset_minutes = place.utc_offset_minutes;
              newloc.name = place.name;
              newloc.keys = [];
              const key = new Key();
              key.id = place.place_id;
              key.name = 'Google';
              newloc.keys.push(key);
              // newloc.tzone: string;

              this.wish.location = newloc;
              let website = '';
              if (place.website) {
                website = place.website;
                this.wish.website = website;
              }

              // save record to Places
              if (place.photos && place.photos.length > 0) {
                const parr = [];
                let googlephotos;
                place.photos.forEach(p => {
                  parr.push(p.getUrl());
                });
                this.http.getGoogleJustPhotos(parr).then(
                  photosreturn => {
                    const photos = Object.values(photosreturn);
                    if (photos && photos.length > 0) {
                      googlephotos = [];
                      photos.forEach(p => {
                        googlephotos.push({url: p, who: 'Google'});
                      });
                    }
                    this.is.setObjNoLog(`Places/${place.place_id}`, {
                      ukey: place.place_id,
                      name: place.name,
                      location: newloc,
                      address: place.formatted_address,
                      website,
                      photos: googlephotos
                    });
                  });
              } else {
                this.is.setObjNoLog(`Places/${place.place_id}`,
                  {
                    ukey: place.place_id,
                    name: place.name,
                    address: place.formatted_address,
                    location: newloc,
                    website
                  });
              }
            }

            // wish.trip is where we list all of the locations mentioned in a blog
            if (!this.wish.trip) {
              this.wish.trip = [];
            }
            const hash = geohash.encode(place.geometry.location.lat(), place.geometry.location.lng());
            this.wish.trip.push({
              objectID: place.place_id,
              hash,
              ukey: place.place_id,
              name: place.name,
              long: place.geometry.location.lng(),
              lat: place.geometry.location.lat()
            });

            this.location = '';
            this.getModes();
            this.closesection.emit(false);
          });
      }
    }
  }


  onSave() {
    if (this.wish.ty === 'BlogGuide') {
      // calculate distance
      if (this.wish.l && this.wish.l.length) {
        this.wish.l.forEach(l => {
          this.mapbox.getRoute(this.wish, l, false);
        })
      }
      this.closeguide.emit(true);
    } else {
      this.getModes();
      this.closesection.emit(true);
      this.onSaveSection();
    }
  }

  onCancel() {
    if (this.wish.ty === 'BlogGuide') {
      this.closeguide.emit(false);
    } else {
      this.getModes();
      this.closesection.emit(false);
    }
  }

  onImgError(i) {

  }

  onAddPhoto() {
    this.addphoto = true;
  }


  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.wish.types.push(value);
    }

    // Clear the input value
    this.tmptag = '';
  }

  remove(tag: string): void {
    const index = this.wish.types.indexOf(tag);

    if (index >= 0) {
      this.wish.types.splice(index, 1);
    }
  }

  onOpenGuide() {
    window.open(this.curguide.l, '_blank');
    this.curguide = null;
  }

  onJumpTo(id) {
    this.container.nativeElement.scrollTop = this.introheight;
    const arect = this.activitybox.nativeElement.getBoundingClientRect();
    const irect = document.getElementById(id).getBoundingClientRect();
    this.activitybox.nativeElement.scrollTop = irect['y'] - arect.y;
  }

  ngOnDestroy() {
    if (this.$sub1) {
      this.$sub1.unsubscribe();
    }
    if (this.$sub2) {
      this.$sub2.unsubscribe();
    }
    if (this.$sub3) {
      this.$sub3.unsubscribe();
    }
    if (this.$sub4) {
      this.$sub4.unsubscribe();
    }
  }

}
