import {Injectable} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import {environment} from '../../environments/environment';
import {Asset, InterfaceService, Key, Wish} from './interface.service';
import {BehaviorSubject} from 'rxjs/index';
import {RoehttpService} from './roehttp.service';
import {GeofireService} from './geofire.service';

const apiToken = environment.MAPBOX_API_KEY;
declare const google: any;


@Injectable({
  providedIn: 'root'
})
export class MapboxService {

  // todo: test
  map: mapboxgl.Map;
  mapbig: mapboxgl.Map;

  style = 'mapbox://styles/mapbox/light-v10'; // 'mapbox://styles/mapbox/streets-v11';
  zoom = 12;

  assets: any[];

  wishesadded: string[];

  _pickasset = new BehaviorSubject<Asset>(null);
  pickasset$ = this._pickasset.asObservable();

  _pickguide = new BehaviorSubject<Wish>(null);
  pickguide$ = this._pickguide.asObservable();
  curguide: Wish;

  _pickvideo = new BehaviorSubject<string>(null);
  pickvideo$ = this._pickvideo.asObservable();

  _pickphotos = new BehaviorSubject<Wish>(null);
  pickphotos$ = this._pickphotos.asObservable();

  poly: any[];
  mapbounds: any;

  styles: Key[];
  mapstyle;

  markers: any[];
  curmarker: string;

  constructor(private http: RoehttpService,
              private gs: GeofireService,
              private is: InterfaceService) {
    mapboxgl.accessToken = apiToken;
  }


  getMapStyle(id) {
    this.is.getObject(`MapStyle/Styles/${id}`).take(1)
      .subscribe(style => {
        this.mapstyle = JSON.parse(style);
        console.log('id', this.mapstyle);
      });
  }

  getMapStyles() {
    this.is.getList(`MapStyle/Defs`).take(1).subscribe(styles => {
      this.styles = styles;
      console.log('styles', this.styles);
    });
  }

  buildMapRoute(guide: Wish) {
    this.poly = [];
    let curday = 1;
    let curactivity = 1;
    this.mapbounds = new google.maps.LatLngBounds();
    if (guide.l && guide.l.length) {
      guide.l.forEach(l => {
        if (l.e.day !== curday) {
          curday = curday + 1;
          curactivity = 1;
        }

        l.e.activitynumber = curactivity;
        curactivity = curactivity + 1;


        if (l.location && l.location.lat &&
          l.e && l.e.goingto && l.e.goingto.lat) {

          const latLng = new google.maps.LatLng(l.location.lat, l.location.long);
          this.mapbounds.extend(latLng);

          if (l.e.mode === 'flight_takeoff' || l.e.mode === 'boat' || l.e.mode === 'other') {
            const start = new google.maps.LatLng(l.location.lat, l.location.long);
            const finish = new google.maps.LatLng(l.e.goingto.lat, l.e.goingto.long);
            const line = [];
            line.push(start);
            line.push(finish);
            this.poly.push(line);
          } else {
            if (l.e && l.e.poly) {
              const poly = new google.maps.geometry.encoding.decodePath(l.e.poly);
              if (poly) {
                this.poly.push(poly);
              }
            }
          }
        }
      });

      console.log('poly line', this.poly);
      /*
            console.log('bounds hb', this.mapbounds.Hb.g, this.mapbounds.Hb.i)
            console.log('bounds tc', this.mapbounds.tc.g, this.mapbounds.tc.i)
      */
    }
  }

  getRoute(guide: Wish, wish: Wish, force: boolean) {
    if (guide && guide.l && guide.l.length && wish.e && wish.e.mode && wish.location) {
      const x = guide.l.findIndex(l => l.ukey === wish.ukey);
      if (x < guide.l.length) {
        // 1. find out what comes next
        const locafter = guide.l[x + 1];
        if (wish.e && locafter.location) {
          // 2. check to see if location has changed
          if (wish.e.goingto &&
            locafter.location.long === wish.e.goingto.long &&
            locafter.location.lat === wish.e.goingto.lat
            && !force) {
            return;
          } else {
            // if get here then have to calculate the distance

            const origin = `${wish.location.lat},${wish.location.long}`;
            const destination = `${locafter.location.lat},${locafter.location.long}`;

            if (wish.e.mode === 'flight_takeoff' ||
              wish.e.mode === 'boat' ||
              wish.e.mode === 'other') {

              wish.e.distanceto = this.gs.distance(wish.location.lat, wish.location.long, locafter.location.lat, locafter.location.long);
              wish.e.goingto = locafter.location;

              console.log('wisher', wish);

              /*
                            const path = [
                              {lat: wish.location.lat, lng: wish.location.long},
                              {lat: locafter.location.lat, lng: locafter.location.long}
                            ];
                            this.poly = path;
              */

            } else if (wish.e.mode === 'driving' ||
              wish.e.mode === 'walking' ||
              wish.e.mode === 'bicycling' ||
              wish.e.mode === 'bus' ||
              wish.e.mode === 'subway' ||
              wish.e.mode === 'train' ||
              wish.e.mode === 'tram') {
              let transmode;
              let mode;
              if (wish.e.mode === 'bus') {
                mode = 'transit';
                transmode = 'bus';
              } else if (wish.e.mode === 'subway') {
                mode = 'transit';
                transmode = 'subway';
              } else if (mode === 'train') {
                mode = 'transit';
                transmode = 'train';
              } else if (mode === 'tram') {
                mode = 'transit';
                transmode = 'tram';
              } else {
                mode = wish.e.mode;
                transmode = '';
              }
              this.http.getRoute(origin, destination, mode, transmode)
                .then(result => {
                  if (result.status === 'OK') {
                    wish.e.durationto = Math.trunc(result.routes[0].legs[0].duration.value / 60);
                    wish.e.distanceto = (result.routes[0].legs[0].distance.value / 1000).toFixed(2);
                    wish.e.goingto = locafter.location;
                    wish.e.poly = result.routes[0].overview_polyline.points;

//                      const rp = result.routes[0].overview_polyline.points;
//                      wish.e.poly = new google.maps.geometry.encoding.decodePath(rp);
                  } else {
                    // todo: how deal with error
                  }
                });
            }
          }
        }
      }
    }
  }


  calcDistances(guide: Wish, wish: Wish, force: boolean) {
    // a mode of transportation needs to be selected before can do a calculation
    if (guide && guide.l && guide.l.length && wish.e && wish.e.mode && wish.location) {
      const x = guide.l.findIndex(l => l.ukey === wish.ukey);
      if (x < guide.l.length) {
        // 1. find out what comes next
        const locafter = guide.l[x + 1];
        if (wish.e && locafter.location) {
          // 2. check to see if location has changed
          if (wish.e.goingto &&
            locafter.location.long === wish.e.goingto.long &&
            locafter.location.lat === wish.e.goingto.lat
            && !force) {
            return;
          } else {
            // if get here then have to calculate the distance
            console.log(5, wish);
            console.log(6, locafter);

            this.http.getDistance(wish.e.mode, wish.location.lat, wish.location.long, locafter.location.lat, locafter.location.long).take(1).subscribe(result => {
              console.log('get distance result', result);
              if (result.status === 200 && result.res.rows && result.res.rows[0] &&
                result.res.rows[0].elements && result.res.rows[0].elements[0]) {

                if (result.res.rows[0].elements[0].status === 'NOT_FOUND') {
                  // todo:
                } else if (result.res.rows[0].elements[0].status === 'OK') {
                  wish.e.durationto = result.res.rows[0].elements[0].duration.value;
                  wish.e.distanceto = result.res.rows[0].elements[0].distance.value;
                  wish.e.goingto = locafter.location;
                } else {
                  // todo:
                }
              } else if (result.status === 201) {
                wish.e.distanceto = result.res;
                wish.e.goingto = locafter.location;
              } else {
                wish.e.durationto = null;
                wish.e.distanceto = null;
                wish.e.goingto = null;
              }
              console.log('wish calc', wish.e);
            });
          }
        }
      }
    }

  }


  onResize() {
    this.mapbig.triggerRepaint();
  }

  addVideos() {
    const longlat = [2.3102323, 48.8556645];
    const ukey = 'iZipA1LL_sU';

    const dotel = document.createElement('div');
    dotel.className = 'marker';
    dotel.style.width = `30px`;
    dotel.style.height = `30px`;
    dotel.style.backgroundImage = `url(../assets/webcam.svg)`;
    dotel.style.backgroundSize = '100%';

    dotel.addEventListener('click', () => {
      console.log('video', ukey);
      this._pickvideo.next(ukey);
    });
    // Add markers to the map.
    new mapboxgl.Marker(dotel)
      .setLngLat(longlat)
      .addTo(this.map);
  }

  // todo: what is this
  addGuides() {
    this.is.getObject(`Topics/TmpTravelguides/guides/1612873910000`).take(1).subscribe(item => {
      if (item) {
        const dotel = document.createElement('div');
        dotel.className = 'marker';
        dotel.style.width = `30px`;
        dotel.style.height = `30px`;
        dotel.style.backgroundImage = `url(../assets/guide.svg)`;
        dotel.style.backgroundSize = '100%';

        dotel.addEventListener('click', () => {
          console.log('click', item);
          this._pickguide.next(item);
        })

        // Add markers to the map.
        new mapboxgl.Marker(dotel)
          .setLngLat([item.location.long, item.location.lat])
          .addTo(this.map);

      }

    });
  }

  buildMap(wish) {
    this.wishesadded = [];
    this.markers = [];
    this.wishesadded.push(wish.ukey);
    this.mapbig = new mapboxgl.Map({
      container: 'mapbig',
      style: this.style,
      center: [wish.location.long, wish.location.lat],
      zoom: 13.5,
      pitch: 45
    });

    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      center: [wish.location.long, wish.location.lat],
      zoom: 13.5,
      pitch: 45
    });

    this.addGuides();
    this.addVideos();
    this.updateCurMarker(wish);
    this.map.addControl(new mapboxgl.NavigationControl());

    const markerbig = new mapboxgl.Marker()
      .setLngLat([wish.location.long, wish.location.lat])
      .addTo(this.mapbig);
    this.mapbig.addControl(new mapboxgl.NavigationControl());

    /*
        this.map.on('moveend', (e) => {
          console.log('flyover')
        });
    */


    this.map.on('load', () => {
      // Insert the layer beneath any symbol layer.
      const layers = this.map.getStyle().layers;
      const labelLayerId = layers.find(
        (layer) => layer.type === 'symbol' && layer.layout['text-field']
      ).id;

      // The 'building' layer in the Mapbox Streets
      // vector tileset contains building height data
      // from OpenStreetMap.
      this.map.addLayer(
        {
          id: 'add-3d-buildings',
          source: 'composite',
          'source-layer': 'building',
          filter: ['==', 'extrude', 'true'],
          type: 'fill-extrusion',
          minzoom: 15,
          paint: {
            'fill-extrusion-color': '#aaa',

// Use an 'interpolate' expression to
// add a smooth transition effect to
// the buildings as the user zooms in.
            'fill-extrusion-height': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              15.05,
              ['get', 'height']
            ],
            'fill-extrusion-base': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              15.05,
              ['get', 'min_height']
            ],
            'fill-extrusion-opacity': 0.6
          }
        },
        labelLayerId
      );
    });
  }

  updateCurMarker(wish: Wish) {
    console.log('curmarker', wish);

    if (this.curmarker) {
      // todo: need to remove the marker and the element and the listener
      const x = this.markers.findIndex(m => m.ukey === this.curmarker);
      const oldwish = this.markers[x].wish;
      const oldmark = this.markers[x].marker;
      console.log('x', oldmark);
      oldmark.remove();
      console.log('oldmark');
      const oldel = this.markers[x].el;
      oldel.remove();
      console.log('oldel');

      // need to create it over again with a gray dollar sign
      const el = document.createElement('div');
      el.className = 'marker';
      el.style.width = `40px`;
      el.style.height = `40px`;
      el.style.backgroundImage = `url(../assets/grayflag.svg)`; // ${wish.icon}
      el.style.backgroundSize = '100%';

      const mark = new mapboxgl.Marker(el)
        .setLngLat([oldwish.location.long, oldwish.location.lat])
        .addTo(this.map);

      oldel.addEventListener('click', () => {
        this._pickphotos.next(oldwish);
      });

      this.markers[x].el = el;
      this.markers[x].marker = mark;
    }

    const y = this.markers.findIndex(m => m.ukey === wish.ukey);
    if (y > -1) {
      const ymark = this.markers[y].marker;
      ymark.remove();
      console.log('oldmark');
      const yel = this.markers[y].el;
      yel.remove();
      this.markers.splice(y, 1);
    }

    const dotel = document.createElement('div');
    dotel.className = 'marker';
    dotel.style.width = `40px`;
    dotel.style.height = `40px`;
    dotel.style.backgroundImage = `url(../assets/flagpole.svg)`; // ${wish.icon}
    dotel.style.backgroundSize = '100%';

    const marker = new mapboxgl.Marker(dotel)
      .setLngLat([wish.location.long, wish.location.lat])
      .addTo(this.map);

    this.curmarker = wish.ukey;

    dotel.addEventListener('click', () => {
      this._pickphotos.next(wish);
    });

    this.markers.push({ukey: wish.ukey, wish, el: dotel, marker});
    console.log('markers', this.markers);
  }


  onDrawLine(start, finish) {
    const route = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: [start, finish]
          }
        }
      ]
    };

    this.map.addSource('route', {
      type: 'geojson',
      data: route
    });

    this.map.addLayer({
      id: 'route',
      type: 'line',
      source: 'route',
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': '#00F',
        'line-width': 3
      }
    });
  }

  onFlyTo(wish) {
    const loc = {
      center: [wish.location.long, wish.location.lat],
      zoom: 13.5,
      pitch: 0
    };

    // create the popup
    /*
        const popup = new mapboxgl.Popup({ offset: 25 }).setText(
          'Construction on the Washington Monument began in 1848.'
        );

        const marker = new mapboxgl.Marker()
          .setLngLat(loc.center)
          .setPopup(popup) // sets a popup on this marker
          .addTo(this.map);
    */


    let marker;
    // TODO: VERY IMPORTANT - need to check if a wish has already been added to the map and if so dont recreate
    const x = this.wishesadded.findIndex(ukey => ukey === wish.ukey);
    if (x === -1) {
      /*
            const markerel = document.createElement('div');
            markerel.className = 'marker';
            markerel.style.width = `60px`;
            markerel.style.height = `60px`;
            markerel.style.backgroundImage = `url(../assets/flagpole.svg)`; // ${wish.icon}
            markerel.style.backgroundSize = '100%';

            marker = new mapboxgl.Marker(markerel)
              .setLngLat(loc.center)
              .addTo(this.map);

            markerel.addEventListener('click', () => {
              this._pickphotos.next(wish);
            });
      */


      this.wishesadded.push(wish.ukey);

      /*
          const geojson = {
            'type': 'FeatureCollection',
            'features': [
              {
                'type': 'Feature',
                'properties': {
                  'message': 'Foo',
                  'iconSize': [30, 30]
                },
                'geometry': {
                  'type': 'Point',
                  'coordinates': loc.center
                }
              },
            ]
          };
      */

      const width = 30;
      const height = 30;

      if (wish && wish.items && wish.items.length) {
        wish.items.forEach(item => {
          if (item.location) {

            const ldist = Math.abs(item.location.long - wish.location.long);
            const ydist = Math.abs(item.location.lat - wish.location.lat);
            if (ldist > .0001 && ydist > .0001) {

              const dotel = document.createElement('div');
              dotel.className = 'marker';
              dotel.style.width = `${width}px`;
              dotel.style.height = `${height}px`;
              dotel.style.backgroundImage = `url(../assets/bluedot.svg)`;
              dotel.style.backgroundSize = '100%';

              dotel.addEventListener('click', () => {
                console.log('item', item);
                this._pickasset.next(item);
              });


              // Add markers to the map.
              new mapboxgl.Marker(dotel)
                .setLngLat([item.location.long, item.location.lat])
                .addTo(this.map);

              if (item.finlocation && item.finlocation.lat && item.finlocation.long) {
                this.onDrawLine([item.location.long, item.location.lat], [item.finlocation.long, item.finlocation.lat]);
              }
            }
          }
        });
      }
    }


    this.updateCurMarker(wish);
    this.map.flyTo(loc);
  }

}
