import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-id-selection-dialog',
  templateUrl: './id-selection-dialog.component.html',
  styleUrls: ['./id-selection-dialog.component.scss']
})
export class IdSelectionDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<IdSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { ids: string[] }
  ) {}

  onSkip(): void {
    this.dialogRef.close('');
  }

  onSelect(id: string): void {
    this.dialogRef.close(id);
  }
}
