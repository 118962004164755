import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Papa} from 'ngx-papaparse';
import {FirebaseService} from '../../services/firebase.service';
import {AdminService, Article, PhoneObj} from '../../services/admin.service';
import {GeofireService} from '../../services/geofire.service';
import {
  BlockString, CappParams,
  Card, ComOrg, LocationData, ContentLibrary, Demographics, Geo, InterfaceService, Invitation, JumpLink, LangString, LangStringBlock, Links,
  Office,
  OrgData, OrgEdition, OrgLegacy,
  OrgLegacyExtra,
  OrgProfile,
  Policy,
  PublicStaff, ServiceArea,
  StateLaw,
  TmpColleges, Address, Phone, Topix, Rating, Wish, PlaceLoc, Key, TouristBoard
} from '../../services/interface.service';
import {BoundingBoxService} from '../../services/bounding-box.service';
import {ComResourceCodes, Days, Languages} from '../../services/constants';
import {BizDay, BizHour, BizhoursService, BizYear, BizYearCont} from '../../services/bizhours.service';
import * as moment from 'moment';
import {AngularFirestore, CollectionReference} from '@angular/fire/firestore';
import {RoehttpService} from '../../services/roehttp.service';
import * as XLSX from 'xlsx';
import * as geohash from 'ngeohash';
import * as _ from 'lodash';
import {Dbf} from 'dbf-reader';

import {DataTable} from 'dbf-reader/models/dbf-file';
import {TouristBoardIncludeService} from '../../services/tourist-board-include.service';

declare var google: any;

/*

const Client = require('ssh2-sftp-client');
const sftp = new Client();
*/


export class Holder {
  art: Article;
  st: string;
  use: string;
  category: string;
  current: string;
}

export class TmpC {
  id: number;
  name: string;
}

export class Incident {
  ukey: string;
  date: number;
  organization: string;
  target: string;
  perpetrator: string;
  arrest: string;
  long: number;
  lat: number;
  firstsentence: string;
  headline: string;
  link: string;
}

export class LU {
  date: number;
}


export class CountryEmbassy {
  countrycode: string; // two digit country code; this is also the ukey
  description: LangStringBlock;
  address: Address;
  phones: PhoneObj[];
  affiliate: string; // if not null then the 2 digit country code = the accreditated embassy
  type: string; // embassy, consulate
  note: string;

  constructor() {
    this.description = new LangStringBlock();
    this.address = new Address();
    this.phones = [];
    this.type = 'embassy';
  }
}

export class CountryCodes {
  alpha2: string;
  alpha3: string;
  UNCode: string;
  internet: string;

}

export class StateDept {
  sdlookup: string;
  sdurl: string;
  visa: string;
  vaccinations: string;
  embassies: CountryEmbassy[];

  constructor() {
    this.embassies = [];
  }
}

export class CountryCulture {
  tourist: string;
  customs: string;
  tipping: string;
  lgbtq: string;
  english: number;
}

export class PowerUtility {
  plug: string;
  volt: string;
  hertz: string;
}

export class MoneyUtility {
  name: string;
  symbol: string;
  iso: string;
  fraction: string;
  note: string;

}

export class PhoneUtility {
  code: string;
  idd: string;
  ndd: string;
}

export class CountryUtility {
  water: string;
  drivers: string;
  sideofroad: string;
  phoneutility: PhoneUtility;
  moneyutility: MoneyUtility;
  powerutility: PowerUtility;


  constructor() {
    this.phoneutility = new PhoneUtility();
    this.moneyutility = new MoneyUtility();
    this.powerutility = new PowerUtility();
  }
}

export class CountryObj {
  name: string;
  alphas: string; // 2 character code that is also the ukey
  codes: CountryCodes;
  statedept: StateDept;
  culture: CountryCulture;
  utilities: CountryUtility;

  constructor() {
    this.codes = new CountryCodes();
    this.statedept = new StateDept();
    this.culture = new CountryCulture();
    this.utilities = new CountryUtility();
  }
}


const ResourceType = [
  'Organization',
  'Link',
  'Person',
  'Jump',
  'Definition'
];


export class ColOffice {
  collegeukey: string;
  hrs: string;
  office: Office;
}

export class ColPublicStaff {
  officeid: number;
  collegeukey: string;
  staff: PublicStaff;
}

export class ColCard {
  collegeukey: string;
  card: Card;
}


export class ColPolicy {
  collegeukey: string;
  policy: Policy;
}

export class ColJumpLink {
  collegeukey: string;
  jumplink: JumpLink;
}

export class CommunityHolder {
  hrs: BizYearCont;
  id: number;
  comorg: ComOrg;
}

@Component({
  selector: 'app-import-articles',
  templateUrl: './import-articles.component.html',
  styleUrls: ['./import-articles.component.scss']
})
export class ImportArticlesComponent implements OnInit {

  rotemp: TmpColleges[];

  tmplibrary: ContentLibrary[];
  tmphld: Holder[];
  tmpincident: Incident[];
  tmplaws: StateLaw[];
  tmpcolleges: OrgProfile[];
  languages = Languages;
  comresources = ComResourceCodes;

  tmpcommunity: CommunityHolder[];

  tmpevents: Invitation[];

  coloffices: ColOffice[];
  colpublicstaff: ColPublicStaff[];
  colcards: ColCard[];
  colpolicies: ColPolicy[];
  coljumplinks: ColJumpLink[];

  filter: string;
  shownext: boolean;
  showart: boolean;

  bizhours_24: BizYear;
  regulardays_24: any[];
  hours_24: any[];
  twentfourseven_24: boolean;
  h;
  dys = Days;

  placearray: string[];

  orgsdir = 'Orgs/';
  orgpridir = 'OrgsPri';

  arr = [];
  nextpage;

  roles = [
    'Blog',
    'Country',
    'Countries',
    'Incidents',
    'Colleges',
    'Laws',
    'Library',
    'Campus Resources',
    'Community Resources',
    'roTemp',
    'Legacy Contacts',
    'Butterflies'
  ];


  cnt = 0;

  @ViewChild('serviceHelper', {static: false}) serviceHelper;


  // private db: AngularFireDatabase,
  constructor(public elementRef: ElementRef,
              private touristBoardService: TouristBoardIncludeService,
              private afs: AngularFirestore,
              private bnd: BoundingBoxService,
              private is: InterfaceService,
              private geo: GeofireService,
              private http: RoehttpService,
              private bz: BizhoursService,
              public admin: AdminService,
              public titleIXdb: FirebaseService,
              private papa: Papa) {
    this.shownext = false;
    this.showart = false;
  }


  makeJson(json: any[]): void {
    json.forEach(j => {
      const obj = {};
      obj['Country'] = '';
      obj['City'] = '';
      obj['Location'] = '';
      obj['ViewOf'] = '';
      obj['HostURL'] = '';
      obj['Inactive'] = false;
      obj['Processed'] = 0;
      obj['Stars'] = 0;
      obj['ukey'] = j.id.videoId;
      obj['Title'] = j.snippet.title;
      obj['Lat'] = -1000;
      obj['Lng'] = -1000;
      obj['Category'] = '';
      obj['CF'] = j.snippet.channelId;
      obj['CN'] = j.snippet.channelTitle;
      obj['Description'] = j.snippet.description;
      obj['Translate'] = '';
      obj['Sunrise'] = false;
      obj['Sunset'] = false;
      obj['GoodAtNight'] = 0;

      this.is.getObject(`Topics/TempInfo/finalcams/${obj['ukey']}`).take(1).subscribe(val => {
        // if the video is already in the database, then nothing more to do
        if (!val) {
          // first check to see if you can find the title
          this.is.getQuery(`Topics/TempInfo/finalcams`, 'Title', obj['Title']).take(1).subscribe(arr => {
            if (arr && arr.length === 1) {
              // if title exists this means that only the embed url has to change
              const newobj = arr[0];
              // update the ukey
              const oldkey = newobj['ukey'];
              if (oldkey) {
                newobj['ukey'] = obj['ukey'];
                // save the cam to its new location
                this.is.setObjNoLog(`Topics/TempInfo/finalcams/${obj['ukey']}`, newobj);
                // remove the old object
                this.is.deleteObjNoLog(`Topics/TempInfo/finalcams/${oldkey}`);
              }
            } else {
              // getting here means new cam
              if (obj['ukey']) {
                this.admin.postTrans('en', obj['Description']).then(val => {
                  if (val && val['data'] && val['data'].translations) {
                    obj['Description'] = val['data'].translations[0].translatedText;
                  }
                  this.is.setObjNoLog(`Topics/TempInfo/finalcams/${obj['ukey']}`, obj);
                  this.is.setObjNoLog(`Topics/TempInfo/newcams/${obj['ukey']}`, obj);
                });
              }
            }
          });
        }
      });
    });
  }


  onMakeMag() {
    /*
        const nme = 'lowseasontraveller';
        const mag = {
          'adminemail': '',
          'adminname': '',
          'archived': false,
          'date': 1633639675902,
          'description': {
            'ar': {
              'block': false,
              'description': ''
            },
            'de': {
              'block': false,
              'description': ''
            },
            'en': {
              'block': false,
              'description': 'Low Season Traveller is a collection of travel industry aficionado’s who have travelled the world during the low seasons and are passionate about the experiences to be had during these quieter times.'
            },
            'es': {
              'block': false,
              'description': ''
            },
            'fr': {
              'block': false,
              'description': ''
            },
            'it': {
              'block': false,
              'description': ''
            },
            'ko': {
              'block': false,
              'description': ''
            },
            'pl': {
              'block': false,
              'description': ''
            },
            'pt_pt': {
              'block': false,
              'description': ''
            },
            'ru': {
              'block': false,
              'description': ''
            },
            'vi': {
              'block': false,
              'description': ''
            },
            'zh_cn': {
              'block': false,
              'description': ''
            }
          },
          'domain': 'https://lowseasontraveller.com/',
          'edition': 'Adventures',
          'feed': '',
          'icon': 'https://lowseasontraveller.com/wp-content/uploads/2019/10/cropped-OC_0023_LST_3D_LOGO_LONG-1024x301.png',
          'name': 'Low Season Traveller',
          'objectID': nme,
          'org': 'Magazine',
          'paid': false,
          'prosurveydesc': {
            'ar': {
              'block': false,
              'description': ''
            },
            'de': {
              'block': false,
              'description': ''
            },
            'en': {
              'block': false,
              'description': ''
            },
            'es': {
              'block': false,
              'description': ''
            },
            'fr': {
              'block': false,
              'description': ''
            },
            'it': {
              'block': false,
              'description': ''
            },
            'ko': {
              'block': false,
              'description': ''
            },
            'pl': {
              'block': false,
              'description': ''
            },
            'pt_pt': {
              'block': false,
              'description': ''
            },
            'ru': {
              'block': false,
              'description': ''
            },
            'vi': {
              'block': false,
              'description': ''
            },
            'zh_cn': {
              'block': false,
              'description': ''
            }
          },
          'public': true,
          'servicearea': false,
          'tags': '',
          'topic': nme,
          'type': 'magazine',
          'ukey': nme
        };

        console.log('mag', mag);
        console.log('path:', `Topics/Travelmags/${nme}`);
        this.is.setObjNoLog(`Topics/Travelmags/${nme}`, mag);
    */

    this.is.getList('Topics/Travelmags/thirllest/repos').subscribe(list => {
      this.is.setObjNoLog('Topics/Travelmags/thrillist/repos', list);
    });

  }


  onPlaces() {
    console.log('OnPlaces');

    const places = [
      {npscode: 'the-white-cliffs-of-dover', name: 'The White Cliffs of Dover', ukey: 'ChIJ2eN1ICCl3kcRoAqebqlz_Lo'},
    ];
    places.forEach(p => {
      this.is.getObject(`Places/${p.ukey}/location`).take(1).subscribe(place => {
        if (!place) {
          console.log('FUCK', p.ukey);
        } else {
          this.is.getObject(`Places/${p.ukey}/viator`).take(1).subscribe(viator => {
            let vcountry = 0;
            let vdestinationId = 0;
            let vrating = 0;
            let vseoId = -1;
            let vurl = '';
            if (viator) {
              vcountry = viator.country;
              vdestinationId = viator.destinationId;
              vrating = viator.rating;
              vseoId = viator.seoId;
              vurl = viator.url;
            }
            console.log(p.npscode, '|', p.name, '|', place.hash, '|', place.lat, '|', place.long, '|', place.country, '|', p.ukey, '|', vcountry, '|', vdestinationId, '|', vrating, '|', vseoId, '|', vurl);
          });
        }
      });
    });

  }


  // gets rid of hotels with no prices
  /*
      this.is.getLimitToNextnKeys(10000, 'Topics/HotelPrices', 'ChIJH5GhNTzRT48Rk4J79CVH2q0').take(1).subscribe(hotels =>{
      let cnt = 0
      hotels.forEach(hotel => {
        const keys = Object.keys(hotel)
        const arr = Object.values(hotel[keys[0]]);


        console.log('ukey', arr[0]['ukey']);
        if (arr) {
          let cost = 0;
          arr.forEach(a => {
            if (a['cost'] > 0) {
              cost = a['cost']
            }
          })

          if (cost === 0 && arr[0] && arr[0]['ukey'] && arr[0]['ukey'].length) {
            cnt +=  1
            console.log(cnt, 'make this hotel inactive', `Topics/TempInfo/hotels/${arr[0]['ukey']}/inactive`)
            this.is.setObjNoLog(`Topics/TempInfo/hotels/${arr[0]['ukey']}/inactive`, true)
            this.is.deleteObjNoLog(`Topics/HotelPrices/${arr[0]['ukey']}`)
          }
        }
      })
    })



   */


  placesftn() {
    const ids = [
    ];

    ids.forEach(id => {
      this.is.getObject(`Places/${id}/name`).take(1).subscribe(name => {
        if (!name) {
          console.log(id);
        }
      });
    });
  }


  ngOnInit() {
    //this.placesftn();
    // this.onPlaces(); getFirstSnapKeys(n, path, startkey)

    /*
        this.http.checkLiveStream('UCpbfMjXmpKH61jU-N2VEyrQ').subscribe(res => {
          console.log('res', res)
        })
    */


    /*
        this.is.getList('Institutions/travelingmom/published').subscribe(articles => {
          articles.forEach(a => {
              console.log('a m inframe', a)
              this.is.setObjNoLog(`Institutions/travelingmom/published/${a.ukey}/m/inframe`, false)
              this.is.setObjNoLog(`Topics/TravelNotifications/${a.ukey}/m/inframe`, false)
          })
        })
    */


    // these are the mags that dont have pubdates
    // const mags = ['travelandleisure','suitcasemag','heremagazine','outlooktravelmag','planetware','tripsavvy','wanderlust']


    /*
            this.is.getList(`Topics/TempInfo/newcams`).take(1).subscribe(list => {
              list.forEach(l => {
                console.log('path', `Topics/TempInfo/finalcams/${l.ukey}`);
                this.is.setObjNoLog(`Topics/TempInfo/finalcams/${l.ukey}`, l);
              });
            });
    */


    // this.is.deleteObjNoLog(`Topics/TempInfo/finalcams`);


    /*
        this.is.deleteObjNoLog(`Topics/TempInfo/PlaceNames`)
    */

    /*
        const parser = new Parser('/Users/richardzandi/Downloads/geo/AZE/gadm40_AZE_1.dbf');
        parser.on('start', (p) => {
          console.log('dBase file parsing has started');
        });

        parser.on('header', (h) => {
          console.log('dBase file header has been parsed');
        });

        parser.on('record', (record) => {
          console.log('record', record); // Name: John Smith
        });

        parser.on('end', (p) => {
          console.log('Finished parsing the dBase file');
        });

        parser.parse();
    */


    this.rotemp = [];
    this.tmphld = [];
    this.tmplibrary = [];
    this.tmpincident = [];
    this.tmplaws = [];
    this.tmpcommunity = [];

    this.coloffices = [];
    this.colpublicstaff = [];
    this.colcards = [];
    this.coljumplinks = [];
    this.colpolicies = [];

    /*
       this.h = [];
       this.is.getList('Holidays/US').take(1).subscribe(hol => {
         this.h = hol;
       });


       this.fetchhours247();
   */

    /*
            sftp.connect({
                host: 'oitftp.risd.edu',
                port: '',
                username: 'Capptivation',
                password: 'C@ppFTP#5'
            }).then(() => {
                return sftp.list('/pathname');
            }).then(data => {
                console.log(data, 'the data info');
            }).catch(err => {
                console.log(err, 'catch error');
            });
    */


    /*        Client.on('ready', () => {
                console.log('Client :: ready');
                Client.sftp((err, sftp) => {
                    if (err) {
                        throw err;
                    }
                    sftp.readdir('', (errr, list) => {
                        if (errr) {
                            throw errr;
                        }
                        console.dir(list);
                        Client.end();
                    });
                });
            }).connect({
                host: 'oitftp.risd.edu',
                port: '',
                username: 'Capptivation',
                password: 'C@ppFTP#5'
            });*/

  }

  fetchhours247() {
    this.bz.getregulardayz('ohrs/247/regulardays').take(1).subscribe(hrs => {
      this.regulardays_24 = hrs;

      this.bz.gettwentfoursevenz('ohrs/247/twentyfourseven').take(1).subscribe(s => {
        this.twentfourseven_24 = s;

        this.bz.getbizhourz('ohrs/247/hours').take(1).subscribe(h => {
          this.hours_24 = h;
          // this.bizhours = this.setuphours(this.bizhours);
          this.bizhours_24 = this.admin.setuphours(this.hours_24, this.regulardays_24, this.twentfourseven_24);
        });
      });
    });
  }


  onChange(e) {
    this.filter = e.value;
    this.shownext = true;
  }


  /*
      onFix() {
          this.is.getList('Community').take(1).subscribe(coms => {
              coms.forEach(hld => {
                  if (hld.locations[0] && hld.locations[0].geo && hld.locations[0].geo.lat && hld.locations[0].geo.long) {
                      this.geo.setLocation(`${hld.ukey}^locations^0`, [hld.locations[0].geo.lat * 1, hld.locations[0].geo.long * 1]);
                  }

              });
          });
      }
  */


  onSave() {
    const x = 0;
    if (this.filter === 'Incidents') {
      let didimport = true;
      this.tmpincident.forEach(hld => {
        this.titleIXdb.titleIXdb.object('Incidents/' + hld.ukey).set(hld)
          .then()
          .catch(err => didimport = false);
      });
      if (didimport) {
        alert('Incidents imported');
      } else {
        alert('tabs failed');
      }

    }
    // End of Incidents
    if (this.filter === 'Library') {
      let didimport = true;
      this.tmplibrary.forEach(hld => {
        this.titleIXdb.titleIXdb.object('Library/' + hld.ukey).set(hld)
          .then()
          .catch(err => didimport = false);
      });
      if (didimport) {
        alert('library imported');
      } else {
        alert('import failed');
      }

    }
    // End of Library
    if (this.filter === 'Laws') {
      let didimport = true;
      this.tmplaws.forEach(hld => {
        if (hld.lastupdate) {
          this.titleIXdb.titleIXdb.object('StateLaws/' + hld.state + '/' + hld.name).set(hld)
            .then()
            .catch(err => didimport = false);
        }
      });
      if (didimport) {
        alert('State laws imported');
      } else {
        alert('tabs failed');
      }

    }
    // End of Laws

    if (this.filter === 'roTemp') {
      this.titleIXdb.titleIXdb.object(`LegacyColleges`).remove();
      this.rotemp.forEach(t => {
        this.titleIXdb.titleIXdb.object(`LegacyColleges/${t.collegeID}`).set(t);
      });

    }


    let h = new Array();

    /*
            if (this.filter === 'Colleges') {
                this.tmpcolleges.forEach(hld => {
                    this.titleIXdb.titleIXdb.object(`Calc/Org/${hld.orglegacyextra.kwabid}`)
                        .set({name: hld.ukey, id: hld.orglegacyextra.kwabid});
                });
            }
    */

    if (this.filter === 'Colleges') {
      let didimport = true;
      this.is.getList('Holidays/US').take(1).subscribe(hol => {
        h = hol;
        this.tmpevents = new Array();
        h.forEach(t => {
          const v = new Invitation();
          v.ukey = t.ukey; // this is the ukey
          v.title = t.name;
          v.headlineDesc = t.headlineDesc;
          v.start = t.date;
          v.end = -1;
          this.tmpevents.push(v);
        });


        this.tmpcolleges.forEach(hld => {
          this.is.setObjNoLog(`${this.orgpridir}/${hld.ukey}/topics/${this.titleIXdb.titleIXdb.createPushId()}`, hld.ukey);

          this.is.setObjNoLog(`${this.orgpridir}/${hld.ukey}/topics/${this.titleIXdb.titleIXdb.createPushId()}`, `${hld.ukey} - Reach Out`);

          this.titleIXdb.titleIXdb.object(`${this.orgsdir}/${hld.ukey}/profile`).set(hld)
            .then()
            .catch(err => didimport = false);

          this.titleIXdb.titleIXdb.object('EdLists/Survivor/' + hld.ukey).set({'ukey': hld.ukey})
            .then()
            .catch(err => didimport = false);


          this.titleIXdb.titleIXdb.object(`OrgsByType/Colleges/${hld.ukey}`).set({'ukey': hld.ukey})
            .then()
            .catch(err => didimport = false);

          this.tmpevents.forEach(ev =>
            this.is.setObj(`${this.orgsdir}/${hld.ukey}/holidays/${ev.ukey}`, ev, 'Legacy', '')
          );


        });
        if (didimport) {
          alert('Colleges imported');
        } else {
          alert('tabs failed');
        }
      });
    }


    if (this.filter === 'Legacy Contacts') {
      this.colpublicstaff.forEach(hld => {
        const pth = `${this.orgsdir}/${hld.collegeukey}/resources/staff/${hld.staff.ukey}`;


        this.is.setObj(pth, hld.staff, 'Legacy', '');
        this.is.setObj(`LegacyLookup/${hld.staff.orglegacyextra.kwabid}`, pth, 'Legacy', '');

        if (hld.staff.primaryservice) {
          this.titleIXdb.titleIXdb.object(`${this.orgsdir}/${hld.collegeukey}/resources/list/${hld.staff.ukey}`).set(hld.staff.primaryservice);
        }

        this.is.getObject(`LegacyLookup/${hld.officeid}`).take(1).subscribe(val => {
          this.is.pushObj(`${val}/staff`, pth);
        });
      });
    }


    if (this.filter === 'Campus Resources') {
      let didimport = true;
      this.coloffices.forEach(hld => {
        const pth = `${this.orgsdir}/${hld.collegeukey}/resources/offices/${hld.office.ukey}`;
        this.is.setObj(pth, hld.office, 'Legacy', '');

        this.is.setObj(`LegacyLookup/${hld.office.orglegacyextra.kwabid}`, pth, 'Legacy', '');

        switch (hld.hrs) {
          case '247' :
            this.bz.savebuisinesshours(pth, this.bizhours_24);

            break;
          default :
        }

        if (hld.office.primaryservice) {
          this.titleIXdb.titleIXdb.object(`${this.orgsdir}/${hld.collegeukey}/resources/list/${hld.office.ukey}`).set(hld.office.primaryservice);
        }

      });
      if (didimport) {
        alert('Offices imported');
      } else {
        alert('tabs failed');
      }

      this.coljumplinks.forEach(hld => {
        const pth = `${this.orgsdir}/${hld.collegeukey}/resources/links/${hld.jumplink.ukey}`;

        this.is.setObj(pth, hld.jumplink, 'Legacy', '');

        this.is.setObj(`LegacyLookup/${hld.jumplink.orglegacyextra.kwabid}`, pth, 'Legacy', '');

        if (hld.jumplink.primaryservice) {
          this.titleIXdb.titleIXdb.object(`${this.orgsdir}/${hld.collegeukey}/resources/list/${hld.jumplink.ukey}`).set(hld.jumplink.primaryservice);
        }

      });
      if (didimport) {
        alert('Links imported');
      } else {
        alert('tabs failed');
      }

      this.colcards.forEach(hld => {
        const pth = `${this.orgsdir}/${hld.collegeukey}/resources/cards/${hld.card.ukey}`;

        this.is.setObj(pth, hld.card, 'Legacy', '');

        this.is.setObj(`LegacyLookup/${hld.card.orglegacyextra.kwabid}`, pth, 'Legacy', '');

        if (hld.card.primaryservice) {
          this.titleIXdb.titleIXdb.object(`${this.orgsdir}/${hld.collegeukey}/resources/list/${hld.card.ukey}`).set(hld.card.primaryservice);
        }
      });
      if (didimport) {
        alert('Cards imported');
      } else {
        alert('tabs failed');
      }

      this.colpolicies.forEach(hld => {
        const pth = `${this.orgsdir}/${hld.collegeukey}/resources/policies/${hld.policy.ukey}`;

        this.is.setObj(pth, hld.policy, 'Legacy', '');

        this.is.setObj(`LegacyLookup/${hld.policy.orglegacyextra.kwabid}`, pth, 'Legacy', '');

        if (hld.policy.primaryservice) {
          this.titleIXdb.titleIXdb.object(`${this.orgsdir}/${hld.collegeukey}/resources/list/${hld.policy.ukey}`).set(hld.policy.primaryservice);
        }
      });
      if (didimport) {
        alert('Policies imported');
      } else {
        alert('tabs failed');
      }

      // todo: need to add bizhours here; just like offices
      this.colpublicstaff.forEach(hld => {
        const pth = `${this.orgsdir}/${hld.collegeukey}/resources/staff/${hld.staff.ukey}`;

        this.is.setObj(pth, hld.staff, 'Legacy', '');
        this.is.setObj(`LegacyLookup/${hld.staff.orglegacyextra.kwabid}`, pth, 'Legacy', '');

        if (hld.staff.primaryservice) {
          this.titleIXdb.titleIXdb.object(`${this.orgsdir}/${hld.collegeukey}/resources/list/${hld.staff.ukey}`).set(hld.staff.primaryservice);
        }
      });
      if (didimport) {
        alert('People imported');
      } else {
        alert('tabs failed');
      }
    }

    if (this.filter === 'Community Resources') {
      const prefix = '';
      // this.titleIXdb.titleIXdb.object(`${prefix}Community`).remove();
      // this.titleIXdb.titleIXdb.object(`${prefix}OrgsByType/${prefix}Community`).remove();
      // this.titleIXdb.titleIXdb.object(`${prefix}Locations`).remove();
      // this.titleIXdb.titleIXdb.object(`${prefix}LegacyLookup`).remove();

      let didimport = true;
      let x = 0;
      const dbRef = this.afs;
      this.tmpcommunity.forEach(hld => {

        // this.titleIXdb.titleIXdb.object(`Calc/Com/${hld.comorg.orglegacyextra.kwabid}`).set({name: hld.comorg.ukey, id: hld.comorg.orglegacyextra.kwabid});

        /*
                        if (hld.comorg.locations[0].geo.lat && hld.comorg.locations[0].geo.long) {
                            this.geo.setLocation(hld.comorg.ukey, [hld.comorg.locations[0].geo.lat * 1, hld.comorg.locations[0].geo.long * 1]);
                        }
        */


        /*
                        dbRef.doc(`Community/${hld.comorg.ukey}`).valueChanges().subscribe(snaps => {
                            console.log('snap', snaps)
                        })

                        dbRef.collection(`Schedule`).snapshotChanges().subscribe(scheds => {
                            console.log('scheds', scheds)
                        })
        */


//                dbRef.doc(`Community/${hld.comorg.ukey}`).delete();
//                dbRef.doc(`Schedule/-LqNdMMPmfHjKupbzFfB`).delete();

        /*
                        const str = '[{"time":"2019-10-04T14:00:00.000Z","open":true,"len":60,"tm":"10:00:00"},{"time":"2019-10-04T15:00:00.000Z","open":true,"len":60,"tm":"11:00:00"},{"time":"2019-10-04T16:00:00.000Z","open":true,"len":60,"tm":"12:00:00"},{"time":"2019-10-04T17:00:00.000Z","open":true,"len":60,"tm":"13:00:00"},{"time":"2019-10-04T18:00:00.000Z","open":true,"len":60,"tm":"14:00:00"},{"time":"2019-10-04T19:00:00.000Z","open":true,"len":60,"tm":"15:00:00"},{"time":"2019-10-04T20:00:00.000Z","open":true,"len":60,"tm":"16:00:00"}]'
                        const s = JSON.parse(str)
                        console.log('str', s)
        */
        dbRef.doc(`Community/${hld.comorg.ukey}`).set(JSON.parse(JSON.stringify(hld.comorg)));
        // this.bz.savebuisinesshoursContSF(hld.comorg.ukey, hld.comorg.orglegacyextra.is24, hld.comorg.locations[0].ukey, hld.hrs);


        /*
                        this.titleIXdb.titleIXdb.object(`${prefix}Community/${hld.comorg.ukey}`).set(hld.comorg)
                            .then(val => {
                                    this.is.createOrgLog(`${prefix}Community/${hld.comorg.ukey}`, 'Add/Edit', 'Legacy', '');
                                    if (hld.comorg.orglegacyextra.is24) {
                                        this.bz.savebuisinesshours(`${prefix}Community/${hld.comorg.ukey}`, this.bizhours_24);
                                    }
                                }
                            )
                            .catch(err => didimport = false);
        */

        /*
                        this.titleIXdb.titleIXdb.object(`${prefix}OrgsByType/${prefix}Community/${hld.comorg.ukey}`).set({'ukey': hld.comorg.ukey})
                            .then()
                            .catch(err => didimport = false);


                        const s = `${prefix}Community/${hld.comorg.ukey}`;
                        this.is.setObj(`${prefix}LegacyLookup/${hld.id}`, s, 'Legacy', '');
        */


      });
      if (didimport) {
        alert('Community Resources imported');
      } else {
        alert('Community Resources failed');
      }

    }

  }

  onScrape() {
    console.log('scrape does not do anything');
  }

  findabbr(str): string {
    let t = '';
    const s = str.split(',');
    this.comresources.forEach(c => {
      if (c.desc === s[0]) {
        t = c.desc;
      }
    });
    return t;
  }

  findicon(str): string {
    let t = '';
    this.comresources.forEach(c => {
      if (c.desc === str) {
        t = c.icon;
      }
    });
    return t;
  }

  createHrs(str: string, name) {
    const tmp = JSON.parse(str);
    const rds = [];
    const newdays = {};
    const tmparr = {};
    const darr = tmp[0]['weekly_schedule'];
    for (let x = 0; x < 7; x = x + 1) {
      const newday = new BizDay();
      newday.color = '#000000';
      newday.day = new Date();
      newday.open = false;
      newday.holiday = false;
      const newblocks = new Array();
      const y = darr.findIndex(d => d['day'] === x);
      if (y === -1) {

        /*
                        // make a regalar day with all blanks;
                        const blocks = new Array();
                        const b = new BizHour();
                        b.time = new Date();
                        b.open = false;
                        b.time.setHours(0, 0, 0, 0);
                        b.len = 359;
                        /!*
                                        b.tm = b.time.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*!/, '$1');
                        *!/
                        b.tm = b.time.toTimeString().substring(0, 8);
                        blocks.push(b);

                        for (let ix = 6; ix < 20; ix++) {
                            const bh = new BizHour();
                            bh.time = new Date();
                            bh.open = false;
                            bh.len = 59;
                            bh.time.setHours(ix, 0, 0);
                            /!*
                                                bh.tm = bh.time.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*!/, '$1');
                            *!/
                            bh.tm = bh.time.toTimeString().substring(0, 8);
                            blocks.push(bh);
                        }
                        newday.blocks = blocks
        */


        newday.blocks = this.admin.makeBlocks();
        newdays[x] = newday;
        // rds.push(blocks);
      } else {
        const endhr = parseInt(darr[y]['close'][0] + darr[y]['close'][1], 10);
        const endmin = parseInt(darr[y]['close'][2] + darr[y]['close'][3], 10);
        const endinmin = endhr * 60 + endmin;

        const openhr = parseInt(darr[y]['open'][0] + darr[y]['open'][1], 10);
        const openmin = parseInt(darr[y]['open'][2] + darr[y]['open'][3], 10);
        const len = [];
        const blocks = new Array();
        const b = new BizHour();
        b.time = new Date();
        b.open = false;
        b.time.setHours(0, 0, 0, 0);
        b.len = openhr * 60 + openmin;
        b.tm = b.time.toTimeString().substring(0, 8);
        blocks.push(b);

        let st = openhr * 60 + openmin;
        let end = Math.trunc((openhr * 60 + openmin + 60) / 60) * 60;
        const b1 = new BizHour();
        b1.time = new Date();
        b1.open = true;
        b1.time.setHours(openhr, openmin, 0, 0);
        b1.len = end - st;
        b1.tm = b1.time.toTimeString().substring(0, 8);
        blocks.push(b1);

        st = end;
        while (st + 60 <= 24 * 60) {
          if (st < endinmin) {
            if (st + 60 > endinmin) {
              end = endinmin;
            } else {
              end = st + 60;
            }
            const bx = new BizHour();
            bx.time = new Date();
            bx.open = true;
            const hr = Math.trunc((st) / 60);
            const min = st - hr * 60;
            bx.time.setHours(hr, min, 0, 0);
            bx.len = end - st;
            bx.tm = bx.time.toTimeString().substring(0, 8);
            blocks.push(bx);
            st = end;
            newday.open = true;
          } else {
            if ((st % 60) !== 0) {
              // todo; odd period
              end = st + st % 60;
              const by = new BizHour();
              by.time = new Date();
              by.open = false;
              const hr = Math.trunc((st) / 60);
              const min = st - hr * 60;
              by.time.setHours(hr, min, 0, 0);
              by.len = end - st;
              by.tm = by.time.toTimeString().substring(0, 8);
              blocks.push(by);
              st = end;
            } else {
              end = st + 60;
              const by = new BizHour();
              by.time = new Date();
              by.open = false;
              const hr = Math.trunc((st) / 60);
              const min = st - hr * 60;
              by.time.setHours(hr, min, 0, 0);
              by.len = end - st;
              by.tm = by.time.toTimeString().substring(0, 8);
              blocks.push(by);
              st = end;
            }
          }
        } // end while loop
        newday.blocks = blocks;
        newdays[x] = newday;
        rds.push(blocks);
      }
    }

    /*
            const year = new Date().getFullYear();
            const month = new Date().getMonth();
            const date = new Date().getDate();
            let std = new Date(year, month, date, 0, 0, 0, 0);
            for (let d = 0; d < 367; d++) {
                const day = new Date(std.getTime() + 86400000 * d);
                if (rds[day.getDay()].length > 0) {
                    const ukey = day.getTime()
                    tmparr[ukey] = JSON.stringify(rds[day.getDay()]);
                }
            }
    */

    const tmpreg = {};
    let cnt = 0;
    rds.forEach(r => {
      tmpreg[cnt] = JSON.stringify(r);
      cnt = cnt + 1;
    });

    const by = new BizYearCont();
    by.regulardays = newdays; // tmpreg;
    by.hours = tmparr;
    by.twentyfourseven = false;
    return by;

  }

  is24(str: string): boolean {
    if (str) {
      const lookfor = `\"open\":\"0000\",\"close\":\"2359\"`;
      return str.includes(lookfor);
    } else {
      return false;
    }

  }


  servicearea(x): ServiceArea {
    x = x.replace(/US-/g, '');
    let country = '';
    let city = '';
    let state = '';
    let county = '';
    let ar = new Array();
    if (x.indexOf(';') > -1) {
      ar = x.split(';');
    } else {
      ar[0] = x;
    }
    ar.forEach(a => {
        let tmp = new Array();
        tmp = a.split(':');
        switch (tmp[0]) {
          case 'county':
            if (county === '') {
              if (tmp[1].indexOf(',') > -1) {
                let tx = new Array();
                tx = tmp[1].split(',');
                county = tx[0];
              } else {
                county = tmp[1];
              }
            } else {
              if (tmp[1].indexOf(',') > -1) {
                let tx = new Array();
                tx = tmp[1].split(',');
                county = county + ';' + tx[0];
              } else {
                county = county + ';' + tmp[1];
              }
            }
            break;
          case 'city':
            if (city === '') {
              if (tmp[1].indexOf(',') > -1) {
                let tx = new Array();
                tx = tmp[1].split(',');
                city = tx[0];
              } else {
                city = tmp[1];
              }
            } else {
              if (tmp[1].indexOf(',') > -1) {
                let tx = new Array();
                tx = tmp[1].split(',');
                city = city + ';' + tx[0];
              } else {
                city = city + ';' + tmp[1];
              }
            }

            break;
          case 'state':
            if (state === '') {
              if (tmp[1].indexOf(',') > -1) {
                let tx = new Array();
                tx = tmp[1].split(',');
                state = tx[0];
              } else {
                state = tmp[1];
              }
            } else {
              if (tmp[1].indexOf(',') > -1) {
                let tx = new Array();
                tx = tmp[1].split(',');
                state = state + ';' + tx[0];
              } else {
                state = state + ';' + tmp[1];
              }
            }
            break;
          case'country':
            if (country === '') {
              if (tmp[1].indexOf(',') > -1) {
                let tx = new Array();
                tx = tmp[1].split(',');
                country = tx[0];
              } else {
                country = tmp[1];
              }
            } else {
              if (tmp[1].indexOf(',') > -1) {
                let tx = new Array();
                tx = tmp[1].split(',');
                country = country + ';' + tx[0];
              } else {
                country = country + ';' + tmp[1];
              }
            }
            break;
        }
      }
    );

    const val = new ServiceArea();
    val.counties = county;
    val.cities = city;
    val.states = state;
    val.countries = country;
    return val;
  }

  createphones(x): PhoneObj[] {
    const phones = new Array();
    const j = JSON.parse(x);
    j.forEach(p => {
      const ph = new PhoneObj();
      ph.phdescription = p.description;
      ph.digits = p.number;
      ph.notify = false;
      ph.private = false;
      phones.push(ph);
    });
    return phones;
  }

  saveAs(blob, fileName) {
    const url = window.URL.createObjectURL(blob);

    const anchorElem = document.createElement('a');
    anchorElem.href = url;
    anchorElem.download = fileName;

    document.body.appendChild(anchorElem);
    anchorElem.click();

    document.body.removeChild(anchorElem);

    // On Edge, revokeObjectURL should be called only after
    // a.click() has completed, atleast on EdgeHTML 15.15048
    setTimeout(function() {
      window.URL.revokeObjectURL(url);
    }, 1000);
  }

  changeListenerCountry(event: any) {
    console.log('files', event.target.files);
    if (event.target.files && event.target.files.length > 0) {
      this.placearray = [];
      this.parseFile(event.target.files[0], event.target.files.length);

      if (event.target.files.length > 1) {
        this.parseFile(event.target.files[1], event.target.files.length);

        if (event.target.files.length > 2) {
          this.parseFile(event.target.files[2], event.target.files.length);

          if (event.target.files.length > 3) {
            this.parseFile(event.target.files[3], event.target.files.length);

            if (event.target.files.length > 4) {
              this.parseFile(event.target.files[4], event.target.files.length);

              if (event.target.files.length > 5) {
                this.parseFile(event.target.files[5], event.target.files.length);

              }
            }
          }
        }
      }
    }
  }

  saveParseArray(filename: string) {
    this.placearray = _.uniq(this.placearray);
    const blob = new Blob(this.placearray, {type: 'text/plain'});
    const fileName = filename.split('.')[0].split('_')[0] +
      '_' +
      filename.split('.')[0].split('_')[1] +
      `.csv`;
    this.saveAs(blob, fileName);
    console.log('placearray', this.placearray);

  }

  parseFile(f, len) {
    const reader = new FileReader();

    const file: File = f;
    const filename = file.name;
    const adminnums = parseInt(filename.split('.')[0].split('_')[2], 10);

    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const arrayBuffer: ArrayBuffer = reader.result as ArrayBuffer;
      if (arrayBuffer) {
        const buffer: any = Buffer.from(arrayBuffer);
        const datatable: DataTable = Dbf.read(buffer);

        if (adminnums === 1) {
          this.placearray.push('Keyword,Chain,ID\n');
          this.placearray.push(datatable.rows[0]['COUNTRY'] + ',' + datatable.rows[0]['COUNTRY'] + ',ID' + '\n');
        }

        datatable.rows.forEach(row => {
          let engtype = row['ENGTYPE_' + adminnums].replace(/\((.*?)\)/g, '').trim();
          if (engtype.includes('|')) {
            engtype = engtype.split('|')[0];
          }

          const str = row['NAME_' + adminnums].replace(/\((.*?)\)/g, '').trim() + ' ' +
            engtype + '|' +
            row['COUNTRY'].replace(/\((.*?)\)/g, '').trim();

          let fullname;
          if (adminnums === 1) {
            fullname = row['NAME_1'] + '|' + row['COUNTRY'];
          } else if (adminnums === 2) {
            fullname = row['NAME_2'] + '|' + row['NAME_1'] + '|' + row['COUNTRY'];
          } else if (adminnums === 3) {
            fullname = row['NAME_3'] + '|' + row['NAME_2'] + '|' + row['NAME_1'] + '|' + row['COUNTRY'];
          } else if (adminnums === 4) {
            fullname = row['NAME_4'] + '|' + row['NAME_3'] + '|' + row['NAME_2'] + '|' + row['NAME_1'] + '|' + row['COUNTRY'];
          } else if (adminnums === 5) {
            fullname = row['NAME_5'] + '|' + row['NAME_4'] + '|' + row['NAME_3'] + '|' + row['NAME_2'] + '|' + row['NAME_1'] + '|' + row['COUNTRY'];
          }


          this.placearray.push(str + ',' + fullname + ',' + row['ID_' + adminnums] + '\n');
        });

        if (len === adminnums) {
          console.log('len', len, adminnums, filename);
          this.saveParseArray(filename);
        }
      }
    };
  }

  changeListenerMag($event: any) {
    this.showart = true;
    this.tmpincident = [];
    this.tmphld = [];
    this.tmpcolleges = [];
    this.coloffices = [];
    const self = this;
    const file: File = $event.target.files[0];
    const filename = file.name;
    console.log('filename', filename);
    const myReader: FileReader = new FileReader();
    myReader.readAsText(file);
    const resultSet = [];
    myReader.onloadend = function(e) {
      // you can perform an action with data read here
      // as an example i am just splitting strings by spaces
      self.papa.parse(myReader.result.toString(), {
        complete: (results, filed) => {

          // This function is called to prepare a AUS.csv file for further processing
          results.data.forEach(rec => {
            console.log('here');
            const item = {};
            item['adminemail'] = ''; // rec[0];
            item['adminname'] = ''; // rec[1];
            item['archived'] = false; // rec[2];
            item['date'] = 1633639675897; // rec[3];
            item['description'] = new LangStringBlock();
            item['description']['en']['description'] = rec[4];
            item['domain'] = rec[5];
            item['edition'] = rec[6];
            item['feed'] = rec[7];
            item['icon'] = rec[8];
            // item['items'] = rec[9];
            item['name'] = rec[10];
            item['objectID'] = rec[11];
            item['org'] = rec[12];
            item['paid'] = rec[13] as boolean;
            item['prosurveydesc'] = new LangStringBlock(); // rec[14];
            item['public'] = true; // rec[15];
            // item['published'] = rec[16];
            item['servicearea'] = false; // rec[17];
            item['tags'] = ''; // rec[18];
            item['topic'] = rec[19];
            item['type'] = rec[20];
            item['ukey'] = rec[21];

            // self.is.setObjNoLog(`Topics/Travelmags/${rec[21]}`, item)

            console.log('item', item);

          });
        }
      });
    };
  }


  placeExists(obj: any) {
    // need to calculate the code
    let code = '';
    if (obj['name']) {
      code = 'N';
    }
    if (obj['location']) {
      code = 'NL';
    }
    if (obj['location'] && obj['location']['lat']) {
      code = 'NLG';
    }
    if (obj['location'] && obj['location']['lat'] && obj['location']['bounds']) {
      code = code + 'LGB';
    }
    if (obj['chain']) {
      code = code + 'C';
    }


    if (obj.type !== 'POI') {
      // TODO: the X means that later I need to revisit the ukey and add the locality to the GEO database as well as get the chains
      // when i am done, i need to removed the X from code and add a C (indicating chain updates)
      code = code + 'X';
    }

    if (obj['ukey']) {
      console.log('PlaceExitst', obj['ukey']);
      this.is.setObjNoLog(`PlaceExists/${obj['ukey']}`, {code, ukey: obj['ukey']});
    }
  }


  getPlace(ukey) {
    const request = {
      placeId: ukey,
      fields: [
        'place_id',
        'address_components',
        'formatted_address',
        'name',
        'types',
        'geometry',
        'utc_offset_minutes',
        'photos',
        'website'
      ]
    };


    const service = new google.maps.places.PlacesService($('#service-helper').get(0));
    service.getDetails(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        // 1. create a PlaceLoc
        const newloc = new PlaceLoc();
        const country = this.admin.getCountry(place.address_components, true);
        const state = this.admin.getState(place.address_components, true);
        const city = this.admin.getCity(place.address_components, true);
        if (country === 'US') {
          if (city) {
            newloc.loc = `${city}, ${state}`;
            newloc.location = `${city}, ${state}`;
          } else {
            newloc.loc = `${state}, ${country}`;
            newloc.location = `${state}, ${country}`;
          }
        } else {
          newloc.loc = `${city}, ${country}`;
          newloc.location = `${city}, ${country}`;
        }

        newloc.long = place.geometry.location.lng();
        newloc.lat = place.geometry.location.lat();
        if (place.geometry.bounds) {
          newloc.bounds = place.geometry.bounds;
        }
        if (place.geometry.viewport) {
          newloc.viewport = place.geometry.viewport;
        }
        newloc.hash = geohash.encode(newloc.lat, newloc.long);
        newloc.address = place.formatted_address; // this is an address
        newloc.utc_offset_minutes = place.utc_offset_minutes;
        newloc.keys = [];
        const key = new Key();
        key.id = place.place_id;
        key.name = 'Google';
        newloc.keys.push(key);
        // newloc.tzone: string;
        let website = '';
        if (place.website) {
          website = place.website;
        }

        let type = '';
        if (place.types) {
          if (place.types[0].includes('sublocality') || place.types[0].includes('neighborhood')) {
            type = 'neighborhood';
          } else if (place.types[0].includes('locality')) {
            type = 'locality';
          } else if (place.types[0].includes('country')) {
            type = 'country';
          } else {
            type = 'POI';
          }
        }

        const hash = geohash.encode(place.geometry.location.lat(), place.geometry.location.lng());
        let nm;
        if (type && (type === 'POI' || (newloc && newloc.loc && (!newloc.loc.includes(',') || ((newloc.loc.includes(', US') && newloc.loc.length === 6)))))) {
          nm = place.name;
        } else {
          nm = newloc.loc;
        }

        // save record to Places
        if (place.photos && place.photos.length > 0) {
          const parr = [];
          let googlephotos;
          place.photos.forEach(p => {
            parr.push(p.getUrl());
          });
          this.http.getGoogleJustPhotos(parr).then(
            photosreturn => {

              const photos = Object.values(photosreturn);
              if (photos && photos.length > 0) {
                googlephotos = [];
                photos.forEach(p => {
                  googlephotos.push({url: p, who: 'Google'});
                });
              }

              const obj = {
                ukey: place.place_id,
                hash: newloc.hash,
                name: place.name,
                location: newloc,
                address: place.formatted_address,
                website,
                types: place.types,
                type,
                photos: googlephotos
              };

              this.is.setObjNoLog(`Places/${place.place_id}/ukey`, obj.ukey);  // ukey
              this.is.setObjNoLog(`Places/${place.place_id}/hash`, obj.hash);  // hash
              this.is.setObjNoLog(`Places/${place.place_id}/name`, obj.name);  // name
              this.is.setObjNoLog(`Places/${place.place_id}/location`, obj.location); // location
              this.is.setObjNoLog(`Places/${place.place_id}/address`, obj.address); // address
              this.is.setObjNoLog(`Places/${place.place_id}/types`, obj.types); // types
              this.is.setObjNoLog(`Places/${place.place_id}/type`, obj.type); // type
              this.is.setObjNoLog(`Places/${place.place_id}/photos`, obj.photos); // photos

              this.placeExists(obj);

            });
        }
      }
    });
  }

  // This code is to import into Places data gathered by md5.py
  // Step 1: see if place already exists. if it does, then simply calculte the md5's
  /*
0 href
1 hrefgo
2 Website URL
3 websitego
4 Name
5 Subtitle
6 Data_Pid
7 Data_URL
x8 Located in
9 Address
10 Description
11 Description Source Link
12 Wikipedia
x13 mapurl
14 Lat
15 Long
16 images
17 kingdom
18 phylum
19 class

*/


  delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  touristBoardWork(loc: any, placeUkey: string, taxonomy, tix) {
    if (placeUkey && placeUkey.length) {
      this.touristBoardService.getFilteredTouristBoardIdsLoc(loc).then(ids => {
        console.log('JJJJ', ids)
        if (ids && ids.length) {
          // 1. save the touristukey to moreinfo
          console.log('PPP', `Places/${placeUkey}/moreinfo/touristukey`)
          if (ids[0] !== 'N.A.') {
            this.is.setObjNoLog(`Places/${placeUkey}/moreinfo/touristukey`, ids[0])
          }

          // 2. save the taxonomy for later separation in touristboards
          ids.forEach(id => {
            if (id !== 'N.A.') {
              const obj = {
                taxonomy,
                tix,
                ukey: placeUkey
              }
              console.log('QQQQ', `Institutions/${id}/potentialplaces/${placeUkey}`)
              this.is.setObjNoLog(`Institutions/${id}/potentialplaces/${placeUkey}`, obj)
            }
          })

        }
      });
    }
  }



  async mainImport(data) {

    let cnt = 0;
    let done = 0;
    for (const rec of data) {
      await this.delay(1500);

      if (cnt > 0 && rec[6]) {
        // means that all we have to do is add it to possibles
        console.log('RRR', rec[0], rec[1]);
        //  && (rec[1] === 1 || rec[1] === '-1')
        const hotel = {};
        hotel['name'] = rec[4];
        hotel['datapid'] = rec[6];
        hotel['placeexists'] = true;
        hotel['ukey'] = rec[6];
        hotel['GoogleSearch'] = rec[7];
        if (rec[9] && rec[9].length > 0) {
          hotel['address'] = rec[9];
        }

        let ishotel = true;
        if (rec[20] && rec[20].length) {
          if (rec[20].toLowerCase().includes('.booking.')) {
            hotel['url'] = rec[20];
          }
          if (rec[20].toLowerCase().includes('.hotels.')) {
            hotel['hotels'] = rec[20];
          }
          if (rec[20].toLowerCase().includes('tripadvisor') && rec[20].includes('/Attraction_Review')) {
            ishotel = false;
          }
          if (rec[20].toLowerCase().includes('tripadvisor') && rec[20].includes('/Hotel_Review')) {
            hotel['tripadvisor'] = rec[20];
          }
          if (rec[20].toLowerCase().includes('tripadvisor.') && rec[20].includes('/Restaurant_Review')) {
            ishotel = false;
          }
          if (rec[20].toLowerCase().includes('expedia.') && rec[20].includes('.Hotel-Information')) {
            hotel['expedia'] = rec[20];
          }
          if (rec[20].toLowerCase().includes('hostelworld.')) {
            hotel['hostelworld'] = rec[20];
          }

          this.is.onPlaceMd5(rec[6], rec[20], rec[4], 'Auto');
        }

        if (rec[21]) {
          hotel['expedia'] = rec[21];
          // self.is.onPlaceMd5(rec[6], rec[20], rec[4], 'Auto');
        }

        if (rec[2]) {
          hotel['saveItem'] = {websiteurl: rec[2]};
        }

        if (((rec[20] && rec[20].length) || rec[21]) && (ishotel)) {
          this.is.setObjNoLog(`Topics/TempInfo/hotels/${hotel['ukey']}`, hotel);
        }

        // end of for hotels


        this.is.getObject(`Places/${rec[6]}/location`).take(1).subscribe(loca => {
          if (loca && loca['hash']) {
            const trip = {
              hash: loca['hash'],
              lat: loca['lat'],
              long: loca['long'],
              name: rec[4],
              country: loca['country'],
              objectID: rec[6],
              ukey: rec[6],
              aboutit: false,
              propogate: true,
              showonmap: true,
              type: rec[17]
            };

            if (rec[22] && rec[22].length > 10 && rec[22].startsWith('Topics/Tmp')) {
              console.log('pushing a possible', `${rec[22]}/possibles`);
              this.is.pushObj(`${rec[22]}/possibles`, trip);
            }

            this.is.getObject(`Places/${rec[6]}/moreinfo/website`).take(1).subscribe(website => {
              if (rec[20] && rec[20].length) {
                this.is.onPlaceMd5(rec[6], rec[20], rec[4], 'Auto');
              }

              if (website) {
                if (rec[1] === '1') {
                  this.is.onPlaceMd5(rec[6], rec[0], rec[4], 'Auto');
                }
                if (rec[3] === '1') {
                  this.is.onPlaceMd5(rec[6], rec[2], rec[4], 'Auto');
                }

                if (rec[20] && rec[20].length) {
                  let vendor = '';

                  if (rec[20].toLowerCase().includes('.booking.')) {
                    vendor = 'booking';
                  }
                  if (rec[20].toLowerCase().includes('.hotels.')) {
                    vendor = 'hotels';
                  }
                  if (rec[20].toLowerCase().includes('tripadvisor')) {
                    vendor = 'tripadvisor';
                  }
                  if (rec[20].toLowerCase().includes('expedia.')) {
                    vendor = 'expedia';
                  }
                  if (rec[20].toLowerCase().includes('hostelworld.')) {
                    vendor = 'hostelworld';
                  }

                  if (vendor && vendor.length > 3) {
                    this.is.setObjNoLog(`Places/${rec[6]}/moreinfo/${vendor}`, rec[20]);
                  }
                }

              } else {
                // calculate the md5s using
                if (rec[1] === '1') {
                  this.is.onPlaceMd5(rec[6], rec[0], rec[4], 'Auto');
                }
                if (rec[3] === '1') {
                  this.is.onPlaceMd5(rec[6], rec[2], rec[4], 'Auto');
                }

                const moreinfo = {};
                if (rec[7]) {
                  moreinfo['GoogleSearch'] = rec[7];
                }

                if (rec[3] === '1' && rec[0].length >= rec[2].length) {
                  moreinfo['website'] = rec[2];
                } else if (rec[1] === '1') {
                  moreinfo['website'] = rec[0];
                }

                if (rec[20] && rec[20].length) {
                  if (rec[20].toLowerCase().includes('.booking.')) {
                    moreinfo['booking'] = rec[20];
                  }
                  if (rec[20].toLowerCase().includes('.hotels.')) {
                    moreinfo['hotels'] = rec[20];
                  }
                  if (rec[20].toLowerCase().includes('tripadvisor')) {
                    moreinfo['tripadvisor'] = rec[20];
                  }
                  if (rec[20].toLowerCase().includes('expedia.')) {
                    moreinfo['expedia'] = rec[20];
                  }
                  if (rec[20].toLowerCase().includes('hostelworld.')) {
                    moreinfo['hostelworld'] = rec[20];
                  }
                }

                this.is.setObjNoLog(`Places/${rec[6]}/moreinfo`, moreinfo);

              }
            });


          } else {

            const lat = parseFloat(rec[14]);
            const lng = parseFloat(rec[15]);
            this.http.getGeoApify(lat, lng).take(1).subscribe(geoappify => {

              // Step 2: Create the place
              const place = {};

              // name
              place['name'] = rec[4];

              // ukey
              place['ukey'] = rec[6];

              const location = {};
              location['name'] = place['name'];
              location['bearing'] = 90;
              location['pitch'] = 40;
              location['speed'] = 0.6;
              location['zoom'] = 13;

              const keys = [];
              const key = {id: place['ukey'], name: 'Google'};
              keys.push(key);

              if (geoappify && geoappify.features && geoappify.features[0] && geoappify.features[0].properties) {
                if (geoappify.features[0] && geoappify.features[0].bbox) {
                  location['bounds'] = geoappify.features[0].bbox;
                }

                const openstreetmap = geoappify.features[0].properties;
                place['openstreetmap'] = openstreetmap;


                if (openstreetmap.place_id) {
                  const newkey = new Key();
                  newkey.id = openstreetmap.place_id;
                  newkey.name = 'Open Street Map';
                  keys.push(newkey);
                }

                let country;
                if (openstreetmap.country_code) {
                  location['countrycode'] = openstreetmap.country_code.toUpperCase();
                  country = openstreetmap.country_code.toUpperCase();
                }
                let state;
                if (openstreetmap.state) {
                  state = openstreetmap.state;
                }
                let city;
                if (openstreetmap.city) {
                  city = openstreetmap.city;
                } else if (openstreetmap.county) {
                  city = openstreetmap.county;
                }

                if (country && country === 'US') {
                  location['country'] = openstreetmap.state;
                  let loc;
                  let locate;
                  if (city && city.length) {
                    if (state && state.length) {
                      loc = `${city}, ${state}`;
                      locate = `${city}, ${state}`;
                    } else {
                      loc = city;
                      locate = city;
                    }
                  } else if (location['country'] && location['country'].length) {
                    loc = location['country'];
                    locate = location['country'];
                  } else {
                    loc = 'United States';
                    locate = 'United States';
                  }

                  location['loc'] = loc;
                  location['location'] = locate;

                } else {
                  if (city) {
                    if (openstreetmap.country) {
                      location['country'] = openstreetmap.country;
                      location['loc'] = `${city}, ${openstreetmap.country}`;
                      location['location'] = `${city}, ${openstreetmap.country}`;
                    } else if (openstreetmap.state) {
                      location['country'] = openstreetmap.state;
                      location['loc'] = `${city}, ${openstreetmap.state}`;
                      location['location'] = `${city}, ${openstreetmap.state}`;
                    }
                  } else if (openstreetmap.state) {
                    if (openstreetmap.country) {
                      location['country'] = openstreetmap.country;
                      location['loc'] = `${openstreetmap.state}, ${openstreetmap.country}`;
                      location['location'] = `${openstreetmap.state}, ${openstreetmap.country}`;
                    } else if (openstreetmap.state) {
                      location['country'] = openstreetmap.state;
                      location['loc'] = openstreetmap.state;
                      location['location'] = openstreetmap.state;
                    }
                  } else if (openstreetmap.country) {
                    location['country'] = openstreetmap.country;
                    location['loc'] = openstreetmap.country;
                    location['location'] = openstreetmap.country;
                  }
                }


              }

              place['subtitle'] = rec[5];
              if (place['Subtitle']) {
                place['subtitle'] = new LangStringBlock();
                place['subtitle'].en.block = false;
                place['subtitle'].en.description = rec[2];
              }

              place['taxonomy'] = {};

              if (rec[17] && rec[17].length) {
                place['taxonomy']['kingdom'] = rec[17];
              }
              if (rec[18] && rec[18].length) {
                place['taxonomy']['phylum'] = rec[18];
              }
              if (rec[19] && rec[19].length) {
                place['taxonomy']['class'] = rec[19];
              }

              // more info
              place['moreinfo'] = {};
              if (rec[7]) {
                place['moreinfo']['GoogleSearch'] = rec[7];
              }

              if (rec[3] === '1') {
                place['moreinfo']['website'] = rec[2];
              } else if (rec[1] === '1') {
                place['moreinfo']['website'] = rec[0];
              }
              if (rec[20] && rec[20].length) {
                if (rec[20].toLowerCase().includes('.booking.')) {
                  place['moreinfo']['booking'] = rec[20];
                }
                if (rec[20].toLowerCase().includes('.hotels.')) {
                  place['moreinfo']['hotels'] = rec[20];
                }
                if (rec[20].toLowerCase().includes('tripadvisor')) {
                  place['moreinfo']['tripadvisor'] = rec[20];
                }
                if (rec[20].toLowerCase().includes('expedia.')) {
                  place['moreinfo']['expedia'] = rec[20];
                }
                if (rec[20].toLowerCase().includes('hostelworld.')) {
                  place['moreinfo']['hostelworld'] = rec[20];
                }
              }

              // 9 address
              if (rec[9] && rec[9].length > 0) {
                place['address'] = rec[9];
                location['address'] = rec[9];
              }


              // description: there can be many descriptions from various sources
              const descriptions = [];
              if (rec[10] && rec[10].length) {
                const desc = new LangStringBlock();
                desc.en.description = rec[10];
                desc.en.block = false;
                let src = '';
                if (rec[11] && rec[11].length > 0) {
                  src = rec[11];
                } else {
                  src = 'Google';
                }

                descriptions.push({desc, source: src});
              }
              place['descriptions'] = descriptions;

              if (rec[12] && rec[12].length && rec[12].indexOf('.jpg') === -1) {
                place['moreinfo']['Wikipedia'] = rec[12];
              }

              // photos
              place['photos'] = [];
              let ph = rec[16].replace('&quot;);', '');
              ph = ph.split('=w')[0];
              const photo = {url: ph, who: 'Google', caption: ''};
              place['photos'].push(photo);

              if (rec[14]) {
                location['lat'] = parseFloat(rec[14]);
              }

              if (rec[15]) {
                location['long'] = parseFloat(rec[15]);
              }


              if (location['lat'] && location['long'] && location['country']) {
                console.log('HHHHHHHHHHHHHHHHHHHHH')
                const loc =  {
                  long: location['long'],
                  lat: location['lat'],
                  country: location['country']};

                const tix = {
                  name: place['name'],
                  address: place['address'],
                  placeUkey: place['ukey'],
                  category: '',
                  lat: location['lat'],
                  long: location['long'],
                  order: -1,
                  path: ''
                }

                this.touristBoardWork(loc, place['ukey'], place['taxonomy'], tix)
              }



              // need to calculate the Hash
              const hash = geohash.encode(location['lat'], location['long']);
              location['hash'] = hash;
              place['hash'] = hash;


              location['keys'] = keys;
              place['location'] = location;

              if (place['ukey'] && place['ukey'].length > 1) {
                let code = 'NLG';

                if (place['photos']) {
                  code = code + 'P';
                }


                if (place['ukey'] && place['ukey'].length > 5 && location['loc'] && location['hash']) {
                  this.is.setObjNoLog(`Places/${place['ukey']}`, place);
                  this.is.setObjNoLog(`PlaceExists/${place['ukey']}`, {code, ukey: place['ukey']});


                  const trip = {
                    hash: location['hash'],
                    lat: location['lat'],
                    long: location['long'],
                    name: rec[4],
                    country: location['country'],
                    objectID: rec[6],
                    ukey: rec[6],
                    aboutit: false,
                    propogate: true,
                    showonmap: true,
                    type: rec[17]
                  };

                  if (rec[22] && rec[22].length > 10 && rec[22].startsWith('Topics/Tmp')) {
                    console.log('working it', `${rec[22]}/possibles`);
                    this.is.pushObj(`${rec[22]}/possibles`, trip);
                  }


                  if (rec[1] === '1') {
                    this.is.onPlaceMd5(place['ukey'], rec[0], rec[4], 'Auto');
                  }
                  if (rec[3] === '1') {
                    this.is.onPlaceMd5(place['ukey'], rec[2], rec[4], 'Auto');
                  }

                  if (rec[20] && rec[20].length) {
                    this.is.onPlaceMd5(rec[6], rec[20], rec[4], 'Auto');
                  }

                }
              }
            });
          }
        });
      }
      cnt++;
      console.log('counting', cnt);
    }
  }


  /*
0 Name
1 CountryName
2 Subtitle
3 nytime
4 localtime
5 Data_Pid
6 Data_URL
7 Website URL
8 Located in
9 Address
10 Phone
11 Hours
12 Description
13 Description Source Link
14 Wikipedia
15 Britanica
16 dest_mid
17 place_href
18 images
19 articles
20 imageurl
21 Lat
22 Long
23 ID
24 chain
25 tourist
26 location
27 kingdom
28 phylum
29 class
*/

  fixPhoto(data) {
    let cnt = 0;
    let done = 0;
    data.forEach(rcc => {
      if (cnt > 0 && rcc[5]) {
        let ph = rcc[18].replace('&quot;);', '');
        ph = ph.split('=w')[0];
        console.log('PHOTO', ph);
        console.log('path', `Places/${rcc[5]}/photos/0/url`);
        this.is.setObjNoLog(`Places/${rcc[5]}/photos/0/url`, ph);
      }
      cnt = cnt + 1;
    });

  }

  photoCheck(data) {
    let cnt = 0;
    let done = 0;
    data.forEach(rcc => {
      if (cnt > 0 && rcc[1]) {
        this.is.getObject(`Places/${rcc[1]}/photos/0/url`).take(1).subscribe(nm => {
          if (nm && nm.includes('googleusercontent') && (nm.includes('.jpg') || nm.includes('quot'))) {
            console.log('cucked', `Places/${rcc[1]}`);
          }
        });
      }
      cnt = cnt + 1;
    });
  }

  placeImport(data) {
    let cnt = 0;
    let done = 0;
    data.forEach(rcc => {
      if (cnt > 0 && rcc[5]) {
        this.is.getObject(`Places/${rcc[5]}/name`).take(1).subscribe(nm => {
          if (!nm) {

            const lat = parseFloat(rcc[21]);
            const lng = parseFloat(rcc[22]);

            this.http.getGeoApify(lat, lng).take(1).subscribe(geoappify => {

              console.log(cnt, 'NEW NEW NEW: ', rcc[5]);
              // Step 2: Create the place
              const place = {};

              // name
              place['name'] = rcc[0];

              // ukey
              place['ukey'] = rcc[5];

              const location = {};
              location['name'] = place['name'];
              location['bearing'] = 90;
              location['pitch'] = 40;
              location['speed'] = 0.6;
              location['zoom'] = 13;

              const keys = [];
              const key = {id: place['ukey'], name: 'Google'};
              keys.push(key);

              let good = true;
              if (geoappify && geoappify.features && geoappify.features[0] && geoappify.features[0].properties) {
                if (geoappify.features[0] && geoappify.features[0].bbox) {
                  location['bounds'] = geoappify.features[0].bbox;
                }

                const openstreetmap = geoappify.features[0].properties;
                place['openstreetmap'] = openstreetmap;
                console.log('OPEN', openstreetmap);
                if (!openstreetmap.country || (openstreetmap.country.toLowerCase() !== rcc[1].toLowerCase())) {
                  good = false;
                }

                if (openstreetmap.place_id) {
                  const newkey = new Key();
                  newkey.id = openstreetmap.place_id;
                  newkey.name = 'Open Street Map';
                  keys.push(newkey);
                }

                let country;
                if (openstreetmap.country_code) {
                  location['countrycode'] = openstreetmap.country_code.toUpperCase();
                  country = openstreetmap.country_code.toUpperCase();
                }
                let state;
                if (openstreetmap.state) {
                  state = openstreetmap.state;
                }
                let city;
                if (openstreetmap.city) {
                  city = openstreetmap.city;
                } else if (openstreetmap.county) {
                  city = openstreetmap.county;
                }

                if (country && country === 'US') {
                  location['country'] = openstreetmap.state;
                  let loc;
                  let locate;
                  if (city && city.length) {
                    if (state && state.length) {
                      loc = `${city}, ${state}`;
                      locate = `${city}, ${state}`;
                    } else {
                      loc = city;
                      locate = city;
                    }
                  } else if (location['country'] && location['country'].length) {
                    loc = location['country'];
                    locate = location['country'];
                  } else {
                    loc = 'United States';
                    locate = 'United States';
                  }

                  location['loc'] = loc;
                  location['location'] = locate;

                } else {
                  if (city) {
                    if (openstreetmap.country) {
                      location['country'] = openstreetmap.country;
                      location['loc'] = `${city}, ${openstreetmap.country}`;
                      location['location'] = `${city}, ${openstreetmap.country}`;
                    } else if (openstreetmap.state) {
                      location['country'] = openstreetmap.state;
                      location['loc'] = `${city}, ${openstreetmap.state}`;
                      location['location'] = `${city}, ${openstreetmap.state}`;
                    }
                  } else if (openstreetmap.state) {
                    if (openstreetmap.country) {
                      location['country'] = openstreetmap.country;
                      location['loc'] = `${openstreetmap.state}, ${openstreetmap.country}`;
                      location['location'] = `${openstreetmap.state}, ${openstreetmap.country}`;
                    } else if (openstreetmap.state) {
                      location['country'] = openstreetmap.state;
                      location['loc'] = openstreetmap.state;
                      location['location'] = openstreetmap.state;
                    }
                  } else if (openstreetmap.country) {
                    location['country'] = openstreetmap.country;
                    location['loc'] = openstreetmap.country;
                    location['location'] = openstreetmap.country;
                  }
                }
                console.log('CCCCC', openstreetmap.country);

              }

              place['subtitle'] = rcc[2];
              if (place['Subtitle']) {
                place['subtitle'] = new LangStringBlock();
                place['subtitle'].en.block = false;
                place['subtitle'].en.description = rcc[2];
              }

              place['taxonomy'] = {};

              if (rcc[27] && rcc[27].length) {
                place['taxonomy']['kingdom'] = rcc[27];
              }
              if (rcc[28] && rcc[28].length) {
                place['taxonomy']['phylum'] = rcc[28];
              }
              if (rcc[29] && rcc[29].length) {
                place['taxonomy']['class'] = rcc[29];
              }

              // more info
              place['moreinfo'] = {};
              if (rcc[6]) {
                place['moreinfo']['GoogleSearch'] = rcc[6];
              }

              if (rcc[7]) {
                place['moreinfo']['website'] = rcc[7];
              }


              // 9 address
              if (rcc[9] && rcc[9].length > 0) {
                place['address'] = rcc[9];
                location['address'] = rcc[9];
              }

              // description: there can be many descriptions from various sources
              const descriptions = [];
              if (rcc[12] && rcc[12].length) {
                const desc = new LangStringBlock();
                desc.en.description = rcc[12];
                desc.en.block = false;
                let src = '';
                if (rcc[13] && rcc[13].length > 0) {
                  src = rcc[13];
                } else {
                  src = 'Google';
                }

                descriptions.push({desc, source: src});
              }
              place['descriptions'] = descriptions;

              if (rcc[14] && rcc[14].length && rcc[12].indexOf('.jpg') === -1) {
                place['moreinfo']['Wikipedia'] = rcc[14];
              }

              if (rcc[15] && rcc[15].length) {
                place['moreinfo']['Britanica'] = rcc[15];
              }

              // photos
              place['photos'] = [];


              //https://lh5.googleusercontent.com/p/AF1QipO6gK-HbLOcq6VP-kIoYLi5UWm5_FPklTluj_Xo=w300-h225-p-k-no&quot;);
              let ph = rcc[18].replace('&quot;);', '');
              ph = ph.split('=w')[0];
              const photo = {url: ph, who: 'Google', caption: ''};
              place['photos'].push(photo);

              if (rcc[21]) {
                location['lat'] = parseFloat(rcc[21]);
              }

              if (rcc[22]) {
                location['long'] = parseFloat(rcc[22]);
              }

              // need to calculate the Hash
              const hash = geohash.encode(location['lat'], location['long']);
              location['hash'] = hash;
              place['hash'] = hash;


              location['keys'] = keys;
              place['location'] = location;

              if (place['ukey'] && place['ukey'].length > 10 && good) {
                let code = 'NLG';

                if (place['photos']) {
                  code = code + 'P';
                }

                if (place['ukey'] && place['ukey'].length > 5 && location['loc'] && location['hash']) {

                  console.log(cnt, 'PLACE', place);
                  this.is.setObjNoLog(`Places/${place['ukey']}`, place);
                  this.is.setObjNoLog(`PlaceExists/${place['ukey']}`, {code, ukey: place['ukey']});

                }
              } else {
                console.log('country does not match', rcc);
              }
            });
          } else {
            done = done + 1;
            console.log('Got it', done);
          }
        });
      }
      cnt++;
    });


  }


  changeListener($event: any) {
    this.showart = true;
    this.tmpincident = [];
    this.tmphld = [];
    this.tmpcolleges = [];
    this.coloffices = [];
    const self = this;
    const file: File = $event.target.files[0];
    const filename = file.name;
    const myReader: FileReader = new FileReader();
    myReader.readAsText(file);
    const resultSet = [];


    myReader.onloadend = function(e) {
      // you can perform an action with data read here
      // as an example i am just splitting strings by spaces
      self.papa.parse(myReader.result.toString(), {
        complete: (results, filed) => {

          // This function is called to prepare a AUS.csv file for further processing
          if (self.filter === 'Countries') {

            /* code to import tourist boards - no longer usef
                        let cnt = 0;
                        results.data.forEach(rec => {
                          if (cnt > 0 && rec[0]) {
                            const tb = new TouristBoard();
                            tb.ukey = rec[0];
                            tb.websitename = rec[1];
                            tb.org = {name: rec[2], email: rec[55]};
                            tb.websiteurl = rec[3];
                            tb.logo = {url: rec[4], backgroundcolor: rec[5]};
                            tb.destination = {
                              countrycode: rec[6],
                              name: rec[7],
                              country: rec[8],
                              loc: rec[9],
                              ukey: rec[10]
                            };
                            tb.locations.push(
                              {hash: rec[15],
                                lat: parseFloat(rec[16]),
                                long: parseFloat(rec[17]),
                                loc: '',
                                viewport: {
                                  nelat: parseFloat(rec[11]),
                                  nelng: parseFloat(rec[12]),
                                  swlat: parseFloat(rec[13]),
                                  swlng: parseFloat(rec[14])
                              }});
                            tb.socialmedia = {
                              instagram: rec[19],
                              youtube: rec[21],
                              tiktok: rec[22],
                              facebook: rec[23],
                              pinterest: rec[24],
                              twitter: rec[25],
                              snapchat: rec[26],
                              flickr: rec[27],
                              weibo: rec[28],
                              google: rec[29],
                              whatsapp: rec[30],
                              linkedin: rec[31],
                              issuu: rec[32],
                              reddit: rec[33],
                              tumblr: rec[34],
                              tripadvisor: rec[35]
                            };
                            if (rec[36]) {
                              const dat = new Date(rec[36]).getTime();
                              const statusarr = [{date: dat, status: 'Yes'}];
                              tb.vendors.push({name: 'simpleview', status: statusarr});
                            }
                            if (rec[37]) {
                              const dat = new Date(rec[37]).getTime();
                              const statusarr = [{date: dat, status: 'Yes'}];
                              tb.vendors.push({name: 'wordpress', status: statusarr});
                            }

                            let lc = false;
                            if (rec[61] === 'Yes') {
                              lc = true;
                            }

                            tb.website = {
                              attractionsurl : rec[39],
                              livechat: lc,
                              workInIframe : false,
                              destinationsurl : rec[40],
                              eventurl : rec[41],
                              offersurl : rec[45],
                              somethingspecialurl : rec[46],
                              accessibleurl : rec[48],
                              tourguidesurl : rec[49],
                              UNESCOurl : rec[51],
                              downloadsurl : rec[52],
                              luggageurl : rec[53],
                              publictoiletsurl : rec[54],
                              scenicspots : rec[57],
                              faqurl : rec[58],
                              muslimurl : rec[59],
                              officesurl : rec[60],
                              beachesurl: rec[62],
                              LGBTQurl: rec[63],
                              itinerariesurl: rec[65],
                              mapurl: rec[66]
                            }

                            tb.blog = {url: rec[42], note: rec[43], feed: ''};
                            tb.introvideo = rec[44];
                            tb.appstemp = rec[47];
                            tb.cardtemp = rec[50];
                            if (rec[56]) {
                              tb.localpubs.push({puburl: rec[56], pubname: ''})
                            }
                            if (rec[64]) {
                              tb.localpubs.push({puburl: rec[64], pubname: ''})
                            }

                            tb.socialmediakeys = {
                              instagram: rec[67],
                              youtube: rec[68],
                              tiktok: rec[69],
                              pinterest: rec[70],
                              twitter: rec[71],
                            };

                            if (rec[67]) {
                              self.is.setObjNoLog(`Topics/SocialMediaKeys/${rec[0]}/instagram/`, rec[67]);

                            }
                            if (rec[69]) {
                              // self.is.setObjNoLog(`Topics/SocialMediaKeys/${rec[0]}/tiktok/`, rec[69]);
                            }
                            if (rec[70]) {
                              // self.is.setObjNoLog(`Topics/SocialMediaKeys/${rec[0]}/pinterest/`, rec[70]);
                            }
                            if (rec[71]) {
                              self.is.setObjNoLog(`Topics/SocialMediaKeys/${rec[0]}/twitter/`, rec[71]);
                            }
                            // self.is.setObjNoLog(`Topics/SocialMediaKeys/${rec[0]}/ukey/`, rec[0]);

                            // create Topics/Boardvlogs/ so can scrape board videos from youtube.
                            if (rec[21]) {
                              const yt = {
                                feed: rec[21],
                                inactive: false,
                                name: rec[1],
                                objectID: rec[68],
                                topic: rec[68],
                                ukey: rec[68],
                              }
                              // self.is.setObjNoLog(`Topics/Boardvlogs/${rec[68]}`, yt)
                            }


                            console.log(tb)
                            console.log('path', `Institutions/${rec[0]}`);
                            // self.is.setObjNoLog(`Institutions/${rec[0]}`, tb);
                            console.log('path', `Topics/TempInfo/touristboards/${rec[0]}`)
                            // self.is.setObjNoLog(`Topics/TempInfo/touristboards/${rec[0]}`, rec[0])
                            console.log('viewpord', tb.locations[0].viewport)
                            const obj = tb.locations[0].viewport
                            obj['ukey'] = rec[0]
                            // self.is.setObjNoLog(`Topics/TempInfo/touristboardsviewports/${rec[0]}`, obj);
                          };
                          cnt += 1;
                        });
            */


            /* for importing blogs
            let cnt = 0;
            results.data.forEach(rec => {
              if (cnt > 0 && rec[0]) {
                const obj = {};

                obj['archived'] = false;
                obj['cardimg'] = '';
                if (rec[7] && rec[7].length) {
                  obj['cardimg'] = rec[7];
                }
                obj['date'] = 1631217833636;
                obj['description'] = new LangStringBlock();
                if (rec[6] && rec[6].length) {
                  obj['description']['en']['description'] = rec[6];
                }

                obj['domain'] = rec[2];
                obj['edition'] = 'Adventures';
                obj['feed'] = rec[0];
                if (rec[5] && rec[5].length) {
                  obj['icon'] = rec[5];
                }
                obj['location'] = '';
                obj['name'] = rec[3];
                obj['objectID'] = rec[1];
                obj['org'] = 'Blogger';
                obj['paid"'] = false;
                obj['public'] = true;
                if (rec[4] && rec[4].length) {
                  obj['tags'] = 'Local';
                }
                obj['topic'] = rec[1];
                obj['type'] = 'blog';
                obj['ukey'] = rec[1];

                self.is.setObjNoLog(`Topics/TempInfo/tempblogs/${rec[1]}`, obj)
              }
              cnt += 1;
            });
            */


            // for festivals
            /*
            let cnt = 0;
            let done = 0;
            results.data.forEach(rec => {
              if (cnt > 0) {
                const obj = {}
                obj['moreinfo'] = {}
                obj['moreinfo']['website'] = rec[0]
                obj['ukey'] = rec[1]
                obj['name'] = rec[2]
                obj['taxonomy'] = {}
                obj['taxonomy']['kingdom'] = 'Festival'
                obj['taxonomy']['phylum'] = ''
                obj['taxonomy']['class'] = ''
                if (rec[3]) {
                  obj['taxonomy']['phylum'] = rec[3]
                }
                if (rec[4]) {
                  obj['taxonomy']['class'] = rec[4]
                }
                if (rec[5]) {
                  // photos
                  const photos = [];
                  const photo = {url: rec[5], who: '', caption: ''};
                  photos.push(photo);
                  obj['photos'] = photos
                }
                if (rec[6]) {
                  // description
                  const fullmsg = new LangStringBlock();
                  fullmsg.en.block = false;
                  fullmsg.en.description = rec[6];
                  obj['fullmsg'] = fullmsg;
                }
                self.is.setObjNoLog(`Topics/TempInfo/Festivals/${obj['ukey']}`, obj)
              }
              cnt += 1
            })
            */


// import data from md5


            /* MAIN IMPORT */
            self.mainImport(results.data);

            // self.placeImport(results.data);

            // self.photoCheck(results.data);


            // for importing {ctry} - refs
            /*
            1 Name
            2 Data_Pid
            3 Lat
            4 Long
            5 Image
            6 Category
             */
            /*
            let cnt = 0;
            results.data.forEach(rec => {
              if (cnt > 0 && rec[2]) {
                const obj = {};
                obj['Name'] = rec[1]
                obj['ukey'] = rec[2]

                obj['Lat'] = parseFloat(rec[3]);
                if (obj['Lat']) {
                  location['lat'] = obj['Lat'];
                }

                obj['Long'] = parseFloat(rec[4]);
                if (obj['Long']) {
                  location['long'] = obj['Long'];
                }

                if (rec[5]) {
                   obj['photos'] = []
                   const photo = {url: rec[5], who: 'Google', caption: ''};
                   obj['photos'].push(photo);
                }

                obj['Category'] = rec[6]

                obj['Subtitle'] = rec[7]

                const keys = [];
                const key = {id: obj['ukey'], name: 'Google'};
                keys.push(key);
                obj['keys'] = keys;

                console.log('objx', obj)

                if (rec[8]) {
                  const arr = rec[8].split(',')
                  if (arr && arr.length) {
                    arr.forEach(a => {
                      console.log('pathx', `Places/${a}/POIS/${obj['ukey']}`)
                      // self.is.setObjNoLog(`Places/${a}/POIS/${obj['ukey']}`, obj)

                      // INPOIS means that the listed ukeys have this mention in their POIS array
                      console.log('path', `Places/${obj['ukey']}/INPOIS/${a}`)
                      self.is.setObjNoLog(`Places/${obj['ukey']}/INPOIS/${a}`, a)
                    })
                  }
                }
              }
              cnt = cnt + 1;
            })
*/


            // for importing PlaceNames
            /*
            Continent
            Country
            CountryRequired
            Demonyms
            FullName
            Name
            Notes
            OtherNames
            hash
            lat
            long
            ukey
            Inactive
            SecondaryNames
            */
            /*
            let cnt = 0;
            results.data.forEach(rec => {
              if (cnt > 0 && rec[11]) {
                const obj = {};


                obj['Continent'] = rec[0];
                obj['Country'] = rec[1];
                if (rec[2] === 'TRUE') {
                  obj['CountryRequired'] = true;
                } else {
                  obj['CountryRequired'] = false;
                }
                obj['Demonyms'] = rec[3];
                obj['FullName'] = rec[4];
                obj['Name'] = rec[5];
                obj['Notes'] = rec[6];
                obj['OtherNames'] = rec[7];
                obj['hash'] = rec[8];
                obj['lat'] = parseFloat(rec[9]);
                obj['long'] = parseFloat(rec[10]);
                obj['ukey'] = rec[11];
                if (rec[12] === 'TRUE') {
                  obj['Inactive'] = true;
                } else {
                  obj['Inactive'] = false;
                }
                obj['SecondaryNames'] = rec[13];

                if (obj['ukey'] && obj['ukey'].length) {
                  self.is.setObjNoLog(`Topics/TempInfo/PlaceNames/${obj['ukey']}`, obj);
                }


              }
              cnt = cnt + 1;
            });
*/


            // for importing to finalcams spreadshet
            /*
              CF	0
              CN
              Category
              City
              Country
              Description
              HostURL 6
              Inactive
              Location
              Processed
              Title	10
              Translate
              ViewOf
              ukey
              Lat
              Lng	15
              Stars
              GoodAtNight
              Sunrise
              Sunset
              Data-Pid
            */
            /*            let cnt = 0;
            results.data.forEach(rec => {
              if (cnt > 0 && rec[13]) {
                const obj = {};
                obj['CF'] = rec[0];
                obj['CN'] = rec[1];
                obj['Category'] = rec[2];
                obj['City'] = rec[3];
                obj['Country'] = rec[4];
                obj['Description'] = rec[5];
                obj['HostURL'] = rec[6];
                if (rec[7] === 'FALSE') {
                  obj['Inactive'] = false;
                } else if (rec[7] === 'TRUE') {
                  obj['Inactive'] = true;
                }
                obj['Location'] = rec[8];
                if (rec[9]) {
                  obj['Processed'] = parseInt(rec[9]);
                }
                obj['Title'] = rec[10];
                obj['Translate'] = rec[11];
                obj['ViewOf'] = rec[12];
                obj['ukey'] = rec[13];
                if (rec[14]) {
                  obj['Lat'] = parseFloat(rec[14]);
                }
                if (rec[15]) {
                  obj['Lng'] = parseFloat(rec[15]);
                }
                if (rec[16]) {
                  obj['Stars'] = parseInt(rec[16]);
                }
                if (rec[17]) {
                  obj['GoodAtNight'] = parseInt(rec[17]);
                }

                if (rec[18] === 'FALSE') {
                  obj['Sunrise'] = false;
                } else if (rec[18] === 'TRUE') {
                  obj['Sunrise'] = true;
                }
                if (rec[19] === 'FALSE') {
                  obj['Sunset'] = false;
                } else if (rec[19] === 'TRUE') {
                  obj['Sunset'] = true;
                }

                if (rec[20] && rec[20].length) {
                  obj['Data-Pid'] = rec[20];
                } else if (rec[19] === 'TRUE') {
                  obj['Data-Pid'] = '';
                }

                console.log('objx', obj);
                console.log('path', `Topics/TempInfo/finalcams/${obj['ukey']}`);
                self.is.setObjNoLog(`Topics/TempInfo/finalcams/${obj['ukey']}`, obj);
              }
              cnt = cnt + 1;
            });
*/


            /*          for importing podcasts
            let cnt = 0;
            results.data.forEach(rec => {
              if (cnt > 0 && rec[0]) {
                const obj = {
                  feed: rec[3],
                  inactive: false,
                  name: rec[0],
                  icon: rec[5],
                  objectID: rec[1],
                  topic: rec[1],
                  ukey: rec[1],
                }

                self.is.setObjNoLog(`Topics/Travelpods/${rec[1]}`, obj)
              }
              cnt = cnt + 1;
            })*/


            /* Snipet to see if a data_pid is already in PlaceExists */
            /*
            let cnt = 0;
            results.data.forEach(rec => {
              if (cnt > 0 && rec[0]) {
                self.is.getObject(`PlaceExists/${rec[0]}`).take(1).subscribe(plce => {
                  if (plce) {
                    console.log(rec[0]);
                  }
                });
              }
              cnt++;
            });
*/


            /*
            0 Data-Pid
            1 Country
            2 Name
            3 Link
            4. desc
             */

            /*
                        let cnt = 0;
                        results.data.forEach(rec => {
                          if (cnt > 0 && rec[0]) {
                            self.is.getObject(`Places/${rec[0]}/location`).take(1).subscribe(plce => {

                              if (plce) {
                                const obj = {};
                                obj['Continent'] = '';
                                obj['Country'] = rec[1];
                                obj['CountryRequired'] = false;
                                obj['Demonyms'] = '';
                                obj['FullName'] = rec[2];
                                obj['Inactive'] = false;
                                obj['Name'] = rec[2];
                                obj['Notes'] = '';
                                obj['OtherNames'] = '';
                                obj['SecondaryNames'] = '';
                                obj['hash'] = plce.hash;
                                obj['lat'] = plce.lat;
                                obj['long'] = plce.long;
                                obj['type'] = 'POI';
                                obj['ukey'] = rec[0];


                                if (rec[3]) {
                                  // GANP = Global Alliance of National Parks
                                  // `Places/${rec[0]}/moreinfo/GANP`,rec[3]
                                  self.is.setObjNoLog(`Places/${rec[0]}/moreinfo/GANP`, rec[3]);
                                }

                                if (rec[4]) {
                                  const desc = new LangStringBlock();
                                  desc.en.block = false;
                                  desc.en.description = rec[4];
                                  self.is.setObjNoLog(`Places/${rec[0]}/descriptions/1`, desc);
                                }
                                console.log('path', `Topics/TempInfo/PlaceNames/${rec[0]}`);
                                console.log('obj', obj);
                                self.is.setObjNoLog(`Topics/TempInfo/PlaceNames/${rec[0]}`, obj);
                                //
                              }

                            });
                          }
                          cnt++;
                        });
            */


            /* This bit of code is used to make a video guide */
            /*
 0	cn
 1	cf
2	location
    3	place
 4	ssoc
    5	Length
 6	link
 7	ukey
 8	Image
 9	Days-ago
10	detail_page_url
 */
            /*
            let cnt = 0;
            results.data.forEach(rec => {
              if (cnt > 0) {
                const curguide = new Wish('', null);
                curguide.app = 'Adventures';
                curguide.as = true;
                curguide.cn = rec[0];
                curguide.cf = rec[1];
                curguide.curlang = 'en';
                const date = new Date().getTime() - rec[9] * 24 * 60 * 60 * 1000 + 5;
                curguide.date = date;
                curguide.ukey = date.toString();
                curguide.flip = false;
                curguide.e = rec[8];
                curguide.l = `https://www.youtube.com/${rec[6]}`;
                curguide.ssoc['en'].description = rec[4];
                curguide.topics = rec[1];
                curguide.trip = [];
                curguide.trip.push({name: rec[3], objectID: rec[2], ukey: rec[2], showmap: true, propogate: true});
                curguide.types = [];
                curguide.types.push('Video');
                curguide.ty = 'feed';
                curguide.v = 'U';
                curguide.vex = -1;

                console.log('curguide', curguide)
                console.log('date', new Date(curguide.date))
                console.log('path to travelnotifications: ', `Topics/TravelNotifications/${curguide.ukey}`);
                self.is.setObjNoLog(`Topics/TravelNotifications/${curguide.ukey}`, curguide);
                console.log('path to save: ', `Topics/Travelvlogs/${rec[1]}/published/${curguide.ukey}`);
                self.is.setObjNoLog(`Topics/Travelvlogs/${rec[1]}/published/${curguide.ukey}`, curguide);
                self.is.setObjNoLog(`Topics/Travelvlogs/${rec[1]}/items/${curguide.ukey}`, curguide.ukey);
                console.log('path to location: ', `Places/${rec[2]}/guides/${curguide.ukey}`);
                self.is.setObjNoLog(`Places/${rec[2]}/guides/${curguide.ukey}`, curguide);
              }
              cnt = cnt + 1;
            });
*/


            /*

            0	name
            1	place
            2	search
            3	CountryName
            4	Data-Pids
            5	desc
            6	image
            7	parent
            8 bestmonths
            9 bestmonthsurl

            */
            // this is used to create a generic Repo (when there are no guides)
            /*
                        let cnt = 0;
                        results.data.forEach(rec => {
                          if (cnt > 0 && rec[0]) {
                            self.is.getObject(`Places/${rec[4]}/location`).take(1).subscribe(locat => {
                              if (locat) {
                                const magname = 'U.S. News & World Report';
                                const magukey = 'usnews';
                                const magicon = 'https://travel.usnews.com/static-atlas/assets/img/social-logo-world-report.png';

                                const curguide = new Wish('', null);
                                curguide.app = 'Adventures';
                                curguide.as = true;
                                curguide.cf = magukey;
                                curguide.cn = magname;
                                curguide.curlang = 'en';
                                const date = new Date().getTime();
                                curguide.ukey = date.toString();

                                curguide.dt = '';
                                curguide.flip = false;
                                curguide.g = '';
                                curguide.i = magicon;
                                curguide.icon = magicon;

                                curguide.m = {};
                                curguide.m.catonly = false;
                                curguide.m.droptitle = false;
                                curguide.m.name = magname;
                                curguide.m.sponsored = false;
                                curguide.topics = magukey;
                                curguide.ty = 'feed';
                                curguide.v = 'U';
                                curguide.vex = -1;

                                curguide.types = [];
                                curguide.types.push('Guide');

                                curguide.s = rec[0];
                                curguide.ssoc = new LangStringBlock();
                                curguide.ssoc.en.block = false;
                                curguide.ssoc.en.description = rec[0];

                                // description
                                curguide.fullmsg = new LangStringBlock();
                                curguide.fullmsg.en.block = false;
                                curguide.fullmsg.en.description = rec[5];
                                curguide.msg = rec[5];

                                // image
                                if (rec[6] && rec[6].length > 0) {
                                  curguide.e = rec[6];
                                }

                                // parent
                                curguide.l = rec[7];

                                curguide.trip = [];
                                const tp = {};

                                tp['hash'] = locat.hash;
                                tp['lat'] = parseFloat(locat.lat);
                                tp['long'] = parseFloat(locat.long);
                                if (locat.name) {
                                  tp['name'] = locat.name;
                                } else if (locat.loc) {
                                  tp['name'] = locat.loc;
                                }


                                tp['objectID'] = rec[4];
                                tp['propogate'] = true;
                                tp['showonmap'] = false;
                                tp['ukey'] = tp['objectID'];
                                curguide.trip.push(tp);

                                if (rec[8]) {
                                  const obj = {
                                    source: magname,
                                    link: rec[9],
                                    desc: rec[8]
                                  };
                                  console.log(rec[4], 'obj', obj);
                                  self.is.pushObj(`Places/${rec[4]}/besttime`, obj);
                                }

                                console.log('curguidex', curguide);

                                // this bit of code if for repos
                                self.is.setObjNoLog(`Places/${rec[4]}/repos/${curguide.ukey}`, curguide);
                                self.is.setObjNoLog(`Topics/Travelmags/${magukey}/repos/${curguide.ukey}`, curguide);


                              } else {
                                console.log('Deoest exist:', `Places/${rec[4]}/location`);
                              }
                            });


                          }
                          cnt++;
                        });
            */


            /*
            // this is used to create a conde traveler Repo (there are no guides)
            let cnt = 0;
            results.data.forEach(rec => {
              if (cnt > 0 && rec[0]) {
                self.is.getObject(`Places/${rec[4]}/location`).take(1).subscribe(locat => {
                  if (locat) {

                    const curguide = new Wish('', null);
                    curguide.app = 'Adventures';
                    curguide.as = true;
                    curguide.cf = 'condetr';
                    curguide.cn = 'Condé Nast Traveler';
                    curguide.curlang = 'en';
                    const date = new Date().getTime();
                    curguide.ukey = date.toString();

                    curguide.dt = '';
                    curguide.flip = false;
                    curguide.g = '';
                    curguide.i = 'https://www.cntraveler.com/verso/static/conde-nast-traveler/assets/favicon.ico';
                    curguide.icon = 'https://www.cntraveler.com/verso/static/conde-nast-traveler/assets/favicon.ico';

                    curguide.m = {};
                    curguide.m.catonly = false;
                    curguide.m.droptitle = false;
                    curguide.m.name = 'Condé Nast Traveler';
                    curguide.m.sponsored = false;
                    curguide.topics = 'condetr';
                    curguide.ty = 'feed';
                    curguide.v = 'U';
                    curguide.vex = -1;

                    curguide.types = [];
                    curguide.types.push('Guide');

                    curguide.s = rec[0];
                    curguide.ssoc = new LangStringBlock();
                    curguide.ssoc.en = rec[0];

                    // description
                    curguide.fullmsg = new LangStringBlock();
                    curguide.fullmsg.en = rec[5];
                    curguide.msg = rec[5];

                    // image
                    if (rec[6] && rec[6].length > 0) {
                      curguide.e = rec[6];
                    }

                    // parent
                    curguide.l = rec[7];

                    curguide.trip = [];
                    const tp = {};
                    tp['hash'] = locat.hash;
                    tp['lat'] = parseFloat(locat.lat);
                    tp['long'] = parseFloat(locat.long);
                    if (locat.name) {
                      tp['name'] = locat.name;
                    } else if (locat.loc) {
                      tp['name'] = locat.loc;
                    }
                    tp['objectID'] = rec[4];
                    tp['propogate'] = true;
                    tp['showonmap'] = false;
                    tp['ukey'] = tp['objectID'];
                    curguide.trip.push(tp);

                    console.log('curguide', curguide);

                    // this bit of code if for repos
                    self.is.setObjNoLog(`Places/${rec[4]}/repos/${curguide.ukey}`, curguide);
                    self.is.setObjNoLog(`Topics/Travelmags/condetr/repos/${curguide.ukey}`, curguide);

                  } else {
                    console.log('Deoest exitst', rec[4]);
                  }
                });
              }
              cnt++;
            });
*/

            /*
                        // this is used to create a matador Repo (there are no guides)
                        let cnt = 0;
                        results.data.forEach(rec => {
                          if (cnt > 0 && rec[0]) {
                            self.is.getObject(`Places/${rec[4]}/location`).take(1).subscribe(locat => {
                              if (locat) {

                                const curguide = new Wish('', null);
                                curguide.app = 'Adventures';
                                curguide.as = true;
                                curguide.cf = 'matadornetwork';
                                curguide.cn = 'Matador Network';
                                curguide.curlang = 'en';
                                const date = new Date().getTime();
                                curguide.ukey = date.toString();

                                curguide.dt = '';
                                curguide.flip = false;
                                curguide.g = '';
                                curguide.i = 'https://matadornetwork.com/favicon.ico';
                                curguide.icon = 'https://matadornetwork.com/favicon.ico';

                                curguide.m = {};
                                curguide.m.catonly = false;
                                curguide.m.droptitle = false;
                                curguide.m.name = 'Matador Network';
                                curguide.m.sponsored = false;
                                curguide.topics = 'matadornetwork';
                                curguide.ty = 'feed';
                                curguide.v = 'U';
                                curguide.vex = -1;

                                curguide.types = [];
                                curguide.types.push('Guide');

                                curguide.s = rec[0];
                                curguide.ssoc = new LangStringBlock();
                                curguide.ssoc.en = rec[0];

                                // description
                                curguide.fullmsg = new LangStringBlock();
                                curguide.fullmsg.en = rec[5];
                                curguide.msg = rec[5];

                                // image
                                if (rec[6] && rec[6].length > 0) {
                                  // 'https://www.fodors.com/' +
                                  curguide.e = rec[6];
                                }

                                // parent
                                curguide.l = rec[7];

                                curguide.trip = [];
                                const tp = {};
                                tp['hash'] = locat.hash;
                                tp['lat'] = parseFloat(locat.lat);
                                tp['long'] = parseFloat(locat.long);
                                if (locat.name) {
                                  tp['name'] = locat.name;
                                } else if (locat.loc) {
                                  tp['name'] = locat.loc;
                                }
                                tp['objectID'] = rec[4];
                                tp['propogate'] = true;
                                tp['showonmap'] = false;
                                tp['ukey'] = tp['objectID'];
                                curguide.trip.push(tp);

                                console.log('curguide', curguide);

                                // This bit of code is for guides
                                // self.is.setObjNoLog(`Places/${rec[4]}/guides/${date}`, curguide);
                                // self.is.setObjNoLog(`Topics/Travelmags/fodors/items/${date}`, date);
                                // self.is.setObjNoLog(`Topics/Travelmags/fodors/published/${date}`, curguide);

                                // this bit of code if for repos
                                self.is.setObjNoLog(`Places/${rec[4]}/repos/${curguide.ukey}`, curguide);
                                self.is.setObjNoLog(`Topics/Travelmags/matadornetwork/repos/${curguide.ukey}`, curguide);

                                if (rec[8]) {
                                  const obj = {
                                    source: 'Matador Network',
                                    link: rec[7],
                                    desc: rec[8]
                                  }
                                  self.is.pushObj(`Places/${rec[4]}/besttime`, obj)
                                }

                              } else {
                                console.log('Deoest exitst', rec[4]);
                              }
                            });
                          }
                          cnt++;
                        });
            */

            /*          this is used to create a us news Guide or Repo
            let cnt = 0;
            results.data.forEach(rec => {
              if (cnt > 0 && rec[0]) {
                self.is.getObject(`Places/${rec[4]}/location`).take(1).subscribe(locat => {
                  if (locat) {

                    const curguide = new Wish('', null);
                    curguide.app = 'Adventures';
                    curguide.as = true;
                    curguide.cf = 'usnews';
                    curguide.cn = 'US News Travel';
                    curguide.curlang = 'en';
                    const date = new Date().getTime();
                    curguide.ukey = date.toString();

                    curguide.dt = '';
                    curguide.flip = false;
                    curguide.g = '';
                    curguide.i = 'https://travel.usnews.com/static-atlas/assets/img/social-logo-world-report.png';
                    curguide.icon = 'https://travel.usnews.com/static-atlas/assets/img/social-logo-world-report.png';

                    curguide.m = {};
                    curguide.m.catonly = false;
                    curguide.m.droptitle = false;
                    curguide.m.name = 'US News Travel';
                    curguide.m.sponsored = false;
                    curguide.topics = 'usnews';
                    curguide.ty = 'feed';
                    curguide.v = 'U';
                    curguide.vex = -1;

                    curguide.types = [];
                    curguide.types.push('Guide');

                    curguide.s = rec[0];
                    curguide.ssoc = new LangStringBlock();
                    curguide.ssoc.en = rec[0];

                    // description
                    curguide.fullmsg = new LangStringBlock();
                    curguide.fullmsg.en = rec[5];
                    curguide.msg = rec[5];

                    // image
                    if (rec[6] && rec[6].length > 0) {
                      // 'https://www.fodors.com/' +
                      curguide.e = rec[6];
                    }

                    // parent
                    curguide.l = rec[7];

                    curguide.trip = [];
                    const tp = {};
                    tp['hash'] = locat.hash;
                    tp['lat'] = parseFloat(locat.lat);
                    tp['long'] = parseFloat(locat.long);
                    if (locat.name) {
                      tp['name'] = locat.name;
                    } else if (locat.loc) {
                      tp['name'] = locat.loc;
                    }
                    tp['objectID'] = rec[4];
                    tp['propogate'] = true;
                    tp['showonmap'] = false;
                    tp['ukey'] = tp['objectID'];
                    curguide.trip.push(tp);

                    console.log('curguide', curguide);

                    // This bit of code is for guides
                    // self.is.setObjNoLog(`Places/${rec[4]}/guides/${date}`, curguide);
                    // self.is.setObjNoLog(`Topics/Travelmags/fodors/items/${date}`, date);
                    // self.is.setObjNoLog(`Topics/Travelmags/fodors/published/${date}`, curguide);

                    // this bit of code if for repos
                    self.is.setObjNoLog(`Places/${rec[4]}/repos/${curguide.ukey}`, curguide);
                    self.is.setObjNoLog(`Topics/Travelmags/usnews/repos/${curguide.ukey}`, curguide);

                    if (rec[8]) {
                      const obj = {
                        source: 'US News Travel',
                        link: rec[7],
                        desc: rec[8]
                      }
                      self.is.pushObj(`Places/${rec[4]}/besttime`, obj)
                    }

                  } else {
                    console.log('Deoest exitst', rec[4]);
                  }
                });
              }
              cnt++;
            });
*/

            // this is used to create a fodors Guide or Repo
            /*
                        0	name
                        1	place
                        2	search
                        3	CountryName
                        4	Data-Pids
                        5	desc
                        6	image
                        7	parent
            */
            /*
            // this is used to create a fodors guide or repo
            let cnt = 0;
            results.data.forEach(rec => {
              if (cnt > 0 && rec[0]) {
                self.is.getObject(`Places/${rec[4]}/location`).take(1).subscribe(locat => {
                  if (locat) {

                    const curguide = new Wish('', null);
                    curguide.app = 'Adventures';
                    curguide.as = true;
                    curguide.cf = 'fodors';
                    curguide.cn = 'Fodor\'s';
                    curguide.curlang = 'en';
                    const date = new Date().getTime();
                    curguide.ukey = date.toString();

                    curguide.dt = '';
                    curguide.flip = false;
                    curguide.g = '';
                    curguide.i = 'https://www.fodors.com/favicon.ico';
                    curguide.icon = 'https://www.fodors.com/favicon.ico';

                    curguide.m = {};
                    curguide.m.catonly = false;
                    curguide.m.droptitle = false;
                    curguide.m.name = 'Fodor\'s';
                    curguide.m.sponsored = false;
                    curguide.topics = 'fodors';
                    curguide.ty = '';
                    curguide.v = 'U';
                    curguide.vex = -1;

                    curguide.types = [];
                    curguide.types.push('Guide');

                    curguide.s = rec[0];
                    curguide.ssoc = new LangStringBlock();
                    curguide.ssoc.en = rec[0];

                    // description
                    curguide.fullmsg = new LangStringBlock();
                    curguide.fullmsg.en = rec[5];
                    curguide.msg = rec[5];

                    // image
                    if (rec[6] && rec[6].length > 0) {
                      // 'https://www.fodors.com/' +
                      curguide.e = rec[6];
                    }

                    // parent
                    curguide.l = rec[7];

                    curguide.trip = [];
                    const tp = {}
                    tp['hash'] = locat.hash;
                    tp['lat'] = locat.lat;
                    tp['long'] = locat.long;
                    if (locat.name) {
                      tp['name'] = locat.name;
                    } else if (locat.loc) {
                      tp['name'] = locat.loc;
                    }
                    tp['objectID'] = rec[4];
                    tp['propogate'] = true;
                    tp['showonmap'] = false;
                    tp['ukey'] = tp['objectID'];
                    curguide.trip.push(tp);

                    console.log('curguide', curguide);

                    // This bit of code is for guides
                    // self.is.setObjNoLog(`Places/${rec[4]}/guides/${date}`, curguide);
                    // self.is.setObjNoLog(`Topics/Travelmags/fodors/items/${date}`, date);
                    // self.is.setObjNoLog(`Topics/Travelmags/fodors/published/${date}`, curguide);

                    // this bit of code if for repos
                    // self.is.setObjNoLog(`Places/${rec[4]}/repos/${curguide.ukey}`, curguide);
                    // self.is.setObjNoLog(`Topics/Travelmags/fodors/repos/${curguide.ukey}`, curguide);


                  } else {
                    console.log('Deoest exitst', rec[4]);
                  }
                });
              }
              cnt++;
            });
*/


            /*            // this is used to create a lonely planet Guide or Repo
            let cnt = 0;
            results.data.forEach(rec => {
              if (cnt > 0 && rec[0]) {
                self.is.getObject(`Places/${rec[4]}/location`).take(1).subscribe(locat => {
                  if (locat) {

                    const curguide = new Wish('', null);
                    curguide.app = 'Adventures';
                    curguide.as = true;
                    curguide.cf = 'lonelyplanet';
                    curguide.cn = 'Lonely Planet';
                    curguide.curlang = 'en';
                    const date = new Date().getTime();
                    curguide.ukey = date.toString();

                    curguide.dt = '';
                    curguide.flip = false;
                    curguide.g = '';
                    curguide.i = 'https://www.lonelyplanet.com/favicon.ico';
                    curguide.icon = 'https://www.lonelyplanet.com/favicon.ico';

                    curguide.m = {};
                    curguide.m.catonly = false;
                    curguide.m.droptitle = false;
                    curguide.m.name = 'Lonely Planet';
                    curguide.m.sponsored = false;
                    curguide.topics = 'lonelyplanet';
                    curguide.ty = '';
                    curguide.v = 'U';
                    curguide.vex = -1;

                    curguide.types = [];
                    curguide.types.push('Guide');


                    // 0	name
                    // 1	place
                    // 2	search
                    // 3	CountryName
                    // 4	Data-Pids
                    // 5	desc
                    // 6	image
                    // 7	parent

                    curguide.s = rec[0];
                    curguide.ssoc = new LangStringBlock();
                    curguide.ssoc.en = rec[0];

                    // description
                    curguide.fullmsg = new LangStringBlock();
                    curguide.fullmsg.en = rec[5];
                    curguide.msg = rec[5];

                    // image
                    if (rec[6] && rec[6].length > 0) {
                      curguide.e = rec[6];
                    }

                    // parent
                    curguide.l = rec[7];

                    curguide.trip = [];
                    const tp = {}
                    tp['hash'] = locat.hash;
                    tp['lat'] = locat.lat;
                    tp['long'] = locat.long;
                    if (locat.name) {
                      tp['name'] = locat.name;
                    } else if (locat.loc) {
                      tp['name'] = locat.loc;
                    }
                    tp['objectID'] = rec[4];
                    tp['propogate'] = true;
                    tp['showonmap'] = false;
                    tp['ukey'] = tp['objectID'];
                    curguide.trip.push(tp);

                    console.log('curguide', curguide);


                    console.log('curguide', curguide);

                    // This bit of code is for guides
                    self.is.setObjNoLog(`Places/${rec[4]}/guides/${date}`, curguide);
                    self.is.setObjNoLog(`Topics/Travelmags/lonelyplanet/items/${date}`, date);
                    self.is.setObjNoLog(`Topics/Travelmags/lonelyplanet/published/${date}`, curguide);

                    // this bit of code if for repos
                    // self.is.setObjNoLog(`Places/${rec[4]}/repos/${curguide.ukey}`, curguide);
                    // self.is.setObjNoLog(`Topics/Travelmags/lonelyplanet/repos/${curguide.ukey}`, curguide);


                  } else {
                    console.log('Deoest exitst', rec[4]);
                  }
                });
              }
              cnt++;
            });*/

            /* try this first - used it for lonely planet
             let cnt = 0;
             results.data.forEach(rec => {
               if (cnt > 0 && rec[0]) {
                 self.is.getObject(`PlaceExists/${rec[2]}`).take(1).subscribe(plce => {

                   const place = {};
                   // 0	Name
                   place['name'] = rec[0];

                   // 1	Subtitle
                   // type
                   let ty = '';
                   let loc = '';
                   if (rec[1]) {
                     const br = rec[1].split(' in ');
                     if (br && br[0]) {
                       ty = br[0];
                       loc = br[1]
                     }
                   }
                   place['type'] = ty;

                   if (rec[1]) {
                     const subtitle = new LangStringBlock();
                     subtitle.en.description = rec[1];
                     subtitle.en.block = false;
                     place['subtitle'] = subtitle;
                   }

                   // 2	Data_Pid
                   place['ukey'] = rec[2];

                   // 3	Data_URL
                   // more info
                   place['moreinfo'] = {};
                   if (rec[3]) {
                     place['moreinfo']['GoogleSearch'] = rec[3];
                   }

                   // 4	Website URL
                   if ( rec[4]) {
                     place['moreinfo']['Website'] =  rec[4];
                   }

                   // 5	Located in

                   // 6	Address
                   const location = {};
                   location['name'] = place['name'];
                   location['bearing'] = 90;
                   location['pitch'] = 40;
                   location['speed'] = 0.6;
                   location['zoom'] = 13;
                   if (rec[6] && rec[6].length > 0) {
                     place['address'] = rec[6];
                     location['address'] = rec[6];
                   }

                   // 7	Phone
                   if (rec[7] && rec[7].length > 0) {
                     location['phone'] = rec[7];
                   }

                   // 8	Description
                   // 9	Description Source Link
                   if (rec[8]) {
                     const descriptions = [];
                     const desc = new LangStringBlock();
                     desc.en.description = rec[8];
                     desc.en.block = false;

                     let source = 'Google';
                     if (rec[9] && rec[9].length > 0) {
                       source = rec[9];
                     }

                     descriptions.push({desc, source});
                     place['descriptions'] = descriptions;
                   }


                   // 10	Wikipedia
                   if (rec[10]) {
                     place['moreinfo']['Wikipedia'] = rec[10];
                   }

                   // 11	Britanica
                   if (rec[11]) {
                     place['moreinfo']['Britanica'] = rec[11];
                   }

                   // 12	images
                   place['photos'] = [];
                   if (rec[12]) {
                     let simg = rec[12];
                     if (rec[12][0] === '|') {
                       simg = rec[12].substring(1);
                     }

                     const photo = {url: simg, who: 'Google', caption: ''};
                     place['photos'].push(photo);
                   }

                   // 13	articles

                   // 14	Lat
                   if (rec[14]) {
                     location['lat'] = rec[14];
                   }

                   // 15	Long
                   if (rec[15]) {
                     location['long'] = rec[15];
                   }

                   // need to calculate the Hash
                   const hash = geohash.encode(location['lat'], location['long']);
                   location['hash'] = hash;
                   place['hash'] = hash;

                   // 16	map
                   if (rec[16]) {
                     place['moreinfo']['GoogleMaps'] = rec[16];
                   }

                   // 17	Name
                   // 18	City

                   // 19	CountryName
                   if (rec[19] && rec[19].length > 0) {
                     location['CountryName'] = rec[19];
                   }

                   const keys = [];
                   const key = {id: rec[2], name: 'Google'};
                   keys.push(key);
                   location['keys'] = keys;


                   // 20	image URL
                   if (rec[20]) {
                     const photo = {url: rec[20], who: 'Google', caption: ''};
                     place['photos'].push(photo);
                   }

                   // 21	logo URL
                   if (rec[21]) {
                     place['logo'] = rec[21];
                   }

                   // 22	detail_page_url
                   if (rec[22]) {
                     place['moreinfo']['GoogleCulture'] = rec[22];
                   }

                   // 23	Pids
                   const parr = rec[23].split(',');
                   place['parents'] = parr;

                   // 24 Code
                   if (loc && loc.length > 0) {
                     location['loc'] = loc;
                     location['location'] = loc;
                   } else {
                     if (rec[24] && rec[24].length > 0) {
                       location['loc'] = rec[24];
                       location['location'] = rec[24];
                     }
                   }

                   place['location'] = location;

                   if (plce) {
                     console.log('exists', place)
                     if (place['ukey']) {

                       if (plce['wikiurl']) {
                         if (place['moreinfo']) {
                           if (!place['moreinfo']['Wikipedia']) {
                             place['moreinfo']['Wikipedia'] = plce['wikiurl'];
                           }
                         }
                       }

                       // desigantions
                       const designations = [];
                       if (plce && plce['unesco']) {
                         const desig = {
                           what: 'World Heritage Site',
                           who: 'UNESCO',
                           who_ukey: plce['unesco'],
                           when: '',
                           linktodesignation: `https://whc.unesco.org/en/list/${plce['unesco']}/`,
                           note: ''
                         };
                         designations.push(desig);
                       }
                       place['designations'] = designations;

                       let code = '';
                       if (plce['code'].includes['N']) {
                         code = 'N';
                       } else if (place['name']) {
                         self.is.setObjNoLog(`Places/${place['ukey']}/name`, place['name']);
                         code = 'N';
                       }

                       if (plce['code'].includes['L']) {
                         code = code + 'L';
                         if (plce['code'].includes['G']) {
                           // if there is a lat ('G') then i assume location is already fully populated
                           code = code + 'G';
                           if (place['location'] && place['location']['phone']) {
                             self.is.setObjNoLog(`Places/${place['ukey']}/location/phone`, place['location']['phone'])
                           }
                           if (place['location'] && place['location']['address']) {
                             self.is.setObjNoLog(`Places/${place['ukey']}/location/address`, place['location']['address'])
                           }

                         } else if (place['location'] && place['location']['lat']) {
                           // if the existing place doesnt have a long / lat i assume they have none of the other attiburetes
                           self.is.setObjNoLog(`Places/${place['ukey']}/location`, place['location']);
                           code = code + 'G';
                         }
                       } else {
                         // if existing place doesnt have a location then i save the whole location
                         self.is.setObjNoLog(`Places/${place['ukey']}/location`, place['location']);
                         code = code + 'LG';
                       }

                       if (plce['code'].includes['B']) {
                         code = code + 'B';
                       }

                       if (plce['code'].includes['P']) {
                         code = code + 'P';
                       } else if (place['photos']) {
                         // save photos to places
                         self.is.setObjNoLog(`Places/${place['ukey']}/photos`, place['photos']);

                         // add p to code
                         code = code + 'P';
                       }

                       if (place['chain']) {
                         code = code + 'C';
                         self.is.setObjNoLog(`Places/${place['ukey']}/chain`, place['chain']);
                       }
                       if (place['tourist_pids']) {
                         self.is.setObjNoLog(`Places/${place['ukey']}/tourist_pids`, place['tourist_pids']);
                       }

                       if (place['subtitle']) {
                         self.is.setObjNoLog(`Places/${place['ukey']}/subtitle`, place['subtitle']);
                       }
                       if (place['logo']) {
                         self.is.setObjNoLog(`Places/${place['ukey']}/logo`, place['logo']);
                       }
                       if (place['parents']) {
                         self.is.setObjNoLog(`Places/${place['ukey']}/parents`, place['parents']);
                       }
                       if (place['descriptions']) {
                         self.is.setObjNoLog(`Places/${place['ukey']}/descriptions`, place['descriptions']);
                       }
                       self.is.setObjNoLog(`Places/${place['ukey']}/designations`, place['designations']);
                       self.is.setObjNoLog(`Places/${place['ukey']}/moreinfo`, place['moreinfo']);
                       self.is.setObjNoLog(`Places/${place['ukey']}/type`, place['type']);

                       self.is.setObjNoLog(`PlaceExists/${place['ukey']}/code`, code);
                     }

                   } else {
                     console.log('doesnt exists', place)

                     if (place['ukey']) {
                       let code = 'NLG';

                       if (place['photos']) {
                         code = code + 'P';
                       }

                       if (place['chain']) {
                         code = code + 'C';
                       }

                       self.is.setObjNoLog(`PlaceExists/${place['ukey']}`, {code, ukey: place['ukey']});
                       self.is.setObjNoLog(`Places/${place['ukey']}`, place);
                     }
                   }


                 });
               }
               cnt = cnt + 1;
             });
 */

            // ******************//
            // This is code for creating a new place from a country code.csv

            /*
    place['name']
    place['chain']
    place['tourist_pids']
    place['descriptions']
    place['designations']
    place['moreinfo']
    place['type']
    place['photos']
    place['ukey']
    place['location'] = location; // name,loc,location,hash,lat,long,country,keys
*/

            /*
             if name: ‘N’
             if location: ‘L’
             if location.lat ‘G’
             if location.bounds: ‘B’
             if photos: ‘P’
             if chain: ‘C’
           */


            /*
0	Name
1	CountryName
2	Subtitle
3	nytime
4	localtime
5	Data_Pid
6	Data_URL
7	Website URL
8	Located in
9	Address
10	Phone
11	Hours
12	Description
13	Description Source Link
14	Wikipedia
15	Britanica
16	dest_mid
17	place_href
18	images
19	articles
20	imageurl
21	Lat
22	Long
23	xlink
24	xdesc
*/

            /*self.is.getObject(`PlaceExists/${rec[5]}`).take(1).subscribe(plce => {
  if (plce) {
    console.log('Place EXISTS', `Places/${rec[5]}/Category`, rec[23]);
    self.is.setObjNoLog(`Places/${rec[5]}/Category`, rec[23]);
  } else {
  }
});*/

            // use this code when importing {ctry} imgpois
            /*
                        let cnt = 0;
                        results.data.forEach(rec => {
                          if (cnt > 0 && rec[0]) {
                            const obj = {};
                            const place = {};

                            // name
                            obj['Name'] = rec[0];
                            place['name'] = obj['Name'];

                            const location = {};
                            location['country'] = rec[1];
                            location['name'] = place['name'];
                            location['bearing'] = 90;
                            location['pitch'] = 40;
                            location['speed'] = 0.6;
                            location['zoom'] = 13;

                            // type
                            place['subtitle'] = '';
                            obj['Subtitle'] = rec[2];
                            let ty = '';
                            if (obj['Subtitle']) {
                              const br = obj['Subtitle'].split(' in ');
                              if (br && br[0]) {
                                ty = br[0];
                              }
                              place['subtitle'] = new LangStringBlock();
                              place['subtitle'].en.block = false;
                              place['subtitle'].en.description = rec[2];
                            }
                            place['type'] = ty;

                            place['Category'] = 'POI'

                            // rec[3] not used
                            // rec[4] not used

                            // ukey
                            obj['Data_Pid'] = rec[5];
                            place['ukey'] = obj['Data_Pid'];

                            // more info
                            place['moreinfo'] = {};
                            obj['Data_URL'] = rec[6];
                            if (obj['Data_URL']) {
                              place['moreinfo']['GoogleSearch'] = obj['Data_URL'];
                            }

                            if (rec[7] && rec[7].length) {
                              obj['Website URL'] = rec[7];
                              if (obj['Website URL']) {
                                place['moreinfo']['Website'] = obj['Website URL'];
                              }
                            }

                            if (rec[23] && rec[23].length) {
                              obj['GANP'] = rec[23];
                              if (obj['GANP']) {
                                place['moreinfo']['GANP'] = obj['GANP'];
                              }
                            }


                            // rec[8] not used

                            // 9 address
                            if (rec[9] && rec[9].length > 0) {
                              place['address'] = rec[9];
                              location['address'] = rec[9];
                            }

                            // 10	Phone
                            if (rec[10] && rec[10].length > 0) {
                              location['phone'] = rec[10];
                            }

                            // 11 hours not used

                            // description: there can be many descriptions from various sources
                            const descriptions = [];
                            if (rec[12] && rec[12].length) {
                              obj['Description'] = rec[12];
                              if (rec[13] && rec[13].length > 0) {
                                obj['Description Source Link'] = rec[13];
                              } else {
                                obj['Description Source Link'] = 'Google';
                              }
                              const desc = new LangStringBlock();
                              desc.en.description = obj['Description'];
                              desc.en.block = false;
                              descriptions.push({desc, source: obj['Description Source Link']});
                            }

                            if (rec[24] && rec[23] && rec[24].length) {
                              const desc = new LangStringBlock();
                              desc.en.description = rec[24];
                              desc.en.block = false;
                              descriptions.push({desc, source: rec[23]});
                            }

                            place['descriptions'] = descriptions;


                            obj['Wikipedia'] = rec[14];
                            if (obj['Wikipedia']) {
                              place['moreinfo']['Wikipedia'] = obj['Wikipedia'];
                            }

                            obj['Britanica'] = rec[15];
                            if (obj['Britanica']) {
                              place['moreinfo']['Britanica'] = obj['Britanica'];
                            }

                            // 16 dest_mid not used

                            // 17	place_href not used


                            // photos
                            place['photos'] = [];
                            obj['images'] = rec[18];

                            if (obj['images'] && obj['images'][0] === '|') {
                              const iarr = obj['images'].substring(1).split('|');
                              if (iarr) {
                                iarr.forEach(i => {
                                  const photo = {url: i, who: 'Google', caption: ''};
                                  place['photos'].push(photo);
                                });
                              }
                            }

                            if (obj['images'] && obj['images'][0] === 'h') {
                              const iarr = obj['images'].split('&s');
                              if (iarr) {
                                iarr.forEach(i => {
                                  const photo = {url: i, who: 'Google', caption: ''};
                                  place['photos'].push(photo);
                                });
                              }
                            }


                            // 19 articles not used

                            // 20 imageurl not used

                            obj['Lat'] = parseFloat(rec[21]);
                            if (obj['Lat']) {
                              location['lat'] = obj['Lat'];
                            }

                            obj['Long'] = parseFloat(rec[22]);
                            if (obj['Long']) {
                              location['long'] = obj['Long'];
                            }

                            obj['Category'] = 'POI';
                            place['Category'] = 'POI';

                            // 24	map_url not used

                            // need to calculate the Hash
                            const hash = geohash.encode(obj['Lat'], obj['Long']);
                            location['hash'] = hash;
                            obj['hash'] = hash;
                            place['hash'] = hash;


                            const pobj = {};
                            pobj['Continent'] = '';
                            pobj['Country'] = rec[1];
                            pobj['CountryRequired'] = false;
                            pobj['Demonyms'] = '';
                            pobj['FullName'] = rec[0];
                            pobj['Inactive'] = false;
                            pobj['Name'] = rec[0];
                            pobj['Notes'] = '';
                            pobj['OtherNames'] = '';
                            pobj['SecondaryNames'] = '';
                            pobj['hash'] = location['hash'];
                            pobj['lat'] = location['lat'];
                            pobj['long'] = location['long'];
                            pobj['type'] = 'POI';
                            pobj['ukey'] = rec[5];


                            const keys = [];
                            const key = {id: place['ukey'], name: 'Google'};
                            keys.push(key);
                            location['keys'] = keys;

                            // obj['CountryName']  Aguadulce District,Panama

                            place['location'] = location;


                            console.log('placename', pobj);
                            console.log('place', place);


                            if (place['ukey'] && place['ukey'].length > 1) {
                              let code = 'NLG';

                              if (place['photos']) {
                                code = code + 'P';
                              }

                              if (place['chain']) {
                                code = code + 'C';
                              }

                              if (place['ukey'] && place['ukey'].length > 5) {
                                console.log('path', `Places/${place['ukey']}`);
                                self.is.setObjNoLog(`PlaceExists/${place['ukey']}`, {code, ukey: place['ukey']});
                                self.is.setObjNoLog(`Places/${place['ukey']}`, place);
                                self.is.setObjNoLog(`Topics/TempInfo/PlaceNames/${place['ukey']}`, pobj);
                              }
                            }
                          }

                          cnt++;
                        });
            */


            /*
            let cnt = 0;
            results.data.forEach(rec => {
              if (cnt > 0 && rec[0]) {
                self.is.getObject(`PlaceExists/${rec[8]}`).take(1).subscribe(plce => {

                  const obj = {};
                  const place = {};

                  // name
                  obj['Name'] = rec[0];

                  place['name'] = obj['Name'];

                  obj['chain'] = rec[2];
                  // [{"Name": "Aguadulce District (Aguadulce)", "Data_Pid": "ChIJ15uVidXIrY8RemOYheuoFlo"}, {"Name": "Cocl\u00e9 Province (Provincia de Cocl\u00e9)", "Data_Pid": "ChIJ1xvNywpIrI8RLaz-6Fd6V0k"}, {"Name": "Panama", "Data_Pid": "ChIJ4yu-yIMVpo8Rz5ulH03g7nk"}]
                  try {
                    place['chain'] = JSON.parse(rec[2]);
                  } catch (err) {
                    console.log('error', rec[2]);
                  }

                  obj['Tourist_Pid'] = rec[3];
                  try {
                    place['tourist_pids'] = JSON.parse(rec[3]);
                  } catch (err) {
                    console.log('error', rec[3]);
                  }

                  // photos
                  place['photos'] = [];
                  obj['images'] = rec[21];

                  // https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTy1K3uWWgru0l4V5dRb6WCT-hVfyd2fKwUnkF5n3be7A&s|https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbR_qKrygouBq4QchtBhM0SJHt3BsZq-EtBUX22KbeXA&s|https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSlLqYZEelXbH5ZMYae6ZlYIu1m6YzcHMNXHkJR3HXzw&s|https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMUH2okBsrxkLSFaN8ECzkMQ7n6Gf5pBZN5yApdYjFlw&s|https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQY72GuryxEp6GFRqXunDJVUAE8bf9u0gPFAU3vQrQMDg&s
                  if (obj['images'] && obj['images'][0] === '|') {
                    const iarr = obj['images'].substring(1).split('&s|');
                    if (iarr) {
                      iarr.forEach(i => {
                        const photo = {url: i, who: 'Google', caption: ''};
                        place['photos'].push(photo);
                      });
                    }
                  }

                  // !https://upload.wikimedia.org/wikipedia/en/2/2c/Armuelles07.gif
                  if (place['photos'].length === 0 && obj['images'][0] === '!') {
                    const photo = {url: obj['images'].substring(1), who: 'Wikipedia', caption: ''};
                    place['photos'].push(photo);
                  }

                  // https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVU840h1gFnOudfp0sNbDqU7jiKUf74TyVCo7fKHqS8w&shttps://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPTwKGsqX2i2dLnL77PkcJecxSxl7s12iP-asHWv2-lQ&shttps://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnyaNtFDoF-qd9iXm4Gmd6aizau7_KTwI27514wcqX2Q&shttps://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2zAUX_vZdy5jVBUX9ybu52u9XMWYUl1uGLlawFWpxow&shttps://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQN-5DkgworP3RadKzCKmfhg3dkX9fqzZlAmyZDzw_uUA&shttps://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQANAYsod9eWYReUu_mi-xqDcrcN8ZFvYTTVtUGid_rMQ&s
                  if (obj['images'] && obj['images'][0] === 'h') {
                    const iarr = obj['images'].split('&s');
                    if (iarr) {
                      iarr.forEach(i => {
                        const photo = {url: i, who: 'Google', caption: ''};
                        place['photos'].push(photo);
                      });
                    }
                  }

                  // description: there can be many descriptions from various sources
                  obj['Description'] = rec[15];
                  if (rec[16] && rec[16].length > 0) {
                    obj['Description Source Link'] = rec[16];
                  } else {
                    obj['Description Source Link'] = 'Google';
                  }
                  const descriptions = [];
                  const desc = new LangStringBlock();
                  desc.en.description = obj['Description'];
                  desc.en.block = false;
                  descriptions.push({desc, source: obj['Description Source Link']});
                  place['descriptions'] = descriptions;

                  // location
                  const location = {};
                  location['name'] = obj['Name'];
                  location['loc'] = obj['Name'];
                  location['location'] = obj['Name'];
                  location['address'] = obj['Name'];
                  location['bearing'] = 90;
                  location['pitch'] = 40;
                  location['speed'] = 0.6;
                  location['zoom'] = 13;

                  obj['Lat'] = rec[23];
                  if (obj['Lat']) {
                    location['lat'] = obj['Lat'];
                  }
                  obj['Long'] = rec[24];
                  if (obj['Long']) {
                    location['long'] = obj['Long'];
                  }

                  // need to calculate the Hash
                  const hash = geohash.encode(obj['Lat'], obj['Long']);
                  location['hash'] = hash;
                  obj['hash'] = hash;
                  place['hash'] = hash;

                  const keys = [];
                  const key = {id: rec[8], name: 'Google'};
                  keys.push(key);
                  location['keys'] = keys;


                  // obj['CountryName']  Aguadulce District,Panama
                  if (rec[4]) {
                    const carr = rec[4].split(',');
                    if (carr.length === 1) {
                      location['country'] = carr[0];
                    } else if (carr.length === 2) {
                      location['country'] = carr[1];
                    }
                  }
                  place['location'] = location;

                  // more info
                  place['moreinfo'] = {};
                  obj['Website URL'] = rec[10];
                  if (obj['Website URL']) {
                    place['moreinfo']['Website'] = obj['Website URL'];
                  }
                  obj['Wikipedia'] = rec[17];
                  if (obj['Wikipedia']) {
                    place['moreinfo']['Wikipedia'] = obj['Wikipedia'];
                  }
                  obj['Britanica'] = rec[18];
                  if (obj['Britanica']) {
                    place['moreinfo']['Britanica'] = obj['Britanica'];
                  }
                  obj['map_url'] = rec[26];
                  if (obj['map_url']) {
                    place['moreinfo']['GoogleMaps'] = obj['map_url'];
                  }

  xxx
                  obj['place_href'] = rec[20];
                  if (obj['place_href']) {
                    place['moreinfo']['GooglePOIs'] = obj['place_href'];
                  }
                  obj['Data_URL'] = rec[9];
                  if (obj['Data_URL']) {
                    place['moreinfo']['GoogleSearch'] = obj['Data_URL'];
                  }


                  // type
                  obj['Subtitle'] = rec[5];
                  let ty = '';
                  if (obj['Subtitle']) {
                    const br = obj['Subtitle'].split(' in');
                    if (br && br[0]) {
                      ty = br[0];
                    }
                  }
                  place['type'] = ty;

                  // ukey
                  obj['Data_Pid'] = rec[8];
                  place['ukey'] = obj['Data_Pid'];

                  // not used
                  obj['Chain'] = rec[1];
                  obj['imageurl'] = rec[22];
                  obj['dest_mid'] = rec[19];
                  obj['nytime'] = rec[6];
                  obj['localtime'] = rec[7];
                  obj['Located in'] = rec[11];
                  obj['Address'] = rec[12];
                  obj['Phone'] = rec[13];
                  obj['Hours'] = rec[14];
                  obj['ID'] = rec[25];

                  if (plce) {

                    if (place['ukey']) {

                      if (plce['wikiurl']) {
                        if (place['moreinfo']) {
                          if (!place['moreinfo']['Wikipedia']) {
                            place['moreinfo']['Wikipedia'] = plce['wikiurl'];
                          }
                        }
                      }

                      // desigantions
                      const designations = [];
                      if (plce && plce['unesco']) {
                        const desig = {
                          what: 'World Heritage Site',
                          who: 'UNESCO',
                          who_ukey: plce['unesco'],
                          when: '',
                          linktodesignation: `https://whc.unesco.org/en/list/${plce['unesco']}/`,
                          note: ''
                        };
                        designations.push(desig);
                      }
                      place['designations'] = designations;
                      let code = '';

                      if (plce['code'].includes['N']) {
                        code = 'N';
                      } else if (place['name']) {
                        self.is.setObjNoLog(`Places/${place['ukey']}/name`, place['name']);
                        code = 'N';
                      }

                      if (plce['code'].includes['L']) {
                        code = code + 'L';
                        if (plce['code'].includes['G']) {
                          // if there is a lat ('G') then i assume location is already fully populated
                          code = code + 'G';
                        } else if (place['location'] && place['location']['lat']) {
                          // if the existing place doesnt have a long / lat i assume they have none of the other attiburetes
                          self.is.setObjNoLog(`Places/${place['ukey']}/location`, place['location']);
                          code = code + 'G';
                        }
                      } else {
                        // if existing place doesnt have a location then i save the whole location
                        self.is.setObjNoLog(`Places/${place['ukey']}/location`, place['location']);
                        code = code + 'LG';
                      }

                      if (plce['code'].includes['B']) {
                        code = code + 'B';
                      }

                      if (plce['code'].includes['P']) {
                        code = code + 'P';
                      } else if (place['photos']) {
                        // save photos to places
                        self.is.setObjNoLog(`Places/${place['ukey']}/photos`, place['photos']);

                        // add p to code
                        code = code + 'P';
                      }

                      if (place['chain']) {
                        code = code + 'C';
                        self.is.setObjNoLog(`Places/${place['ukey']}/chain`, place['chain']);
                      }
                      if (place['tourist_pids']) {
                        self.is.setObjNoLog(`Places/${place['ukey']}/tourist_pids`, place['tourist_pids']);
                      }
                      self.is.setObjNoLog(`Places/${place['ukey']}/descriptions`, place['descriptions']);
                      self.is.setObjNoLog(`Places/${place['ukey']}/designations`, place['designations']);
                      self.is.setObjNoLog(`Places/${place['ukey']}/moreinfo`, place['moreinfo']);
                      self.is.setObjNoLog(`Places/${place['ukey']}/type`, place['type']);

                      self.is.setObjNoLog(`PlaceExists/${place['ukey']}/code`, code);
                    }


                  } else {
                    console.log('Place No', place)
                    // ???? what about PlacesHash
                    // place doesn't exist so have to create from scratch

                    if (place['ukey']) {
                      let code = 'NLG';

                      if (place['photos']) {
                        code = code + 'P';
                      }

                      if (place['chain']) {
                        code = code + 'C';
                      }

                      self.is.setObjNoLog(`PlaceExists/${place['ukey']}`, {code, ukey: place['ukey']});
                      self.is.setObjNoLog(`Places/${place['ukey']}`, place);
                    }

                  }
                });
              }
              cnt++;

            });


            //  This bit of code is to trapse through a country to see if all the admin areas line up
            /*
                        results.data.forEach(rec => {
                          if (rec[1] !== 'Chain') {
                            const st = ', BD';
                            // calculate the time offset - may not be possible if location straddles more than one timezone.
                            if (rec[5]) {
                              const tarr = rec[4].split(' ');
                              const tstr = tarr[0] + 'T' + tarr[1].replace(/-/g, ':');
                              const dt0 = new Date(tstr);
                              const year = dt0.getFullYear();
                              const month = dt0.getMonth() + 1;
                              const day = dt0.getDate();
                              const dt0dow = dt0.getDay();
                              const timearr = rec[5].split(' ');
                              const dt1dow = self.dys.findIndex(d => d === timearr[0]);
                              const dt1 = new Date(month + ' ' + (day + dt1dow - dt0dow) + ' ' + year + ' ' + timearr[1] + ' ' + timearr[2]);
                              const dt0get = dt0.getTime();
                              const dt1get = dt1.getTime();
                              const offset = Math.ceil(Math.round((dt1get - dt0get) / (60000)) / 60) * 60;
                              const testdate = new Date(dt0get + offset * 60000);
                            }

                            // finds the parent administrative areas
                            if (rec && rec[1]) {
                              let temparr = rec[1].split('+');
                              const ids = [];

                              const temparrlen = temparr.length;
                              if (temparr.length > 2) {
                                temparr.shift();
                                const str = temparr.join('+');
                                const x = results.data.findIndex(r => r[1] === str);
                                if (x > -1) {
                                  const id1 = {
                                    name: results.data[x][1],
                                    id: results.data[x][6]
                                  };
                                  ids.push(id1);
                                }  else {
                                  // if here, means didn't find the parent in r[1] so need to look in r[0]
                                  const lookstr = temparr[0] + st;
                                  const y = results.data.findIndex(r => r[0] === lookstr);
                                  if (y > -1) {
                                    const id1 = {
                                      name: results.data[y][1],
                                      id: results.data[y][6]
                                    };
                                    ids.push(id1);
                                  }
                                }
                              }

                              if (temparr.length > 2) {
                                temparr.shift();
                                const str = temparr.join('+');
                                const x = results.data.findIndex(r => r[1] === str);
                                if (x > -1) {
                                  const id1 = {
                                    name: results.data[x][1],
                                    id: results.data[x][6]
                                  };
                                  ids.push(id1);
                                } else {
                                  // if here, means didn't find the parent in r[1] so need to look in r[0]
                                  const lookstr = temparr[0] + st;
                                  const y = results.data.findIndex(r => r[0] === lookstr);
                                  if (y > -1) {
                                    const id1 = {
                                      name: results.data[y][1],
                                      id: results.data[y][6]
                                    };
                                    ids.push(id1);
                                  }
                                }
                              }

                              if (temparr.length > 2) {
                                temparr.shift();
                                const str = temparr.join('+');
                                const x = results.data.findIndex(r => r[1] === str);
                                if (x > -1) {
                                  const id1 = {
                                    name: results.data[x][1],
                                    id: results.data[x][6]
                                  };
                                  ids.push(id1);
                                } else {
                                  // if here, means didn't find the parent in r[1] so need to look in r[0]
                                  const lookstr = temparr[0] + st;
                                  const y = results.data.findIndex(r => r[0] === lookstr);
                                  if (y > -1) {
                                    const id1 = {
                                      name: results.data[y][1],
                                      id: results.data[y][6]
                                    };
                                    ids.push(id1);
                                  }
                                }
                              }

                              if (temparr.length > 2) {
                                temparr.shift();
                                const str = temparr.join('+');
                                const x = results.data.findIndex(r => r[1] === str);
                                if (x > -1) {
                                  const id1 = {
                                    name: results.data[x][1],
                                    id: results.data[x][6]
                                  };
                                  ids.push(id1);
                                }   else {
                                  // if here, means didn't find the parent in r[1] so need to look in r[0]
                                  const lookstr = temparr[0] + st;
                                  const y = results.data.findIndex(r => r[0] === lookstr);
                                  if (y > -1) {
                                    const id1 = {
                                      name: results.data[y][1],
                                      id: results.data[y][6]
                                    };
                                    ids.push(id1);
                                  }
                                }
                              }

                              if (temparr.length > 2) {
                                temparr.shift();
                                const str = temparr.join('+');
                                const x = results.data.findIndex(r => r[1] === str);
                                if (x > -1) {
                                  const id1 = {
                                    name: results.data[x][1],
                                    id: results.data[x][6]
                                  };
                                  ids.push(id1);
                                }   else {
                                  // if here, means didn't find the parent in r[1] so need to look in r[0]
                                  const lookstr = temparr[0] + st;
                                  const y = results.data.findIndex(r => r[0] === lookstr);
                                  if (y > -1) {
                                    const id1 = {
                                      name: results.data[y][1],
                                      id: results.data[y][6]
                                    };
                                    ids.push(id1);
                                  }
                                }
                              }

                              if (temparr.length === 2) {
                                const x = results.data.findIndex(r => r[1] === temparr[temparr.length - 1]);
                                if (x > -1) {
                                  const id1 = {
                                    name: results.data[x][1],
                                    id: results.data[x][6]
                                  };
                                  ids.push(id1);
                                }   else {
                                  // if here, means didn't find the parent in r[1] so need to look in r[0]
                                  const lookstr = temparr[0] + st;
                                  const y = results.data.findIndex(r => r[0] === lookstr);
                                  if (y > -1) {
                                    const id1 = {
                                      name: results.data[y][1],
                                      id: results.data[y][6]
                                    };
                                    ids.push(id1);
                                  }
                                }
                              }

                              // console.log(rec[1], 'ids', ids)
                              if (temparrlen !== (ids.length + 1)) {
                                console.log(rec[1], 'ids', ids)
                              }
                            }


                            // gets the ping images if there are any - unfortunately these are only tumbnails
                            if (rec && rec[19]) {
                              // "pimg_1":"https://encrypted-tbn0.gstatic.com/images?q\u003dtbn:ANd9GcREJg_OYYg-MCMa-e4QTgTGdUAfjbsWMvyV-J70BUsQ\u0026s","pimg_3":"https://encrypted-tbn0.gstatic.com/images?q\u003dtbn:ANd9GcShgI1fSPifePjMGckgvURGbsdgiHNOBnECKtWzv1prvg\u0026s","pimg_5":"https://encrypted-tbn0.gstatic.com/images?q\u003dtbn:ANd9GcTeRWDllntzdf_Vc7obkJVTdwCADKkU4TT7Ey5GTUjBhg\u0026s","pimg_9":"https://encrypted-tbn0.gstatic.com/images?q\u003dtbn:ANd9GcRawrLGz73ThO5xFPTwj-1b6jGUnfEEhXHGfR_hyYfA\u0026s","pimg_7":"https://encrypted-tbn0.gstatic.com/images?q\u003dtbn:ANd9GcQ_IEDVU1MOW0-yd_cymFeBDw4-kdV8m0PxeVn2klcr\u0026s"
                              let parr = [];
                              if (rec[19].includes('pimg')) {
                                const pingarr = rec[19].split(',');
                                if (pingarr) {
                                  pingarr.forEach(p => {
                                    const arr = p.split('":');
                                    if (arr.length === 2) {
                                      parr.push(arr[1].replace(/"/g, '').replace(/\\u0026s/, '').replace(/\\u003d/, '='));
                                    }
                                  });
                                }
                              } else if (rec[19].startsWith('https')) {
                                const tparr = rec[19].split('&s');
                                tparr.forEach(t => {
                                  if (t && t.length > 0) {
                                    parr.push(t);
                                  }
                                });
                              } else {
                                const garr = rec[19].split('&s=0');
                                if (garr) {
                                  garr.forEach(g => {
                                    parr.push('https:' + g);
                                  });
                                }
                              }
                            }

                            // todo: need to save the calculations to a new csv file
                          }
                        });
            */
            /*
                        console.log('placearray', placearray);
                        const blob = new Blob(placearray, {type: 'text/plain'});
                        const fileName = filename.split('.')[0].split('_')[0] +
                          '_' +
                          filename.split('.')[0].split('_')[1] +
                          `.csv`;
                        self.saveAs(blob, fileName);
            */

          } else {

            results.data.forEach(rec => {

                const country = new CountryObj();
                // todo: every resource should have a jsonld
                // see: https://coryrylan.com/blog/angular-seo-with-schema-and-json-ld
                // and: https://ngxlite.com/docs/json-ld


                if (self.filter === 'Blog') {
                  const t = new Topix();
                  // date: number;
                  // archived: boolean;
                  // public: boolean;
                  // paid: boolean;
                  // servicearea: boolean; // true if must check for service area if can subscribe

                  // rec[1] description
                  t.description['en'].description = rec[1];

                  //  rec[6] edition
                  t.edition = 'Adventures';

                  // policyurl: string;
                  // confirmemployee: boolean;
                  // confirmpath: string;
                  // groupadmins: string[];

                  //  rec[12] Icon
                  t.icon = rec[12];

                  // rec[7] name
                  t.name = rec[7];

                  // rec[2] domain
                  t.domain = rec[2];

                  // rec[8] org/name of bloggers
                  t.org = rec[8];
                  t.adminname = rec[8];

                  // rec[11] Emails
                  t.adminemail = rec[11];

                  // rec[10] objectID
                  t.topic = rec[10];
                  t.ukey = rec[10];
                  t.objectID = rec[10];

                  //  rec[3] category
                  t.tags = rec[3]; // comma deliminated string of tags to help with search

                  // rec[5] feed
                  t.feed = rec[5]; // url of the feed if the type === Feed

                  // rec[9] tmpimage
                  t['tmpimage'] = rec[9];

                  // rec[0] grade;
                  if (rec[0]) {
                    t.rating.count = 1;
                    t.rating.stars = rec[0];
                  }

                  // rec[4] geography
                  t.location = rec[4];  // the location of the user

                  t.metadetails = {};

                  // rec[13] Origin
                  if (rec[13]) {
                    t.metadetails['origin'] = rec[13];
                  }

                  // rec[14] Affiliation
                  if (rec[14]) {
                    t.metadetails['affiliation'] = rec[14];
                  }

                  // rec[15] ContentManagement
                  if (rec[15]) {
                    t.metadetails['contentcanagement'] = rec[15];
                  }

                  // rec[16] Analytics
                  if (rec[16]) {
                    t.metadetails['analytics'] = rec[16];
                  }

                  t.type = 'blog';

                  // rec[17] Type
                  t.org = rec[17];

                  self.is.setObjNoLog(`Topics/TmpTravel/${t.ukey}`, t);

                } else if (self.filter === 'Country') {
                  if (rec[0] !== 'Country') {
                    self.is.setObjNoLog(`CA/Countries/${rec[1]}`, {country: rec[0], abbr: rec[1]});
                  }
                } else if (self.filter === 'Campus Resources') {
                  switch (rec[6]) {
                    case 'o' :
                      const coloff = new ColOffice();
                      const tmpo = new Office();

                      tmpo.orglegacyextra = new OrgLegacyExtra();
                      tmpo.orglegacyextra.resource_super_type_slug = rec[7];
                      tmpo.orglegacyextra.short_description = '';
                      tmpo.orglegacyextra.schedule = rec[21];
                      tmpo.orglegacyextra.is24 = self.is24(rec[21]);
                      tmpo.orglegacyextra.kwabid = rec[0];

                      tmpo.class = 'Office';
                      tmpo.ukey = self.titleIXdb.titleIXdb.createPushId();
                      tmpo.parentkey = '';
                      tmpo.nameDesc = new LangStringBlock();
                      tmpo.langDesc = new LangStringBlock();
                      tmpo.abbrDesc = new LangStringBlock();
                      self.languages.forEach(l => {
                        tmpo.nameDesc[l.abbr] = new BlockString();
                        tmpo.nameDesc[l.abbr].description = '';
                        tmpo.nameDesc[l.abbr].block = false;

                        tmpo.langDesc[l.abbr] = new BlockString();
                        tmpo.langDesc[l.abbr].description = '';
                        tmpo.langDesc[l.abbr].block = false;

                        tmpo.abbrDesc[l.abbr] = new BlockString();
                        tmpo.abbrDesc[l.abbr].description = '';
                        tmpo.abbrDesc[l.abbr].block = false;
                      });

                      tmpo.nameDesc['en'].description = rec[1];           // 0: Excel: name
                      tmpo.active = rec[2];        // 1: Excel: active
                      tmpo.roles = rec[8];          // 4: Excel: resource_type_codes - these needs processing
                      tmpo.populationcode = ''; // not ix Excel
                      tmpo.langDesc['en'].description = rec[9];    // 5: Excel: long_description
                      tmpo.tags = rec[10];           // 6: Excel: tags
                      tmpo.icon = rec[11];           // 7: Excel: image_file_name

                      if (rec[12] === 'confidential') {                  // 8: Excel: confidential_text
                        tmpo.confidential = true;
                      } else {
                        tmpo.confidential = false;
                      }
                      tmpo.contact = rec[13];        // 9: Excel: contact_person_name
                      tmpo.email = rec[14];          // 12: Excel: contact.email
                      if (rec[15] === '1') {                  // 13: Excel: cappmail_enabled
                        tmpo.cappmail = true;
                      } else {
                        tmpo.cappmail = false;
                      }

                      tmpo.link = rec[16];           // 14: Excel: info_url

                      tmpo.feedbackurl = '';    // 15: Excel: feedback_url
                      tmpo.emojis = rec[20];         // 16: Excel: attributes
                      coloff.hrs = '';         // 17: Excel: schedule    ** needs A LOT OF FIXING processing
                      if (rec[22]) {
                        tmpo.phones = self.createphones(rec[22]);
                      }

                      // these should start out inheriting from the parent
                      tmpo.street1 = rec[26];        // 22: Excel: address.line1
                      tmpo.street2 = rec[27];        // 23: Excel: address.line2
                      tmpo.location = rec[28];       // 24: Excel: address.city
                      tmpo.city = rec[29];           // 25: Excel: address.city
                      tmpo.state = rec[30];          // 26: Excel: address.state
                      tmpo.zip = rec[31];            // 27: Excel: address.zip

                      if (rec[33] * 1 > 0) {
                        tmpo.lat = rec[33] * 1;
                        tmpo.long = rec[34] * 1;
                      } else {
                        tmpo.lat = 0;
                        tmpo.long = 0;
                      }

                      tmpo.primaryservice = '';

                      coloff.office = tmpo;
                      coloff.collegeukey = rec[35];

                      // todo: verify that this is supposed to be kwab's key
                      self.coloffices.push(coloff);

                      break;
                    case 'x':
                      const colstaff = new ColPublicStaff;
                      const tmpp = new PublicStaff();
                      tmpp.class = 'PublicStaff';
                      tmpp.orglegacyextra = new OrgLegacyExtra();
                      tmpp.orglegacyextra.resource_super_type_slug = rec[7];
                      tmpp.orglegacyextra.short_description = '';
                      tmpp.orglegacyextra.schedule = rec[21];
                      tmpp.orglegacyextra.is24 = self.is24(rec[21]);
                      tmpp.orglegacyextra.kwabid = rec[0];

                      tmpp.ukey = self.titleIXdb.titleIXdb.createPushId();           // this is the resource's unique id
                      tmpp.legacyid = 0;
                      tmpp.schoolgivenid = '';
                      tmpp.title = rec[1];            // 0: Excel: name
                      tmpp.active = rec[2];          // 1: Excel: active
                      tmpp.roles = rec[8];            // 4: Excel: resource_type_codes - commas desparted codes that get busted apart with a parser
                      tmpp.langDesc = new LangStringBlock();
                      tmpp.abbrDesc = new LangStringBlock();
                      self.languages.forEach(l => {
                        tmpp.langDesc[l.abbr] = new BlockString();
                        tmpp.langDesc[l.abbr].description = '';
                        tmpp.langDesc[l.abbr].block = false;

                        tmpp.abbrDesc[l.abbr] = new BlockString();
                        tmpp.abbrDesc[l.abbr].description = '';
                        tmpp.abbrDesc[l.abbr].block = false;
                      });
                      tmpp.langDesc['en'].description = rec[9];      // 5: Excel: description
                      tmpp.tags = rec[10];             // 6: Excel: tags
                      tmpp.icon = rec[11];
                      if (rec[12] === 'confidential') {                  // 8: Excel: confidential_text
                        tmpp.confidential = true;
                      } else {
                        tmpp.confidential = false;
                      }

                      tmpp.fname = '';
                      tmpp.lname = '';
                      tmpp.mname = '';
                      tmpp.zname = '';
                      tmpp.pronouns = '';
                      if (rec[13]) {
                        const n = rec[13].lastIndexOf(' ');
                        tmpp.fname = rec[13].substr(0, n - 1);
                        // todo: need to verify that an extra space in front of last name is gone. before it was n not n + 1
                        tmpp.lname = rec[13].substr(n + 1, rec[13].length);
                        tmpp.fullname = rec[13];
                      }

                      tmpp.email = rec[14];            // 12: Excel: contact.email
                      if (rec[15] === '1') {                  // 13: Excel: cappmail_enabled
                        tmpp.cappmail = true;
                      } else {
                        tmpp.cappmail = false;
                      }

                      tmpp.link = rec[16];             // 14: Excel: cappmail_enabled
                      tmpp.feedbackurl = '';      // 15: Excel: feedback_url
                      tmpp.emojis = rec[20];         // 16: Excel: attributes ** needs processing
                      // tmpp.hours = rec[17];           // 17: Excel: schedule    ** needs A LOT OF FIXING processing
                      if (rec[22]) {
                        tmpp.phones = self.createphones(rec[22]);
                      }

                      tmpp.office = '';             // Not in Eccel: name of the building
                      tmpp.location = rec[26];      // 22: Excel: address.line3

                      if (rec[33] * 1 > 0) {
                        tmpp.lat = rec[33] * 1;
                        tmpp.long = rec[34] * 1;
                      } else {
                        tmpp.lat = 0;
                        tmpp.long = 0;
                      }

                      tmpp.photo = '';              // Not in Excel not in Excel
                      tmpp.populationcode = '';    // Not in Excel for what population eg. undergraduates, all students, faculty, all staff, responsible employees, etc.
                      tmpp.titleixjob = '';
                      tmpp.titleixresume = false;
                      tmpp.primaryservice = '';
                      tmpp.getsreport = false;

                      colstaff.staff = tmpp;
                      colstaff.collegeukey = rec[35];
                      self.colpublicstaff.push(colstaff);
                      break;
                    case 'c':
                      const colcard = new ColCard();
                      const tmpc = new Card();
                      tmpc.class = 'Card';
                      tmpc.orglegacyextra = new OrgLegacyExtra();
                      tmpc.orglegacyextra.resource_super_type_slug = rec[7];
                      tmpc.orglegacyextra.short_description = '';
                      tmpc.orglegacyextra.schedule = rec[21];
                      tmpc.orglegacyextra.is24 = self.is24(rec[21]);
                      tmpc.orglegacyextra.kwabid = rec[0];

                      tmpc.ukey = self.titleIXdb.titleIXdb.createPushId();             // this is the resource's unique id

                      tmpc.nameDesc = new LangStringBlock();
                      tmpc.langDesc = new LangStringBlock();
                      tmpc.abbrDesc = new LangStringBlock();
                      tmpc.linkDesc = new LangString();
                      self.languages.forEach(l => {
                        tmpc.nameDesc[l.abbr] = new BlockString();
                        tmpc.nameDesc[l.abbr].description = '';
                        tmpc.nameDesc[l.abbr].block = false;

                        tmpc.langDesc[l.abbr] = new BlockString();
                        tmpc.langDesc[l.abbr].description = '';
                        tmpc.langDesc[l.abbr].block = false;

                        tmpc.abbrDesc[l.abbr] = new BlockString();
                        tmpc.abbrDesc[l.abbr].description = '';
                        tmpc.abbrDesc[l.abbr].block = false;

                        tmpc.linkDesc[l.abbr] = '';
                      });

                      tmpc.nameDesc['en'].description = rec[1];              // 0: Excel name
                      tmpc.active = rec[2];           // 1: Excel: Active ** needs a bit of processing
                      tmpc.roles = rec[8];             // 4: Excel: resource_type_codes
                      tmpc.langDesc['en'].description = rec[9];       // 5: Excel: description
                      tmpc.tags = rec[10];              // 6: Excel: tags
                      tmpc.icon = rec[11];              // 7: Excel: image_file_name
                      tmpc.linkDesc['en'] = rec[16];              // 14: Exce: info_url
                      tmpc.linkPubDate = '';
                      tmpc.emojis = rec[20];            // 16: Excel: attributes *** needs processing
                      tmpc.population_code = '';   // for what population eg. undergraduates, all students, faculty, all staff, responsible employees, etc.

                      tmpc.primaryservice = '';

                      colcard.card = tmpc;
                      colcard.collegeukey = rec[35];
                      self.colcards.push(colcard);
                      break;
                    case 'p':
                      const colpolicy = new ColPolicy();
                      const tmppol = new Policy();
                      tmppol.class = 'Policy';
                      tmppol.orglegacyextra = new OrgLegacyExtra();
                      tmppol.orglegacyextra.resource_super_type_slug = rec[7];
                      tmppol.orglegacyextra.short_description = '';
                      tmppol.orglegacyextra.schedule = rec[21];
                      tmppol.orglegacyextra.is24 = self.is24(rec[21]);
                      tmppol.orglegacyextra.kwabid = rec[0];

                      tmppol.ukey = self.titleIXdb.titleIXdb.createPushId();             // this is the resource's unique id

                      tmppol.nameDesc = new LangStringBlock();
                      tmppol.langDesc = new LangStringBlock();
                      tmppol.abbrDesc = new LangStringBlock();
                      tmppol.linkDesc = new LangString();
                      self.languages.forEach(l => {
                        tmppol.nameDesc[l.abbr] = new BlockString();
                        tmppol.nameDesc[l.abbr].description = '';
                        tmppol.nameDesc[l.abbr].block = false;

                        tmppol.langDesc[l.abbr] = new BlockString();
                        tmppol.langDesc[l.abbr].description = '';
                        tmppol.langDesc[l.abbr].block = false;

                        tmppol.abbrDesc[l.abbr] = new BlockString();
                        tmppol.abbrDesc[l.abbr].description = '';
                        tmppol.abbrDesc[l.abbr].block = false;

                        tmppol.linkDesc[l.abbr] = '';
                      });

                      tmppol.nameDesc['en'].description = rec[1];              // 0: Excel name
                      tmppol.active = rec[2];           // 1: Excel: Active ** needs a bit of processing
                      tmppol.roles = rec[8];             // 4: Excel: resource_type_codes
                      tmppol.langDesc['en'].description = rec[9];       // 5: Excel: description
                      tmppol.tags = rec[10];              // 6: Excel: tags
                      tmppol.icon = rec[11];              // 7: Excel: image_file_name
                      tmppol.linkDesc['en'] = rec[16];              // 14: Exce: info_url
                      tmppol.linkPubDate = '';
                      tmppol.emojis = rec[20];            // 16: Excel: attributes *** needs processing
                      tmppol.population_code = '';   // for what population eg. undergraduates, all students, faculty, all staff, responsible employees, etc.

                      tmppol.primaryservice = '';

                      colpolicy.policy = tmppol;
                      colpolicy.collegeukey = rec[35];
                      self.colpolicies.push(colpolicy);
                      break;
                    case 'l':
                      const coljumplink = new ColJumpLink();
                      const tmpj = new JumpLink();
                      tmpj.class = 'JumpLink';
                      tmpj.orglegacyextra = new OrgLegacyExtra();
                      tmpj.orglegacyextra.resource_super_type_slug = rec[7];
                      tmpj.orglegacyextra.short_description = '';
                      tmpj.orglegacyextra.schedule = rec[21];
                      tmpj.orglegacyextra.is24 = self.is24(rec[21]);
                      tmpj.orglegacyextra.kwabid = rec[0];

                      tmpj.ukey = self.titleIXdb.titleIXdb.createPushId();             // this is the resource's unique id

                      tmpj.linkDesc = new LangString();
                      tmpj.nameDesc = new LangStringBlock();
                      tmpj.abbrDesc = new LangStringBlock();
                      self.languages.forEach(l => {
                        tmpj.nameDesc[l.abbr] = new BlockString();
                        tmpj.nameDesc[l.abbr].description = '';
                        tmpj.nameDesc[l.abbr].block = false;

                        tmpj.abbrDesc[l.abbr] = new BlockString();
                        tmpj.abbrDesc[l.abbr].description = '';
                        tmpj.abbrDesc[l.abbr].block = false;

                        tmpj.linkDesc[l.abbr] = '';
                      });

                      tmpj.nameDesc['en'].description = rec[1];              // 0: Excel name
                      tmpj.active = rec[2];           // 1: Excel: Active ** needs a bit of processing
                      tmpj.roles = rec[8];            // 4: Excel: resource_type_codes
                      tmpj.tags = rec[10];              // 6: Excel: tags
                      tmpj.icon = rec[11];            // 7: Excel: image_file_name
                      tmpj.linkDesc['en'] = rec[16];          // 14: Exce: info_url
                      tmpj.linkPubDate = '';
                      tmpj.emojis = rec[20];         // 16: Excel: attributes *** needs processing
                      tmpj.population_code = '';   // for what population eg. undergraduates, all students, faculty, all staff, responsible employees, etc.

                      tmpj.format = '';
                      tmpj.embedded = false;
                      tmpj.width = 300;
                      tmpj.height = 150;
                      tmpj.primaryservice = '';

                      coljumplink.jumplink = tmpj;
                      coljumplink.collegeukey = rec[35];
                      self.coljumplinks.push(coljumplink);
                      break;
                  }
                }

                if (self.filter === 'Library') {
                  const tmplib = new ContentLibrary();
                  tmplib.ukey = self.titleIXdb.titleIXdb.createPushId();
                  tmplib.pubdate = rec[0];
                  tmplib.name = rec[1];
                  tmplib.format = rec[3];
                  tmplib.mustread = rec[4];
                  tmplib.behavior = rec[5];
                  tmplib.topic = rec[6];
                  tmplib.population = rec[7];
                  tmplib.costtoread = rec[8];
                  tmplib.publication = rec[9];
                  tmplib.author = rec[10];
                  tmplib.title = rec[11];
                  tmplib.URL = rec[12];
                  tmplib.summary = rec[13];

                  tmplib.CapptivationURL = ''; // this is the location in the Google Bucket of this content
                  tmplib.rating = -1;  // this is the average rarting of users
                  tmplib.ratingdetails = null;
                  tmplib.surveyform = null;

                  self.tmplibrary.push(tmplib);
                }


                if (self.filter === 'Incidents') {
                  const tmp = new Incident();
                  tmp.ukey = rec[0];
                  tmp.date = rec[1];
                  tmp.organization = rec[2];
                  tmp.target = rec[3];
                  tmp.perpetrator = rec[4];
                  tmp.arrest = rec[5];
                  tmp.long = rec[7];
                  tmp.lat = rec[8];
                  tmp.firstsentence = rec[9];
                  tmp.headline = rec[10];
                  tmp.link = rec[11];
                  self.tmpincident.push(tmp);

                }
                // End Incidents
                if (self.filter === 'Laws') {
                  const tmp = new StateLaw();
                  tmp.state = rec[0];
                  tmp.name = rec[2];
                  tmp.status = rec[3];
                  tmp.target = rec[4];
                  tmp.lastupdate = rec[1];
                  tmp.lastchange = rec[6];
                  tmp.description = rec[7];
                  tmp.sponsor = rec[8];
                  tmp.billsURL = rec[9];
                  tmp.articleURL = rec[10];
                  tmp.bipartisan = rec[11];
                  tmp.tags = rec[12];
                  self.tmplaws.push(tmp);
                }
                // End of State laws
                if (self.filter === 'Colleges') {

                  const tmp = new OrgProfile();
                  tmp.orgdata = new OrgData();
                  tmp.orglegacy = new OrgLegacy();
                  tmp.links = new Links();
                  tmp.geo = new Geo();
                  tmp.demo = new Demographics();


                  tmp.abbrDesc = new LangStringBlock();
                  self.languages.forEach(l => {
                    tmp.abbrDesc[l.abbr] = new BlockString();
                    tmp.abbrDesc[l.abbr].description = '';
                    tmp.abbrDesc[l.abbr].block = false;
                  });

                  tmp.active = rec[3];

                  tmp.cappid = rec[10]; // this is the link to spreadsheet data

                  tmp.demo.description = rec[24];
                  tmp.demo.female = rec[26];
                  tmp.demo.male = rec[27];
                  tmp.demo.type = rec[28];
                  tmp.demo.wikilink = rec[25];

                  tmp.doeid = rec[1]; // this is the DOE id
                  tmp.rfctoresourceid = '';
                  tmp.rfctoreporterid = '';
                  tmp.rfcfrom = '';

                  tmp.geo.county = rec[22];
                  tmp.geo.long = rec[5];
                  tmp.geo.lat = rec[6];
                  tmp.geo.rad = 0.4;
                  tmp.geo.timezone = rec[7];
                  // calculates the bounding rectangle
                  const box = self.bnd.boundingCoordinates(tmp.geo.lat, tmp.geo.long, tmp.geo.rad);
                  tmp.geo.west = box[1];
                  tmp.geo.east = box[3];
                  tmp.geo.north = box[2];
                  tmp.geo.south = box[0];
                  // tmp.lastupdated

                  tmp.links.facebook = '';
                  tmp.links.googleplus = '';
                  tmp.links.instagram = '';
                  tmp.links.linkedin = '';
                  tmp.links.pinterest = '';
                  tmp.links.twitter = '';
                  tmp.links.url = rec[29];
                  tmp.links.wikilink = '';
                  tmp.links.youtube = '';
                  tmp.links.tumblr = '';

                  tmp.orgdata.city = rec[15];
                  tmp.orgdata.contactemail = '';
                  tmp.orgdata.contactname = '';
                  tmp.orgdata.country = rec[20];
                  tmp.orgdata.emailextentions = '';
                  tmp.orgdata.orgtype = '';
                  tmp.orgdata.phones = new Array();
                  if (rec[23]) {
                    const tmpphone = new PhoneObj();
                    tmpphone.phdescription = 'Main';
                    tmpphone.digits = rec[23];
                    tmp.orgdata.phones.push(tmpphone);
                  }
                  tmp.orgdata.religiousaffiliation = '';
                  tmp.orgdata.state = rec[17];
                  tmp.orgdata.street1 = rec[13];
                  tmp.orgdata.street2 = rec[14];
                  tmp.orgdata.zip = rec[19];


                  const editions = new Array();
                  const survivor = new OrgEdition();
                  survivor.active = true;
                  survivor.ukey = '-L89javeQsBrdd6ocPpb';
                  survivor.initialviewmode = 'Chicklet';
                  // todo: not sure if i like this name
                  survivor.name = 'Reach Out';
                  survivor.signin = 'nr';
                  editions.push(survivor);

                  tmp.orglegacy.logo = rec[32];
                  tmp.orglegacy.name = rec[4];
                  tmp.orglegacy.nickname = rec[8];
                  tmp.orglegacy.primarycolor = rec[30];
                  tmp.orglegacy.secondarycolor = rec[31];
                  tmp.orglegacy.smalllogo = '';
                  tmp.orglegacy.photo = rec[32];

                  tmp.orglegacyextra = new OrgLegacyExtra;
                  tmp.orglegacyextra.resource_super_type_slug = 'organization'; //
                  tmp.orglegacyextra.short_description = ''; //
                  tmp.orglegacyextra.schedule = ''; //
                  tmp.orglegacyextra.is24 = false; //
                  tmp.orglegacyextra.kwabid = rec[9] * 1; //

                  tmp.parentkey = '';
                  tmp.cappparams = new CappParams();
                  tmp.cappparams.publickey = '';
                  tmp.privatekey = '';
                  tmp.ukey = rec[0];

                  // todo: doesn't create the edition correctly and it shouldn't be called Survivor
                  self.tmpcolleges.push(tmp);
                }


                if (self.filter === 'Legacy Contacts') {
                  const colstaff = new ColPublicStaff();
                  const tmpp = new PublicStaff();
                  tmpp.orglegacyextra = new OrgLegacyExtra();
                  tmpp.orglegacyextra.resource_super_type_slug = 'person';
                  tmpp.orglegacyextra.short_description = '';
                  tmpp.orglegacyextra.schedule = '';
                  tmpp.orglegacyextra.is24 = false;
                  tmpp.orglegacyextra.kwabid = rec[1];

                  tmpp.ukey = self.titleIXdb.titleIXdb.createPushId();           // this is the resource's unique id
                  tmpp.legacyid = rec[0];
                  tmpp.schoolgivenid = '';
                  tmpp.title = rec[8];            // 0: Excel: name
                  tmpp.active = rec[2];          // 1: Excel: active
                  tmpp.roles = '';            // 4: Excel: resource_type_codes - commas desparted codes that get busted apart with a parser
                  tmpp.langDesc = new LangStringBlock();
                  tmpp.abbrDesc = new LangStringBlock();
                  self.languages.forEach(l => {
                    tmpp.langDesc[l.abbr] = new BlockString();
                    tmpp.langDesc[l.abbr].description = '';
                    tmpp.langDesc[l.abbr].block = false;

                    tmpp.abbrDesc[l.abbr] = new BlockString();
                    tmpp.abbrDesc[l.abbr].description = '';
                    tmpp.abbrDesc[l.abbr].block = false;
                  });
                  tmpp.langDesc['en'].description = rec[9];      // 5: Excel: description
                  tmpp.tags = '';             // 6: Excel: tags
                  tmpp.icon = '';
                  tmpp.confidential = false;

                  tmpp.fname = rec[4];
                  tmpp.lname = rec[5];
                  tmpp.mname = rec[6];
                  tmpp.zname = '';
                  tmpp.pronouns = '';
                  tmpp.fullname = self.admin.makenPersonName(tmpp.fname, tmpp.mname, tmpp.lname, tmpp.zname);
                  tmpp.email = rec[7];
                  tmpp.cappmail = false;

                  tmpp.link = '';             // 14: Excel: cappmail_enabled
                  tmpp.feedbackurl = '';      // 15: Excel: feedback_url
                  tmpp.emojis = '';         // 16: Excel: attributes ** needs processing
                  // tmpp.hours = rec[17];           // 17: Excel: schedule    ** needs A LOT OF FIXING processing
                  if (rec[10]) {
                    tmpp.phones = self.createphones(rec[10]);
                  }

                  tmpp.office = '';             // Not in Eccel: name of the building
                  tmpp.location = '';      // 22: Excel: address.line3

                  tmpp.photo = '';              // Not in Excel not in Excel
                  tmpp.populationcode = '';    // Not in Excel for what population eg. undergraduates, all students, faculty, all staff, responsible employees, etc.
                  tmpp.titleixjob = '';
                  tmpp.primaryservice = '';
                  tmpp.getsreport = false;

                  colstaff.staff = tmpp;
                  colstaff.officeid = rec[3];
                  colstaff.collegeukey = rec[12];
                  self.colpublicstaff.push(colstaff);
                }


                if (self.filter === 'roTemp') {
                  const tmp = new TmpColleges();
                  tmp.cappCollegeId = rec[10];
                  tmp.cityName = rec[15];
                  tmp.collegeID = rec[9] * 1;
                  tmp.collegeName = rec[4];
                  tmp.collegeNameLowerCase = rec[4].toLowerCase();
                  tmp.collegeNickname = rec[8];
                  tmp.consoleUkey = rec[0];
                  tmp.homepageType = 'catalog';
                  tmp.logofile = rec[32];
                  tmp.primaryColor = rec[30];
                  const tmpc = new TmpC;
                  tmpc.id = 1;
                  tmpc.name = 'U.S. Colleges and Universities';
                  tmp.roEditions = new Array();
                  tmp.roEditions.push(tmpc);
                  tmp.secondaryColor = rec[31];
                  tmp.sponsored = true;
                  tmp.sponsorText = 'Sponsored by Capptivation';
                  tmp.sponsorURL = null;
                  tmp.stateName = rec[33];
                  self.rotemp.push(tmp);
                }

                if (self.filter === 'Community Resources') {
                  const communityholder = new CommunityHolder();
                  const tmp = new ComOrg();
                  tmp.lastupdate = new Date().getTime();
                  tmp.locations = [];
                  tmp.class = 'ComOrg';
                  tmp.programs = [];
                  tmp.orglegacy = new OrgLegacy();
                  tmp.orglegacyextra = new OrgLegacyExtra();
                  tmp.religiousaffiliation = '';
                  tmp.content = '';
                  tmp.rfctoresourceid = '';
                  tmp.rfctoreporterid = '';

                  tmp.rfcfrom = '';

                  tmp.orglegacy.name = rec[1]; //
                  tmp.orglegacy.nickname = '';
                  tmp.orglegacy.logo = '';
                  tmp.orglegacy.smalllogo = '';
                  tmp.orglegacy.photo = '';
                  tmp.orglegacy.primarycolor = '#99add0';
                  tmp.orglegacy.secondarycolor = '#000000';
                  tmp.orglegacy.homepage_type = 'catalog';

                  tmp.orglegacyextra.resource_super_type_slug = rec[3]; //
                  tmp.orglegacyextra.short_description = rec[6]; //
                  tmp.orglegacyextra.schedule = rec[26]; //
                  tmp.orglegacyextra.kwabid = rec[0]; //

                  tmp.links = new Links();
                  tmp.abbrDesc = new LangStringBlock();
                  tmp.langDesc = new LangStringBlock();
                  self.languages.forEach(l => {
                    tmp.langDesc[l.abbr] = new BlockString();
                    tmp.langDesc[l.abbr].description = '';
                    tmp.langDesc[l.abbr].block = false;

                    tmp.abbrDesc[l.abbr] = new BlockString();
                    tmp.abbrDesc[l.abbr].description = '';
                    tmp.abbrDesc[l.abbr].block = false;
                  });

                  tmp.parentkey = '';
                  tmp.cappparams = new CappParams();
                  tmp.cappparams.publickey = '';
                  tmp.privatekey = '';
                  tmp.aes = '';
                  tmp.active = true;
                  tmp.disaster = false;
                  tmp.holidays = self.h;

                  tmp.type = rec[2]; //
                  tmp.primary = rec[4];
                  tmp.services = rec[5];
                  tmp.langDesc['en'].description = rec[7]; //
                  tmp.links.url = rec[17]; //
                  tmp.timezone = rec[22]; //
                  tmp.ukey = rec[40]; //
                  tmp.orgtype = rec[41];
                  if (rec[42] && rec[42].length > 0) {
                    tmp.abbrDesc['en'].description = rec[42]; //
                  }
                  tmp.emailextentions = ''; // rec[43];
                  if (rec[9] && rec[9].length > 0) {
                    tmp.icon = rec[9]; //
                  } else {
                    tmp.icon = 'chiclet-referrals.png'; //
                  }
                  tmp.links.facebook = '';
                  tmp.links.pinterest = '';
                  tmp.links.googleplus = '';
                  tmp.links.twitter = '';
                  tmp.links.instagram = '';
                  tmp.links.linkedin = '';
                  tmp.links.tumblr = '';
                  tmp.links.wikilink = '';
                  tmp.links.youtube = '';

                  const comorg = new LocationData();
                  comorg.name = rec[1];
                  const is24 = self.is24(rec[26]);
                  if (!is24 && rec[26] && rec[26].length > 0) {
                    const tmphrs = self.createHrs(rec[26], rec[40]);
                    // comorg.days = tmphrs.hours
                    comorg.regdays = tmphrs.regulardays;
                  }
                  comorg.closedholidays = true;
                  tmp.orglegacyextra.is24 = is24;
                  comorg.twentyfourseven = is24;
                  comorg.ukey = self.titleIXdb.titleIXdb.createPushId();
                  comorg.contactname = rec[13]; //
                  comorg.contactemail = rec[14]; //
                  if (rec[27]) {
                    comorg.phones = self.createphones(rec[27]);  //
                  }

                  // Todo: need to sort this way
                  /*
                  'Not-for-profit Local',
                    'Not-for-profit State',
                    'Not-for-profit National',
                    'Not-for-profit International',
                    'Government Local',
                    'Government State',
                    'Government National',
                    'For-profit'
                    */


                  comorg.street1 = rec[31]; //
                  comorg.street2 = rec[32]; //
                  comorg.city = rec[34]; //
                  comorg.state = rec[36]; //
                  comorg.zip = rec[35]; //
                  comorg.country = rec[37];  //
                  comorg.geo = new Geo();
                  // todo: how get county???
                  if (!rec[43]) {
                    comorg.geo.county = '';

                  } else {
                    comorg.geo.county = rec[43];
                  }
                  if (rec[23]) {
                    comorg.geo.servicearea = self.servicearea(rec[23]); //
                  }
                  comorg.geo.lat = rec[38]; //
                  comorg.geo.long = rec[39]; //
                  comorg.geo.rad = 0.4;
                  const box = self.bnd.boundingCoordinates(comorg.geo.lat, comorg.geo.long, comorg.geo.rad);
                  comorg.geo.west = box[1];
                  comorg.geo.east = box[3];
                  comorg.geo.north = box[2];
                  comorg.geo.south = box[0];
                  comorg.comtags = rec[8];
                  comorg.comemojis = rec[25]; //
                  comorg.cappmail = false;
                  comorg.programs = '';
                  communityholder.id = rec[0];
                  // communityholder.hrs = rec[12];


                  /*
                    const program = new ComProgram;
                    self.languages.forEach(l => {
                      program.langDesc[l.abbr] = new BlockString;
                      program.langDesc[l.abbr].description = '';
                      program.langDesc[l.abbr].block = false;
                    });
                    program.confidential = false;
                    program.comservices = '';
                    program.comlanguages = 'en';
                    program.comcommunities = '';
                    program.comtags = '';
                    program.comemojis = '';
                    program.ronpartner = false;
                    program.cappmail = false;
                    program.confidential = false;
                  */

                  if (tmp.orglegacyextra.kwabid > 0) {
                    tmp.locations.push(comorg);
                    communityholder.comorg = tmp;
                    self.tmpcommunity.push(communityholder);
                  }
                }
              }
            );
          }
        }

      });
    };
  }

}

